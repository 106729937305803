/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'underscore';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { getStyle } from './style/get-style';

class UnthemedGlobalErrorView extends React.PureComponent {
  render() {
    const {
      classes, description, title, className,
    } = this.props;
    return (
      <div className={classNames(['global-error-page-container', className, classes.errorPage])}>
        <div className={classes.errorPageContent}>
          <div className={classes.errorPageIcon} />
          <div className={classes.errorPageTxtContent}>
            <div className={classes.errorPageTxtTitle} id="global-error-title">
              {title}
            </div>
            <div id="global-error-desc">
              {_.map(description, (txt, index) => (
                <div className={classes.errorPageTxtDesc} key={index}>
                  {_.isFunction(txt) ? txt() : txt}
                </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UnthemedGlobalErrorView.propTypes = {
  description: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.func]))
    .isRequired,
  title: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    errorPage: PropTypes.string,
    errorPageContent: PropTypes.string,
    errorPageIcon: PropTypes.string,
    errorPageTxtContent: PropTypes.string,
    errorPageTxtDesc: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

UnthemedGlobalErrorView.defaultProps = {
  className: '',
};

export const GlobalErrorView
  = withDefaultStyles(UnthemedGlobalErrorView, getStyle);
