export const TradeScreeningStatus = Object.freeze({
  InValid: 0,
  HitInReview: 1,
  TrueMatch: 2,
  NoHit: 3,
  SSIF: 4,
  SSID: 5,
  SSIE: 6,
  LicR: 7,
  SSIF14: 8,
  SSID30: 9,
  SSIE60: 10,
  LicA: 11,
  DataIssue: 12,
  IngestionError: 13,
  Error: 14,
  NotScreened: 15,
  DataIssueOrg: 16,
  DataIssueName: 17,
  DataIssueAddress: 18,
  DataIssueLegalId: 19,
});
