export const TaxType = Object.freeze({
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  GSTINNumber: 'GSTINNumber',
  PANNumber: 'PANNumber',
  VatNumber: 'VatNumber',
  NZGSTNumber: 'NZGSTNumber',
  AUGSTNumber: 'AUGSTNumber',
  CCM: 'CCM',
  GSTNumber: 'GSTNumber',
  PSTNumber: 'PSTNumber',
  QSTNumber: 'QSTNumber',
  LegalIdentifier: 'LegalIdentifier',
  IE: 'IE',
});

export const TaxInfo = Object.freeze({
  IsTaxExemptionCertVerified: 'IsTaxExemptionCertVerified',
  SkipVatIdValidation: 'SkipVatIdValidation',
  IsIVAOrVATTaxPayer: 'IsIVAOrVATTaxPayer',
  IsWithholdingTaxExempted: 'IsWithholdingTaxExempted',
  ReverseChargeDocumentAttestation: 'ReverseChargeDocumentAttestation',
});

export const TaxOptionType = Object.freeze({
  Personal: 'TaxType_Personal',
  Business: 'TaxType_Business',
});

export const AttestationValue = Object.freeze({
  True: 'True',
  False: 'False',
  NotSet: '',
});

export const ChileIVAorVATConfirmValueToLabel = Object.freeze({
  [AttestationValue.True]: 'Account_ChileIVAorVATConfirmPositive',
  [AttestationValue.False]: 'Account_ChileIVAorVATConfirmNegative',
});

export const ChileWHTConfirmValueToLabel = Object.freeze({
  [AttestationValue.True]: 'Account_ChileWHTConfirmPositive',
  [AttestationValue.False]: 'Account_ChileWHTConfirmNegative',
});

export const ReverseChargeDocumentAttestationValueToLabel = Object.freeze({
  [AttestationValue.True]: 'Account_ReverseChargeDocumentAttestation_Positive',
  [AttestationValue.False]: 'Account_ReverseChargeDocumentAttestation_Negative',
});

// Microsoft.AdCenter.ClientCenter.MT.Service.TaxType
export const TaxTypeEnumValue = Object.freeze({
  [TaxType.CPF]: 1,
  [TaxType.CNPJ]: 2,
  [TaxType.GSTINNumber]: 3,
  [TaxType.PANNumber]: 4,
  [TaxType.VatNumber]: 5,
  [TaxType.NZGSTNumber]: 6,
  [TaxType.AUGSTNumber]: 8,
  [TaxType.CCM]: 9,
  [TaxType.GSTNumber]: 10,
  [TaxType.QSTNumber]: 15,
  [TaxType.PSTNumber]: 16,
  [TaxType.IE]: 17,
});

export const VatStatusEnum = Object.freeze({
  Valid: 174,
  Invalid: 175,
  Pending: 176,
  PastDue: 177,
});

export const TaxIdMaxLength = Object.freeze({
  [TaxType.CPF]: 11,
  [TaxType.CNPJ]: 14,
  [TaxType.CCM]: 8,
  [TaxType.LegalIdentifier]: 50,
});

export const TaxCertificateDeclineReasonType = Object.freeze({
  SubmittedDocumentInvalid: 1,
  CodigoSectionInvalid: 2,
  NITMissingOrIncomplete: 3,
  NITMismatch: 4,
});

export const TaxExemptionCertVerifiedResult = {
  Accept: 'True',
  Reject: 'False',
  NotSet: null,
};

export const TaxLabelFormat = {
  Optional: 'Optional',
  Required: 'Required',
  RequiredForAllTheEntities: 'RequiredForAllTheEntities',
  RequiredForBusiness: 'RequiredForBusiness',
  RequiredForVATRegisteredBusiness: 'RequiredForVATRegisteredBusiness',
  RequiredForValueAddedTaxIVARegistered: 'RequiredForValueAddedTaxIVARegistered',
  RequiredForIVAorVATTaxPayer: 'RequiredForIVAorVATTaxPayer',
  StateTaxIDOptional: 'StateTaxIDOptional',
  Default: 'Default',
};

export const TaxCertLabel = {
  TaxCertificateOrEquivalentProof: 'Account_TaxCertificate_Or_EquivalentProof',
  TaxCertificate: 'Account_TaxCertificate',
};

export const AttestationValues = [AttestationValue.True, AttestationValue.False];
