export const SecurityAction = {
  AccountAdd: 'AccountAdd',
  AccountBillingLoad: 'AccountBillingLoad',
  AccountCashBackFetch: 'AccountCashbackFetch',
  AccountCashBackUpdate: 'AccountCashbackUpdate',
  AccountDelete: 'AccountDelete',
  AccountFetch: 'AccountFetch',
  AccountLoad: 'AccountLoad',
  AccountReactivate: 'AccountReactivate',
  AccountSensitiveCategorySet: 'AccountSensitiveCategorySet',
  AccountSensitiveCategoryView: 'AccountSensitiveCategoryView',
  AccountUpdate: 'AccountUpdate',
  AccountUserResponsibilityLoad: 'AccountUserResponsibilityLoad',
  ActionLoad: 'ActionLoad',
  AgencyAndSalesHouseCommissionPctView: 'AgencyAndSalesHouseCommissionPctView',
  AgencyInviteLoad: 'AgencyInviteLoad',
  AgencyInvite: 'AgencyInvite',
  AgencyLinkJobAdd: 'AgencyLinkJobAdd',
  AgencyLinkJobAccept: 'AgencyLinkJobAccept',
  AgencyLinkJobDelete: 'AgencyLinkJobDelete',
  AgencyLinkJobUpdate: 'AgencyLinkJobUpdate',
  AgencyLinkJobLoad: 'AgencyLinkJobLoad',
  AgencyLinkJobFetch: 'AgencyLinkJobFetch',
  AgencyRelationshipLoad: 'AgencyRelationshipLoad',
  ApiCustomerFetch: 'APICustomerFetch',
  ApiCustomerUpdate: 'APICustomerUpdate',
  ApiTokenStatusUpdate: 'APITokenStatusUpdate',
  ApiUserFetch: 'APIUserFetch',
  ApiUserUpdate: 'APIUserUpdate',
  BalanceLoad: 'BalanceLoad',
  BalanceAdjustmentExecute: 'BalanceAdjustmentExecute',
  BIReportMetadataLoad: 'BIReportMetadataLoad',
  BusinessLocationLoad: 'BusinessLocationLoad',
  CampaignManagementFullControl: 'CampaignManagementFullControl',
  CampaignManagementLoad: 'CampaignManagementLoad',
  CardStatementLoad: 'CardStatementLoad',
  CashBackRebateUpdate: 'CashbackRebateUpdate',
  CashBackMinMaxRebateUpdate: 'CashbackMinMaxRebateUpdate',
  CashBackPropertiesView: 'CashbackPropertiesView',
  ConvertCustomerToAgency: 'ConvertCustomerToAgency',
  ConvertCustomerToAggregator: 'ConvertCustomerToAggregator',
  CsoEmailUpdate: 'CSOEmailUpdate',
  CustomerFetch: 'CustomerFetch',
  CustomerFinancialStatusUpdate: 'CustomerFinancialStatusUpdate',
  CustomerDelete: 'CustomerDelete',
  CustomerIdMappingFetch: 'CustomerIdMappingFetch',
  CustomerIdMappingUpdate: 'CustomerIdMappingUpdate',
  CustomerLoad: 'CustomerLoad',
  CustomerRetier: 'CustomerRetier',
  CustomerSignup: 'CustomerSignup',
  CustomerScrub: 'CustomerScrub',
  CustomerUpdate: 'CustomerUpdate',
  ForceBilling: 'ForceBilling',
  GetCustomerCreditCardMigrationStatus: 'GetCustomerCreditCardMigrationStatus',
  InternalUserLoad: 'InternalUserLoad',
  InterstitialUrlUpdate: 'InterstitalUrlUpdate',
  InsertionOrderAdd: 'InsertionOrderAdd',
  InsertionOrderLoad: 'InsertionOrderLoad',
  InsertionOrderUpdate: 'InsertionOrderUpdate',
  InvoiceAdd: 'InvoiceAdd',
  InvoiceDelete: 'InvoiceDelete',
  IsInternal: 'http://advertising.microsoft.com/ClientCenter/MT/RoleAttributes/IsInternal',
  IsAggregator: 'http://advertising.microsoft.com/ClientCenter/MT/RoleAttributes/IsAggregator',
  LoadBatch: 'LoadBatch',
  MarketUpdate: 'MarketUpdate',
  MySettingsUpdate: 'MySettingsUpdate',
  NewAdjustment: 'NewAdjustment',
  OrderLoad: 'OrderLoad',
  PaymentInstrumentAdd: 'PaymentInstrAdd',
  PaymentInstrumentDelete: 'PaymentInstrDelete',
  PaymentInstrumentFetch: 'PaymentInstrFetch',
  PaymentInstrumentLoad: 'PaymentInstrLoad',
  PaymentInstrumentUpdate: 'PaymentInstrUpdate',
  PasswordReset: 'PasswordReset',
  PilotBatchUpdate: 'PilotBatchUpdate',
  PilotFlagUpdate: 'PilotFlagUpdate',
  PssAdvertiserLoad: 'PSSAdvertiserLoad',
  Rebill: 'Rebill',
  RoleActionLoad: 'RoleActionLoad',
  SetCustomerConsentForCreditCardMigration: 'SetCustomerConsentForCreditCardMigration',
  StatementLoad: 'StatementLoad',
  SupportBillingTemplateAdd: 'PSSAdvertiserLoad',
  SupportBillingDocumentRenderExecute: 'PSSAdvertiserLoad',
  SupportBillingDocumentReRenderExecute: 'PSSAdvertiserLoad',
  SystemLimitUpdate: 'SystemLimitUpdate',
  TaxExemptionCertStatusGet: 'TaxExemptionCertStatusGet',
  TaxExemptionCertStatusSet: 'TaxExemptionCertStatusSet',
  TaxInfoUpdate: 'TaxInfoUpdate',
  UpdateBatch: 'UpdateBatch',
  UserAccountSecurityFetch: 'UserAccountSecurityFetch',
  UserAdd: 'UserAdd',
  UserCustomerSecurityFetch: 'UserCustomerSecurityFetch',
  UserDelete: 'UserDelete',
  UserFetch: 'UserFetch',
  UserForceDelete: 'ForceDeleteUser',
  UserLoad: 'UserLoad',
  UserRoleFetch: 'UserRoleFetch',
  UserSuspend: 'UserSuspend',
  UserUpdate: 'UserUpdate',
  ViewAgentWorkspace: 'ViewAgentWorkspace',
};
