import { AttestationValue } from './tax';

export const IncludeCampaignDetailsValueToLabel = {
  [AttestationValue.True]: _TL_('Show amount due for each campaign'),
  [AttestationValue.False]: _TL_('Don’t show amount due for each campaign'),
};

export const CampaignDetailsForUnconsolidatedInvoiceValueToLabel = {
  [AttestationValue.True]: _TL_('Show amount due for each campaign on unconsolidated invoice'),
  [AttestationValue.False]: _TL_('Don’t show amount due for each campaign on unconsolidated invoice'),
};

export const GroupingCriteria = {
  BilltoSAPId: 'BilltoSAPId',
  BusinessAddress: 'BusinessAddress',
  CompanyCode: 'CompanyCode',
  CurrencyType: 'CurrencyType',
  TaxInformation: 'TaxInformation',
  BillingGroupId: 'BillingGroupId',
};

export const GroupingPrerequisites = {
  PaymentOption: 'PaymentOption',
};

export const CompanyCode = Object.freeze({
  MIOL: 1062, // Ireland
  MOI: 1548, // US
  India: 1098,
  Brazil: 1639,
  Taiwan: 1058,
});

export const CompanyCodeCompanyNameMapping = Object.freeze({
  [CompanyCode.MIOL]: 'Microsoft Ireland Operations Limited',
  [CompanyCode.MOI]: 'Microsoft Online, Inc.',
  [CompanyCode.India]: 'Microsoft Corporation (India) Private Limited',
  [CompanyCode.Brazil]: 'Microsoft do Brasil Importação e Comércio de Software e Games LTDA',
  [CompanyCode.Taiwan]: '台灣微軟股份有限公司',
});
