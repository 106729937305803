import _ from 'underscore';
import {
  PaymentInstrFinancialStatus,
  CardCategory,
  CountryCode,
  CreditCardType,
  PaymentInstrumentType,
  PaymentInstrLifecycleStatus,
  PaymentType,
  PaymentTypeId,
  PaymentOption,
  PaymentOptionId,
  PaymentSettingsMap,
  PaymentTransactionStatus,
  SAPApplicationType,
  SAPApplicationStatus,
  DocumentType,
  AccountMode,
  ESCPrepayUnsupportedPITypes,
  NukedPIStatusTypes,
} from './constants';

export const getCardNumberLast4Digits = number => (_.isString(number) ? number.substr(-4) : '');

const getCreditCardDisplayName = (pi, i18n) => {
  const displayNameKey = pi.Address ? 'PaymentMethod_CreditCardDisplayFormat' : 'PaymentMethod_CreditCardNoCountryDisplayFormat';
  const cardTypeKey = pi.CardTypeId ? `CreditCardTypeID_${pi.CardTypeId}` : `CreditCardTypeID_${CreditCardType[pi.CardType]}`;

  return i18n.getString(
    displayNameKey,
    {
      cardType: i18n.getString(cardTypeKey),
      cardCategory: pi.CardCategory === CardCategory.Prepaid ? i18n.getString('CreditCard_Prepaid') : '',
      last4: getCardNumberLast4Digits(pi.Number),
      country: pi.Address ? pi.Address.CountryName : '',
    }
  );
};

const getInvoiceDisplayName = (pi, i18n) => {
  const stringFormat = pi.SAPCustomerName ? 'PaymentMethod_SAPDisplayFormat' : 'PaymentMethod_SAPNoCustomerNameDisplayFormat';
  return i18n.getString(
    stringFormat,
    {
      customerName: pi.SAPCustomerName,
      invoiceId: pi.InvoiceId,
    }
  );
};

const getCheckDisplayName = (pi, i18n) =>
  i18n.getString('PaymentMethod_CheckDisplayFormat', { friendlyName: pi.FriendlyName });

const getEFTDisplayName = (pi, i18n) =>
  i18n.getString('PaymentMethod_EFTDisplayFormat', { friendlyName: pi.FriendlyName });

const getPayPalDisplayName = (pi, i18n) =>
  (pi.Email ? i18n.getString('PaymentMethod_PayPalDisplayFormat', { email: pi.Email }) : i18n.getString('PaymentInstrTypeId_PayPal'));

const getSepaDisplayName = (pi, i18n) =>
  i18n.getString('PaymentMethod_SEPADisplayFormat', { friendlyName: pi.FriendlyName });

const getOfflineDisplayName = (pi, i18n) => {
  const stringFormat = pi.Address ? 'PaymentMethod_OfflinePaymentDisplayFormat' : 'PaymentInstrument_OfflinePaymentSimpleDisplayFormat';
  return i18n.getString(stringFormat, { country: pi.Address ? pi.Address.CountryName : '' });
};

const getBoletoDisplayName = (pi, i18n) =>
  i18n.getString('PaymentMethod_BoletoDisplayFormat', { friendlyName: pi.FriendlyName });

const getVBADisplayName = (pi, i18n) =>
  i18n.getString('PaymentMethod_VBADisplayFormat', { friendlyName: pi.FriendlyName });

const getDisplayNameDict = {
  [PaymentInstrumentType.CreditCard]: getCreditCardDisplayName,
  [PaymentInstrumentType.Invoice]: getInvoiceDisplayName,
  [PaymentInstrumentType.Check]: getCheckDisplayName,
  [PaymentInstrumentType.ElectronicFundsTransfer]: getEFTDisplayName,
  [PaymentInstrumentType.PayPal]: getPayPalDisplayName,
  [PaymentInstrumentType.ELV]: getSepaDisplayName,
  [PaymentInstrumentType.OfflinePaymentMethod]: getOfflineDisplayName,
  [PaymentInstrumentType.Boleto]: getBoletoDisplayName,
  [PaymentInstrumentType.VBA]: getVBADisplayName,
};

export const isExpired = (pi) => {
  if (!(pi && Date.parse(pi.ExpirationDate))) {
    return false;
  }
  const date = new Date(pi.ExpirationDate);
  return date.setMonth(date.getMonth() + 1) <= new Date();
};

export const isOnHold = pi =>
  _.isObject(pi)
    && pi.FinancialStatus !== null
    && pi.FinancialStatus !== undefined
    && pi.FinancialStatus !== PaymentInstrFinancialStatus.NoHold;

export const isDeleted = pi => _.isObject(pi) && pi.LifecycleStatusId === PaymentInstrLifecycleStatus.Deleted;

export const isPurged = pi => _.isObject(pi) && pi.IsPurged === true;

export const isIndiaPI = pi => _.isObject(pi) && pi.Address && pi.Address.Country === CountryCode.IN;

/**
 * Gets a string representation of the expiration date in 'mm/yy' format
 * @param {string} dateString The date string
 * @returns {string} the string 'mm/yy' format of the date
*/
export const formatExpDate = (dateString) => {
  if (!Date.parse(dateString)) {
    return '';
  }

  const date = new Date(dateString);
  const yearString = date.getFullYear().toString().substr(-2);
  const monthString = `0${date.getMonth() + 1}`.substr(-2);
  return `${monthString}/${yearString}`;
};

export const getDisplayName = (pi, i18n) => {
  if (!(pi && pi.Id && i18n && i18n.getString)) {
    return '';
  }

  const getDisplayNameFunc = getDisplayNameDict[pi.Type];
  if (getDisplayNameFunc) {
    return getDisplayNameFunc(pi, i18n);
  }

  return i18n.getString('PaymentMethod_DefaultFormat', { id: pi.Id });
};

/**
 * Gets the display string of card status, whether it's on hold, expired or active
 * @param {object} pi the payment instrument
 * @param {object} i18n the i18n object
 * @param {object} permissions the permissions object
 * @returns {string} display string of the payment instrument of type CreditCard
 */
export const getCardStatusDisplay = (pi, i18n) => {
  if (!(pi && i18n && i18n.getString && i18n.formatDate)) {
    return '';
  }

  if (isOnHold(pi)) {
    const date = Date.parse(pi.OnHoldDate) ? i18n.formatDate(new Date(pi.OnHoldDate)) : '';
    return i18n.getString('PaymentMethod_CreditCardOnHoldFormat', { date });
  }

  return i18n.getString(
    isExpired(pi) ? 'PaymentMethod_CreditCardExpiredFormat' : 'PaymentMethod_CreditCardExpiresFormat',
    { date: isIndiaPI(pi) ? i18n.getString('IndiaCardStorage_ExpiryDate') : formatExpDate(pi.ExpirationDate) }
  );
};

/**
 * Get the payment option text by the given paymentOptionId
 * @param {number} paymentOptionId the payment option Id
 * @param {*} i18n the i18n object
 * @returns {string} display text of the payment option
 */
export const getPaymentOptionTextById = (paymentOptionId, i18n) => {
  const paymentOptionIds = _.values(PaymentOptionId);
  return _.contains(paymentOptionIds, paymentOptionId) ?
    i18n.getString(`Billing_PaymentOption_${paymentOptionId}`) :
    undefined;
};

/**
 * Get the payment method formatted display string based on MT returned fields
 * This is the same function of server side 'BillingHelper.GetPaymentMethodFormatted'
 * @param {object} piInformation contains below properties:
 *  paymentInstrumentTypeId indicate this PI's type Id
 *  paymentInstrAccountNumber contains this payment's account information, for different payment type this value is different.
 *  paymentInstrFriendlyName this holds the PI friendly named used by Paypal, VBA and Boleto.
 *  paymentInstrCardTypeId this holds the Credit Card Type Id if the PI type is Credit Card.
* @param {*} i18n the i18n object
 * @returns {string} display text of the formatted payment method
 */
export const getPaymentMethodFormatted = (
  {
    paymentInstrumentTypeId,
    paymentInstrAccountNumber,
    paymentInstrFriendlyName,
    paymentInstrCardTypeId,
  },
  i18n
) => {
  let paymentMethodText;
  if (paymentInstrumentTypeId && paymentInstrumentTypeId !== PaymentInstrumentType.Unset) {
    switch (paymentInstrumentTypeId) {
      case PaymentInstrumentType.CreditCard: {
        if (paymentInstrCardTypeId) {
          paymentMethodText = getCreditCardDisplayName({
            CardTypeId: paymentInstrCardTypeId,
            Number: paymentInstrAccountNumber,
          }, i18n);
        }
        break;
      }
      case PaymentInstrumentType.Invoice: {
        paymentMethodText = getInvoiceDisplayName({ InvoiceId: paymentInstrAccountNumber }, i18n);
        break;
      }
      case PaymentInstrumentType.PayPal: {
        paymentMethodText = getPayPalDisplayName({ Email: paymentInstrFriendlyName }, i18n);
        break;
      }
      case PaymentInstrumentType.ELV: {
        paymentMethodText = getSepaDisplayName({ FriendlyName: paymentInstrAccountNumber }, i18n);
        break;
      }
      case PaymentInstrumentType.OfflinePaymentMethod: {
        paymentMethodText = getOfflineDisplayName({}, i18n);
        break;
      }
      case PaymentInstrumentType.VBA: {
        paymentMethodText = getVBADisplayName({ FriendlyName: paymentInstrFriendlyName }, i18n);
        break;
      }
      case PaymentInstrumentType.Boleto: {
        paymentMethodText = getBoletoDisplayName({ FriendlyName: paymentInstrFriendlyName }, i18n);
        break;
      }
      default:
        break;
    }
  }
  return paymentMethodText;
};

/**
 * Calculates the remaining credit for a given SAP, returning null if there is no applicable limit.
 * @param {object} sap the Invoice object to check
 * @param {object} options Some optional parameter to pass, like matchCurrencyCode to ensure that the SAP object matches the given currency code
 * @returns {number} the remaining credit for the given SAP or null if not applicable
 */
export const sapCreditRemaining = (sap, options = {}) => {
  const { matchCurrencyCode } = options;
  const hasValidCreditLimit =
    _.isObject(sap) &&
    sap.Type === PaymentInstrumentType.Invoice &&
    sap.IsCLEOn === true &&
    sap.CreditLimit > 0 &&
    (!matchCurrencyCode || (sap.CurrencyCode || '').toLowerCase() === matchCurrencyCode.toLowerCase());

  return hasValidCreditLimit ?
    sap.CreditLimit - (sap.SAPBalance || 0) :
    null;
};

/**
 * Calculates the projected number of days remaining before the given SAP runs out of credit,
 * returning null if there is no valid estimate.
 * @param {object} sap the Invoice object to check
 * @returns {number} the number of days remaining before the given SAP runs out of credit or null
 *                   if there is no valid estimate
 */
export const sapCreditDaysRemaining = (sap) => {
  const creditRemaining = sapCreditRemaining(sap);

  return creditRemaining != null && sap.SAPSpendRunRate > 0 ?
    Math.ceil(creditRemaining / sap.SAPSpendRunRate) :
    null;
};

/**
 * Returns the status whether the passed pi was validated
 * @param {object} pi the payment instrument
 * @returns {bool} indicate whether the pi had already validated
 */
export const isPiValidated = pi => _.isObject(pi) && Number(pi.LifecycleStatusId) > 0 && pi.LifecycleStatusId !== PaymentInstrLifecycleStatus.PendingValidation;

const isPiType = (pi, piType) => _.isObject(pi) && piType && pi.Type === piType;

/**
 * Return whether the payment instrument is the indicated type of pi.
 * @param {object} pi the PaymentInstrument object
 * @returns {bool} indicate whether the provided Pi is the indicated type of pi.
 */
export const isCreditCard = pi => isPiType(pi, PaymentInstrumentType.CreditCard);

export const isInvoice = pi => isPiType(pi, PaymentInstrumentType.Invoice);

export const isBoleto = pi => isPiType(pi, PaymentInstrumentType.Boleto);

export const isOfflinePaymentMethod = pi => isPiType(pi, PaymentInstrumentType.OfflinePaymentMethod);

export const isSepa = pi => isPiType(pi, PaymentInstrumentType.ELV);

export const isPaypal = pi => isPiType(pi, PaymentInstrumentType.PayPal);

export const isNukePIInvalid = pi => isCreditCard(pi) && (pi.NukedPIStatus === NukedPIStatusTypes.Invalid || pi.NukedPIStatus === NukedPIStatusTypes.AltInvalid);

export const isNukePIShowButton = pi => isCreditCard(pi) && pi.NukedPIStatus === NukedPIStatusTypes.ValidShowButton;

const getPiCountry = pi => (pi.Address ? pi.Address.Country : null);

/**
 * Check whether the given backup PI can be set as a backup for the given primary PI.
 * @param {Object} obj.backupPi - PI to check whether it can be set as backup
 * @param {Object} obj.primaryPi - PI set currently set as primary PI
 * @returns {Boolean} - A value indicating whether the PI can be set as backup
 */
export const isValidBackup = ({ backupPi, primaryPi }) =>
  backupPi.SupportsRecurringPayment &&
  backupPi.Id !== primaryPi.Id &&
  backupPi.IsTaxable === primaryPi.IsTaxable &&
  (!primaryPi.IsTaxable || getPiCountry(backupPi) === getPiCountry(primaryPi));

/**
 * Returns a copy of the given PaymentInstrument object with additional utility methods.
 * @param {object} pi The PaymentInstrument object to be extended with additional utility methods
 * @param {object} i18n The i18n object
 * @param {object} permissions The permissions object
 * @returns {object} A copy of the extended PaymentInstrument object.
 */
export const extendPi = (pi = {}, i18n, permissions = {}) => _.defaults({}, pi, {
  id: pi && pi.Id, // react DropdownSelector expects an id property
  displayName: getDisplayName(pi, i18n),
  cardStatus: (pi && pi.Type) === PaymentInstrumentType.CreditCard ? getCardStatusDisplay(pi, i18n, permissions) : '',
  isDeleted: isDeleted(pi),
  isOnHold: isOnHold(pi),
  isPurged: isPurged(pi),
  isExpired: isExpired(pi),
  isValidated: isPiValidated(pi),
  isInvoice: isInvoice(pi),
  isBoleto: isBoleto(pi),
  isOfflinePaymentMethod: isOfflinePaymentMethod(pi),
  isCreditCard: isCreditCard(pi),
  isSepa: isSepa(pi),
  isPaypal: isPaypal(pi),
  isNukePIInvalid: isNukePIInvalid(pi),
  isNukePIShowButton: isNukePIShowButton(pi),
});

/**
 * Returns a sorted list of PaymentInstrument ordered by Active > Expired > OnHold, then by Type, then by name.
 * @param {object[]} piList A list of PaymentInstrument object to be extended and sorted.
 * @param {object} i18n The i18n object
 * @param {object} permissions The permissions object
 * @returns {object[]} The sorted list of extended PaymentInstrument object.
 */
export const sortPiList = (piList = [], i18n, permissions = {}) => {
  if (_.isEmpty(piList)) {
    return [];
  }

  const piListExtended = _.map(piList, pi => extendPi(pi, i18n, permissions));
  const activePis = _.chain(piListExtended)
    .reject(pi => pi.isOnHold || pi.isExpired)
    .sortBy('displayName')
    .sortBy('Type')
    .value();
  const expiredPis = _.chain(piListExtended)
    .filter(pi => pi.isExpired && !pi.isOnHold)
    .sortBy('displayName')
    .value();
  const onHoldPis = _.chain(piListExtended)
    .filter(pi => pi.isOnHold)
    .sortBy('displayName')
    .value();
  return _.union(activePis, expiredPis, onHoldPis);
};

/**
 * Returns a string enum value for Payment Type
 * @param {PaymentTypeId} piTypeId The payment type Id.
 * @returns {PaymentType} The payment type.
 */
export const convertPaymentTypeToString = (piTypeId) => {
  const key = _.findKey(PaymentTypeId, val => val === piTypeId);
  return PaymentType[key];
};

/**
 * Returns a string enum value for Payment Type
 * @param {PaymentType} piType The payment type.
 * @returns {PaymentTypeId} The payment type id.
 */
export const convertPaymentTypeToInt = (piType) => {
  const key = _.findKey(PaymentType, val => val === piType);
  return PaymentTypeId[key];
};

/**
 * Returns a string enum value for Payment Option
 * @function
 * @param {PaymentOptionId} paymentOptionId The payment option Id.
 * @returns {string} The payment option as a string value.
 */
export const convertPaymentOptionToString = paymentOptionId =>
  _.result(PaymentOption, _.findKey(PaymentOptionId, val => val === paymentOptionId), null);

/**
 * Returns a number enum value for Payment Option
 * @function
 * @param {PaymentOption} paymentOptionString The payment option string.
 * @returns {number} The payment option as a number value.
 */
export const convertPaymentOptionToId = paymentOptionString =>
  _.result(PaymentOptionId, _.findKey(PaymentOption, val => val === paymentOptionString), null);

/**
 * Returns the payment type corresponding to the given payment option ID.
 * @param {Number} paymentOptionId - payment option ID to lookup
 * @returns {string} - payment type corresponding to the given payment option ID
 */
export const getPaymentTypeForPaymentOption = paymentOptionId =>
  _.findKey(PaymentSettingsMap, p => _.contains(p, paymentOptionId)) || null;

/**
 * Return the text key of the payment transaction status used by billing grid.
 * @param {PaymentTransactionStatus} paymentTransactionStatus the input payment transaction status
 * @param {bool} hasPaymodError whether has paymod error
 * @returns {string} the text key of the payment transaction status used by billing grid.
 */
export const getPaymentTransactionStatus = (paymentTransactionStatus, hasPaymodError) => {
  if (paymentTransactionStatus
    && paymentTransactionStatus !== PaymentTransactionStatus.Unknown
    && paymentTransactionStatus !== PaymentTransactionStatus.Success
    && !hasPaymodError) {
    return `Billing_PaymentStatus_${paymentTransactionStatus}`;
  }
  return null;
};

/**
 * Return a filtered list of newly added pending SAPApplications
 * @param {object[]} [sapApplications=[]] the original SAPApplication list to filter
 * @returns {object[]} the filtered SAPApplication list
 */
export const filterPendingSAPApplicationsOfNewSAP = (sapApplications = []) => {
  if (!_.isArray(sapApplications)) {
    return [];
  }

  const types = [SAPApplicationType.ApplyNewBilltoSAP, SAPApplicationType.ApplyNewSoldtoSAP, SAPApplicationType.AddingExistingSAP];
  const statuses = [SAPApplicationStatus.UnderReview, SAPApplicationStatus.ActionRequired];

  return _.filter(sapApplications, ({ SAPApplicationType: sapApplicationType, SAPApplicationStatus: sapApplicationStatus }) =>
    _.contains(types, sapApplicationType) && _.contains(statuses, sapApplicationStatus));
};

const onlinePiTypes = [
  PaymentInstrumentType.CreditCard,
  PaymentInstrumentType.PayPal,
];

const offlinePiTypes = [
  PaymentInstrumentType.Boleto,
  PaymentInstrumentType.ELV,
  PaymentInstrumentType.OfflinePaymentMethod,
];

/**
 * Return a list of payment instruments filtered by payment option
 * @param {object[]} paymentInstruments the list of PI's to filter
 * @param {number} paymentOptionId The type of payment option to use as filter criteria
 * @returns {object[]} the filtered list
 */
export const filterPisByPaymentOption = (paymentInstruments, paymentOptionId) => {
  switch (paymentOptionId) {
    case PaymentOptionId.Invoice:
      return _.filter(
        paymentInstruments,
        paymentInstrument => paymentInstrument.Type === PaymentInstrumentType.Invoice
      );

    case PaymentOptionId.Prepay:
      return _.filter(
        paymentInstruments,
        paymentInstrument => _.contains([...onlinePiTypes, ...offlinePiTypes], paymentInstrument.Type)
      );

    case PaymentOptionId.Threshold:
      return _.filter(
        paymentInstruments,
        paymentInstrument => _.contains(
          onlinePiTypes,
          paymentInstrument.Type
        ) && paymentInstrument.SupportsPostpay
      );

    default:
      return [];
  }
};

/**
 * Filters the specified payment instruments,
 * based on their compatibility with the specified account mode.
 * @param {object[]} paymentInstruments the list of payment instruments to be checked for compatibility
 * @param {number} paymentInstruments[].Type the type of payment instrument, e.g. credit card, etc.
 * @param {number|null} accountMode the account mode which all specified payment instruments need to be compatible with
 * @returns {object[]} the filtered list of payment instruments
 */
export const filterPaymentInstrumentsByAccountMode = (paymentInstruments, accountMode) =>
  (accountMode !== AccountMode.UnifiedSmart
    ? paymentInstruments
    : _.filter(paymentInstruments, pi => !_.contains(ESCPrepayUnsupportedPITypes, pi.Type)));

export const getPaymentReason = (documentType) => {
  if (documentType === DocumentType.WriteOffCreditMemo) {
    return `Billing_ColumnValue_AmountPaid_DocumentType_${documentType}`;
  }

  return null;
};

/**
 * Filter payment options by user's account mode
 * @param {Object[]} paymentOptions Payment options returned by backend
 * @param {string | null} accountMode account mode
 * @param {Object} pilots Permissions/Features
 * @param {function} poKeySelector An optional function that selects
 * the payment option ID from elements of {@link paymentOptions} collection.
 * By default the elements are assumed to be the IDs.
 * @returns {Object[]} Filtered payment options, share the same type with paymentOptions param
 */
export const filterPaymentOptionsByAccountMode = (paymentOptions, accountMode, pilots, poKeySelector) => {
  const keySelector = poKeySelector || _.identity;
  const {
    UnifiedProductMonthlyInvoice: isUnifiedProductMonthlyInvoiceEnabled,
    ESCPrepay: isEscPrepayEnabled,
  } = pilots || {};

  return accountMode !== AccountMode.UnifiedSmart
    ? paymentOptions
    : _.filter(paymentOptions, (option) => {
      switch (`${keySelector(option)}`) {
        case `${PaymentOptionId.Invoice}`:
          return isUnifiedProductMonthlyInvoiceEnabled;
        case `${PaymentOptionId.Prepay}`:
          return isEscPrepayEnabled;
        case `${PaymentOptionId.Threshold}`:
          return true;
        default:
          return false;
      }
    });
};
