import React from 'react';

const defaultImpl = {};
const PreferenceContext = React.createContext(defaultImpl);

PreferenceContext.displayName = 'PreferenceContext';

export {
  PreferenceContext,
};
