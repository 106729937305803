/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-param-reassign */
/* eslint-disable function-paren-newline */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { withActivityContext } from './activity-context-provider';

export const withSignal = (WrappedComponent, SignalAdaptor) => {
  class HoC extends React.Component {
    render = () => {
      const shimmedProps = {};
      _.map(Object.keys(SignalAdaptor), (event) => {
        // call logger first then call the original handler
        // not using _.compose because the original handler may expect multiple params
        // and _.compose can only pass one param
        const shimmedHandler = (...e) => {
          if (this.props.activity) {
            this.props.activity.signal(_.assign(SignalAdaptor[event](e), { event }));
          }
          if (this.props[event]) {
            this.props[event](...e);
          }
        };

        shimmedProps[event] = shimmedHandler.bind(this);
      });

      return (<WrappedComponent
        {...this.props}
        {...shimmedProps}
      />);
    };
  }

  HoC.propTypes = {
    activity: PropTypes.object,
  };

  HoC.defaultProps = {
    activity: undefined,
  };

  return withActivityContext(hoistNonReactStatics(HoC, WrappedComponent));
};

export const CommonEventParser = (e) => {
  if (e[0] && e[0].currentTarget) {
    return ({
      className: e[0].currentTarget.className,
      name: e[0].currentTarget.name,
      id: e[0].currentTarget.id,
      text: e[0].currentTarget.innerText,
      value: e[0].currentTarget.value,
      checked: e[0].currentTarget.checked,
      'data-analyticsid': e[0].currentTarget.dataset && e[0].currentTarget.dataset.analyticsid,
    });
  }
  return {};
};

export const FabricDropdownParser = e => ({
  className: e[0].target.className,
  name: e[0].target.name,
  id: e[0].target.id,
  text: e[1].text,
  value: e[1].value,
});
