export const AdjustmentReason = {
  None: 0,
  CustomerRetention: 41,
  CreditAndRebill: 42,
  CreditAndRebillTaxIssue: 43,
  Fraud: 44,
  SystemIssues: 45,
  InternalUserError: 46,
  VolatileTrafficComplaint: 47,
  Broadmatch: 48,
  PrepayBalanceRefund: 50,
  AccountCompromised: 52,
  TravelSpecialProgram: 53,
};
