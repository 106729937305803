import { Validation } from '@bingads-webui/validation';
import _ from 'underscore';
import { BaseFactory } from './base-factory';

/**
 * Factory for string validation rules
 * @param {Object} options - input for schema property bag and i18n
 */
export class StringFactory extends BaseFactory {
  /**
   * Create string rules set from schema property
   * @returns {Object} - string validation rules set
   */
  create() {
    const rules = {};

    if (_.isFinite(this.prop.maxLength)) {
      rules.maxLength = Validation.string.size(
        this.prop.maxLength,
        this.prop.distinguishCJK,
        this.i18n
      );
    }

    if (_.isFinite(this.prop.minLength)) {
      rules.minLength = Validation.string.minSize(
        this.prop.minLength,
        this.prop.distinguishCJK,
        this.i18n
      );
    }

    if (this.prop.pattern) {
      rules.pattern = Validation.string.pattern(this.prop.pattern, this.i18n);
    }

    if (this.prop.checkXss) {
      rules.checkXss = Validation.string.xss(this.i18n);
    }

    // apply nullable prop
    _.each(rules, (rule) => {
      rule.set({ nullable: this.prop.nullable !== false });
    });

    return rules;
  }
}
