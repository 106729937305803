export const EmailRegExp = '^$|^[a-zA-Z0-9_\\.\\-\\+\']+\\@(?:[\\w\\-]+\\.)+[a-zA-Z0-9]+$';
export const UKGovEmailRegExp = '^$|[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-.]+\\.)?(?:gov\\.uk|fca\\.org\\.uk)$';
export const PhoneNumberRegExp = '^\\+?[\\d\\-\\s\\(\\)extX\\.]*$';
export const PhoneNumberWithoutPrefixRegExp = '^[\\d\\-\\s\\(\\)extX\\.]*$';
export const DefaultPostalCodeRegExp = '[0-9]{5}(\\-[0-9]{4})?';
export const WebsiteRegExp = '^$|(?:https?:\\/\\/)?(?:[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*\\.)+(?:[a-zA-Z0-9]+)+(?:\\/[A-Za-z0-9-\\._~:/?#[\\]@!$&\'()*+,;=%]*)*';
export const RegGeneralInputBoxRegExp = '[^\x00-\x1F]*';
export const TaxSGGSTNumberRegExp = '^\\w{2}-?\\d{7}-?\\w$|^\\d{8,9}\\w$|^[TS]\\d{2}[A-Z]\\w\\d{4}\\w$';
export const TaxNZGSTNumberRegExp = '^\\d{2,3}-?\\d{3}-?\\d{3}$';
export const TaxINPANNumberRegExp = '^[A-Za-z0-9]{10}$';
export const TaxRSPIBNumberRegExp = '^[0-9]{9}$|^[0-9]{13}$';
export const TaxRSLegalNumberRegExp = '^[0-9]{8}$';
export const TaxINGSTINRegExp = '^$|^([0-9]{2}[0-9A-Z]{13})$';
export const TaxCOVatNumberRegExp = '^\\d{9}\\-\\d{1}$';
export const TaxCOCertificateExecutionDateRegExp = '^\\d{4}/\\d{2}/\\d{2}$';
export const TaxAEVatNumberRegExp = '^\\d{15}$';
export const TaxILVatNumberRegExp = '^\\d{9}$';
export const TaxVNVatNumberRegExp = '^\\d{10}(-\\d{3})?$';
export const TaxNOVatNumberRegExp = '^(NO)?\\s?\\d{3}\\s?\\d{3}\\s?\\d{3}\\s?MVA$';
export const TaxTRVatNumberRegExp = '^\\d{10}$|^\\d{11}$';
export const TaxGEVatNumberRegExp = '^\\d{9}$';
export const TaxTHVatNumberRegExp = '^\\d{13}$|^\\d{1}-\\d{4}-\\d{5}-\\d{2}-\\d{1}$';
export const TaxBDVatNumberRegExp = '^\\d{9}-\\d{4}$';
export const TaxIDVatNumberRegExp = '^\\d{2}\\.\\d{3}\\.\\d{3}\\.\\d{1}-\\d{3}\\.\\d{3}$|^\\d{15}$';
export const TaxBYVatNumberRegExp = '^\\d{9}$';
export const TaxNGVatNumberRegExp = '^\\d{8}\\-0001$';
export const TaxISVatNumberRegExp = '^\\d{5}$|^\\d{6}$';
export const TaxMDVatNumberRegExp = '^\\d{7}$';
export const TaxZWVatNumberRegExp = '^\\d{8}$';
export const TaxSAVatNumberRegExp = '^\\d{15}$';
export const TaxBHVatNumberRegExp = '^2\\d{14}$';
export const TaxKHVatNumberRegExp = '^L001-\\d{9}$|^B0\\d{2}-\\d{9}$|^K0\\d{2}-\\d{9}$|^E0\\d{2}-\\d{10}$';
export const TaxKEVatNumberRegExp = '^P\\d{9}[A-Za-z]$';
export const TaxOMVatNumberRegExp = '^OM\\d{10}$';
export const TaxGHVatNumberRegExp = '^[CP]\\d{10}$';
export const TaxCMVatNumberRegExp = '^[A-Za-z]{1}\\d{12}[A-Za-z]{1}$';
export const TaxUZVatNumberRegExp = '^\\d{12}$';
export const TaxFJVatNumberRegExp = '^\\d{2}-\\d{5}-\\d{1}-\\d{1}$|^\\d{10}$';
export const TaxGTVatNumberRegExp = '^\\d{7}-[0-9]$';
export const TaxBSVatNumberRegExp = '^\\d{9}$';
export const TaxPHVatNumberRegExp = '^\\d{3}(-\\d{3}){2,3}$';
export const TaxTHVATRegExp = '^$|^\\d{13}$';
export const TaxNGVATRegExp = '^$|^\\d{8}-0001$';
export const TaxAUGSTNumberRegExp = '^$|\\d{11}$';
export const TaxCAGSTNumberRegExp = '^$|^\\d{9}(\\s?RT\\d{4})?$';
export const TaxCABCPSTNumberRegExp = '^$|^PST-\\d{4}-\\d{4}$';
export const TaxCAQCQSTNumberRegExp = '^$|^\\d{10}TQ\\d{4}$';
export const TaxCAMBPSTNumberRegExp = '^$|^\\d{6}\\-\\d$|^\\d{9}MT\\d{4}$';
export const TaxCASKPSTNumberRegExp = '^$|^\\d{7}$';
export const TaxAMVatNumberRegExp = '^\\d{8}\\/[0,1]$';
export const TaxBBVatNumberRegExp = '^\\d{13}$';
export const TaxKZVatNumberRegExp = '^\\d{12}$';
export const TaxMXVatNumberRegExp = '^[A-Za-z]{4}\\d{6}[A-Za-z0-9]{3}$|^\\S{3}\\d{6}\\S{3}$';
export const TaxZAVatNumberRegExp = '^\\d{10}$';
export const TaxLAVatNumberRegExp = '^\\d{12}$';
export const TaxNPVatNumberRegExp = '^\\d{9}$';
export const FCARegistrationNumberRegExp = '^$|^[0-9]{6,7}$';
export const TaxChileVATRegExp = '^$|^\\d{8}-(\\d{1}|K)$';
export const UnifiedSocialCreditIdentifierRegExp = '^$|^[0-9A-Z]{18}$';
export const TaxARVatNumberRegExp = '^30-\\d{8}-\\d{1}$';
export const TaxAZVatNumberRegExp = '^AZ\\d{10}$';
export const TaxBJVatNumberRegExp = '^\\d{13}$';
export const TaxCNVatNumberRegExp = '^[\\dA-Za-z]{15}($|[\\dA-Za-z]{3}$)';
export const TaxCIVatNumberRegExp = '^\\d{7}[A-Za-z]{1}$';
export const TaxJPVatNumberRegExp = '^\\d{13}$';
export const TaxMYVatNumberRegExp = '^[\\dA-Za-z]{1}\\d{2}-\\d{4}-\\d{8}$';
export const TaxMMVatNumberRegExp = '^\\d{9}$';
export const TaxSRVatNumberRegExp = '^\\d{10}$';
export const TaxTJVatNumberRegExp = '^\\d{9}$';
export const TaxUAVatNumberRegExp = '^[\\dA-Za-z]{12}$';
export const TaxVEVatNumberRegExp = '^[JGVE]-\\d{8}-?\\d{1}$';
export const TaxZMVatNumberRegExp = '^100\\d{7}$';
export const TaxEGVatNumberRegExp = '^\\d{9}$';
export const TaxKRVatNumberRegExp = '^KR\\d{3}-\\d{2}-\\d{5}$';
export const TaxPEVatNumberRegExp = '^\\d{11}$';
export const TaxUYVatNumberRegExp = '^\\d{12}$';
export const TaxPAVatNumberRegExp = '^[1-9]\\d{0,2}-\\d{1,3}-\\d{1,6}\\sDV:\\s\\d{1,2}$|^\\d{1,4}-\\d{1,4}-\\d{1,4}\\sDV:\\s\\d{1,2}$|^\\d{1,9}-[23]-\\d{4}\\sDV:\\s\\d{1,2}$';
export const TaxPKVatNumberRegExp = '^PK\\d{13}$';
export const TaxIERegExp = '^[\\dA-Za-z]{9,14}$';
