import _ from 'underscore';

/*
PRIORITY LIST:
  case1. Entity + field + error code
  case2. field + error code
  case3. No field + entity + error code
  case4. error code
  case5. Entity + field + server error message
  case6. Return error code without mapping as last option
errorMap structure for above case handling:
  {
    entity: {
      field: {
        errorCode: 'case1',
      },
      errorCode: 'case3',
    },
    field: {
      errorCode: 'case2',
    },
    errorCode: 'case4'
  }
*/
export const getMessageForErrorCode = (
  i18n,
  instrumentation,
  errorMap,
  {
    errorCode,
    entity,
    field,
    serverErrorMessage,
    errMsgObj = {},
  }
) => {
  if (entity && field
    && errorMap[entity]
    && errorMap[entity][field]
    && errorMap[entity][field][errorCode]
    && _.isString(errorMap[entity][field][errorCode])) {
    return i18n.getString(errorMap[entity][field][errorCode], errMsgObj);
  }
  if (field
    && errorMap[field]
    && errorMap[field][errorCode]
    && _.isString(errorMap[field][errorCode])) {
    return i18n.getString(errorMap[field][errorCode], errMsgObj);
  }
  if (!field && entity
    && errorMap[entity]
    && errorMap[entity][errorCode]
    && _.isString(errorMap[entity][errorCode])) {
    return i18n.getString(errorMap[entity][errorCode], errMsgObj);
  }
  if (errorMap[errorCode]
    && _.isString(errorMap[errorCode])) {
    return i18n.getString(errorMap[errorCode], errMsgObj);
  }

  if (entity && field && serverErrorMessage) {
    if (instrumentation && instrumentation.logTrace) {
      instrumentation.logTrace(`Error string not found for error code: ${errorCode}; entity: ${entity}; field: ${field}; using the server error message for now: ${serverErrorMessage}`);
    }
    return serverErrorMessage;
  }

  if (instrumentation && instrumentation.logError) {
    instrumentation.logError(`Error string not found for error code: ${errorCode}; entity: ${entity}; field: ${field}`);
  }

  return i18n.getString(_TL_('An unknown error occurred'));
};

export const getMessageForErrorCodeFn = (i18n, instrumentation, errorMap) => (
  args => getMessageForErrorCode(i18n, instrumentation, errorMap, args)
);
