/**
 * Base factory for conversion object
 * @param {Object} options - input for schema property bag and i18n
 */
export class BaseFactory {
  constructor(options) {
    this.prop = options.prop;
    this.i18n = options.i18n;
  }

  /**
   * Base method to create conversion object
   * @returns {Object} - pass-through conversion object
   */
  create() {
    const passThrough = value => value;

    return {
      fromModel: passThrough,
      toModel: passThrough,
    };
  }
}
