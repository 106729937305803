import _ from 'underscore';

function addInputToForm({ form, name, value }) {
  const hiddenField = document.createElement('input');

  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', name);
  hiddenField.setAttribute('value', value);

  form.appendChild(hiddenField);
}

/**
  * POSTS a form with a full page redirect
  * @param {url} url to POST to
  * @param {fields} fields which can be used for POSTing parameters
  * @returns {void} Nothing
*/
export const postRedirect = ({ url, fields }) => {
  const form = document.createElement('form');

  form.setAttribute('method', 'post');
  form.setAttribute('action', url);

  _.each(fields, (field) => {
    addInputToForm({ form, name: field.name, value: field.value });
  });

  document.body.appendChild(form);
  form.submit();
};
