import _ from 'underscore';
import URI from 'urijs';
import { JsDataRestStore } from '@bingads-webui/js-data-rest';
import { join } from '@bingads-webui/url-util';
import RestfulServiceBase from '@bingads-webui-clientcenter/restful-service';
import { staticResourceConfig } from './static-resource';

/**
 * Create RestfulService with given options, and use to create jsdata objects
 * @param {Object} options - constructor paramaters for '@bingads-webui-clientcenter/restful-service'
 * @param {string} rootUrl - url of root CC server
 * @param {Array} resourceNames - array of string jsData resource names like "FeatureAdoptionCoupon" to filter on
 * @return {Object} - jsdata rest service, and jsdata rest store
 */
export function GetJsDataInfo(options, rootUrl = '', resourceNames = []) {
  const restfulService = new RestfulServiceBase(options);
  const jsDataRestStore = new JsDataRestStore({ restfulService });
  const cleanedRootUrl = URI(rootUrl).path('').toString();

  const selectedResources = resourceNames && resourceNames.length > 0
    ? _.pick(staticResourceConfig, resourceNames)
    : staticResourceConfig;

  _.each(
    selectedResources,
    (config, name) => jsDataRestStore.defineRestResource(name, {
      ...config,
      uriPath: join(cleanedRootUrl, config.uriPath),
    })
  );

  const jsDataCcRest = jsDataRestStore.definitions;

  return { jsDataCcRest, jsDataRestStore };
}
