import _ from 'underscore';
import { VByVRequiredCurrencyCodes } from './constants';

/**
 * Get currency code property
 * @param {object} currencyValue Value object of currency
 * @param {number} currencyValue.Id Id of currency
 * @param {string} currencyValue.Code Code of currency
 * @returns {object} currency code, and empty string if code is not found
 */
export const getCurrencyCode = currencyValue => _.result(currencyValue, 'Code', '');

/**
 * Get extendCurrency object
 * @param {object} currencyValue Value object of currency
 * @param {number} currencyValue.Id Id of currency
 * @param {string} currencyValue.Code Code of currency
 * @returns {object} extendCurrency object
 */
export const extendCurrency = (currencyValue = {}) => _.defaults({}, currencyValue, {
  code: getCurrencyCode(currencyValue),
});

/**
 * Get currency value object by id
 * @param {number} id Id of currency
 * @param {bool} getExtendedObject It indicates if this function will return extended currency value object
 * @param {object[]} currencies List of currencies
 * @param {number} currencies[].id Id of currency
 * @param {object} currencies[].value Value object of currency
 * @param {number} currencies[].value.Id Id of currency
 * @param {string} currencies[].value.Code Code of currency
 * @returns {object} Currency value object, and null or default extendCurrency object if currency code is not found
 */
export const findCurrency = ({ currencies, id, getExtendedObject = false }) => {
  const currency = _.findWhere(currencies, { id });
  const currencyValue = _.result(currency, 'value', {});
  return getExtendedObject ? extendCurrency(currencyValue) : currencyValue;
};

/**
 * Sort currencies from MT domainData by localized currency name
 * @param {object[]} currencies List of currencies returned by domain data
 * @param {number} currencies[].Id Currency id
 * @param {string} currencies[].Code Currency Code
 * @param {number} currencies[].ControlledCurrencyCountryId It indicates if the currency is a controlled currency
 * @param {boolean} currencies[].DisplayName Currency display name
 * @returns {array} The sorted currencies
 */
export const sortCurrencies = (currencies) => {
  if (!_.isArray(currencies)) {
    return [];
  }
  return currencies.sort((item1, item2) => item1.DisplayName.localeCompare(item2.DisplayName, 'co', { sensitivity: 'base' }));
};

/**
 * Get controlled currency
 * @param {object[]} currencies List of currencies
 * @param {string} currencyType Currency type
 * @returns {object} return the controlled currency
 */
export const getControlledCurrency = ({ currencies, currencyType }) => _.find(currencies, curr => _.isNumber(curr.ControlledCurrencyCountryId) && curr.Code === currencyType);

/**
 * Get whether the provided currency should be verified by Visa
 * @param {string} currencyCode Code of currency
 * @returns {bool} indicate whether the provided currency should be verified by Visa
 */
export const isCurrencyRequiresVerifiedByVisa = currencyCode => _.contains(VByVRequiredCurrencyCodes, currencyCode);

/**
 * Get available currencies based on countryCourrenciesMapping
 * @param {string} countryCode Code of country
 * @param {string} currencies List of currencies
 * @param {string} countryCurrenciesMapping List of country currencies mapping
 * @returns {array} return sorted currencies based on countryCurrenciesMapping
 */
export const getAvailableCurrenciesByCountryCode = ({ countryCode, currencies, countryCurrenciesMapping }) => {
  if (!countryCode || !countryCurrenciesMapping) {
    return currencies;
  }
  const allowedCurrencyCodes = _.findWhere(countryCurrenciesMapping, { country: countryCode });
  const allowedCurrencies = _.filter(
    currencies,
    currency => _.contains(allowedCurrencyCodes ? allowedCurrencyCodes.currencies : [], currency.Code)
  );
  return sortCurrencies(allowedCurrencies);
};
