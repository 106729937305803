import _ from 'underscore';
import IWindow from '@bingads-webui-clientcenter/window-interface';
import { CouponType, CouponStatus } from './constants/coupon';
import { isAccountTaxable } from './tax-utils';

/**
 * Return the active coupons
 * @param {array} coupons an array of the coupons list
 * @returns {array} the list of active coupons
 */
export const getActiveCoupons = (coupons = []) => _.filter(coupons, coupon => coupon.StatusId === CouponStatus.ActiveCoupon);

/**
 * Return the upfront spending coupons
 * @param {array} coupons an array of the coupons list
 * @returns {array} the list of upfront spending coupons
 */
export const getUpfrontSpendingCoupons = (coupons = []) => _.filter(coupons, coupon => coupon.StatusId === CouponStatus.UpfrontSpending);

/**
 * Return the first available coupon
 * @param {array} coupons an array of the coupons list
 * @returns {array} the first available coupon, will return first active coupon. If no active coupons, return first upfront spending coupon
 */
export const getFirstAvailableCoupon = (coupons = []) => _.first(getActiveCoupons(coupons)) || _.first(getUpfrontSpendingCoupons(coupons));

/**
 * Return the upfront spending coupon threshold
 * @param {object} coupon the coupon object
 * @param {object} account the account object
 * @param {array} countries the countries list
 * @returns {number} upfront spending coupon threshold
 */
export const getCouponUpfrontSpendThreshold = ({ coupon, account, countries }) => {
  if (!coupon) {
    return null;
  }

  const { CouponType: couponType, UpfrontSpendThreshold: upfrontSpendThreshold, CurrentUpfrontSpend: currentUpfrontSpend = {} } = coupon;
  if (couponType === CouponType.Performance
    && currentUpfrontSpend === 0
    && currentUpfrontSpend < upfrontSpendThreshold
    && isAccountTaxable(account, countries)) {
    return upfrontSpendThreshold;
  }
  return null;
};

/**
 * @typedef {object} Coupon
 * @property {string} ExpiryDateFromAssociation - The expiry date from association
 * @property {string} EndDate - The end date
 * @property {string} ExpiryDate - The expiry date
 */

/**
 * Get asssociated coupon expiration date
 * @function
 * @param {Coupon} coupon - The coupon object
 * @returns {Date} the coupon expiration date
 */
export const getAssociatedCouponExpirationDate = (coupon) => {
  const {
    ExpiryDateFromAssociation,
    EndDate,
    ExpiryDate,
  } = coupon || {};

  return _.chain([ExpiryDateFromAssociation, EndDate, ExpiryDate])
    .compact()
    .map(dateString => new IWindow.Date(dateString))
    .sortBy()
    .first()
    .value();
};

/**
 * Whether the coupon is expired
 * @function
 * @param {Coupon} coupon - The coupon object
 * @returns {bool} Whether the coupon is expired
 */
export const isExpired = (coupon) => {
  const associatedCouponExpirationDate = getAssociatedCouponExpirationDate(coupon);
  return !!associatedCouponExpirationDate && associatedCouponExpirationDate < new IWindow.Date();
};

/**
 * Extend coupon with some useful functions
 * @function
 * @param {Coupon} coupon - The coupon to extend
 * @returns {Coupon} The extended coupon
 */
export const extendCoupon = coupon => _.defaults({}, coupon, {
  isExpired: () => isExpired(coupon),
  associatedCouponExpirationDate: () => getAssociatedCouponExpirationDate(coupon),
});

/**
 * Get PBC offer description
 * @function
 * @param {object} options - The options
 * @param {object} options.i18n - The i18n object
 * @param {string} options.currencyCode - The currency code
 * @param {number} options.performanceCoupon - The performance coupon value
 * @param {number} options.upfrontSpend - The upfront spend threshold
 * @returns {string} The PBC offer description
 */
export const getPBCOfferDescription = ({
  i18n,
  currencyCode,
  performanceCoupon,
  upfrontSpend,
}) => i18n.getString('CouponDetails_PerformanceCombinedValueThresholdLabel_OneLine')
  .replace('{0}', i18n.formatCurrency(performanceCoupon, currencyCode, { maximumFractionDigits: 0 }))
  .replace('{1}', i18n.formatCurrency(upfrontSpend, currencyCode, { maximumFractionDigits: 0 }));
