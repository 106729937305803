import { StateStore } from '@bingads-webui/state-store';
import React, { ComponentClass } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Subscription } from '@bingads-webui-universal/observable';
import _ from 'underscore';

export function withStateStore<T, S extends StateStore>(
  ComponentToWrap: ComponentClass<T> | React.FC<any>,
  storeFactory: S | (() => S),
  selector: string | ((store: S) => Object) = 'store',
) {
  if (typeof storeFactory !== 'function') {
    const store = storeFactory;
    // eslint-disable-next-line no-param-reassign
    storeFactory = () => store;
  }

  let select: any = selector;
  if (typeof selector === 'string') {
    const storeName: string = selector;
    select = (store: StateStore) => {
      const res: any = {};
      res[storeName] = store;
      return res;
    };
  }

  interface RefProps {
    forwardedRef: {
      current: any;
    };
  }

  class WrappedComponent extends React.PureComponent<Partial<T> & RefProps> {
    private subscription: Subscription | undefined;

    private store: StateStore;

    constructor(props: Partial<T> & RefProps) {
      super(props);
      this.state = {};
      this.store = this.createStore();
    }

    componentDidMount() {
      this.subscribe();
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    createStore() {
      if (storeFactory instanceof StateStore) {
        return storeFactory;
      }
      // @ts-ignore
      return storeFactory();
    }

    subscribe() {
      this.subscription = this.store.subscribe((state: any) => {
        if (!this.subscription?.closed) {
          this.setState(state);
        }
      });
    }

    unsubscribe() {
      (this.subscription as Subscription).unsubscribe();
    }

    render() {
      const props = _.extend({}, this.props, select(this.store));
      return <ComponentToWrap {...props} ref={this.props.forwardedRef} />;
    }
  }

  // @ts-ignore
  return React.forwardRef((props: any, ref) => <WrappedComponent {...props} forwardedRef={ref} />);
}
