/* istanbul ignore file */
import { iconBase64 } from './global-error-icon';

export const getStyle = (_, { palette = {} }) => ({
  errorPage: {
    width: '100%',
    height: '100%',
    minWidth: 800,
    backgroundColor: palette.white,
    position: 'relative',
  },

  errorPageContent: {
    position: 'absolute',
    top: 163,
    height: 230,
    width: 727,
    left: '50%',
    transform: 'translate(-50%, 0)',
    '-webkit-transform': 'translate(-50%, 0)',
  },

  errorPageIcon: {
    width: 230,
    height: 230,
    position: 'absolute',
    backgroundImage: `url("data:image/svg+xml;base64, ${iconBase64}")`,
    backgroundSize: '230px 230px',
  },

  errorPageTxtContent: {
    position: 'absolute',
    left: 288,
    top: '50%',
    transform: 'translate(0, -50%)',
    '-webkit-transform': 'translate(0, -50%)',
  },

  errorPageTxtTitle: {
    color: palette.neutralPrimary,
    fontSize: 26,
    fontWeight: 600,
  },

  errorPageTxtDesc: {
    color: palette.neutralPrimary,
    fontSize: 14,
    marginTop: 16,
  },

  errorPageTxtRefresh: {
    color: '#0078D4',
    fontSize: 14,
    marginTop: 16,
    cursor: 'pointer',
    width: 107,

    '&:focus': {
      border: `1px solid ${palette.neutralSecondary}`,
      outline: 'none',
    },
    '&:hover': {
      color: '#23527c',
      textDecoration: 'underline',
    },
  },
});
