export const StandardDateFormat = 'M/d/yyyy';

export const MillisecondsPerDay = 86400000;

export const FrequencyMonthCountMap = Object.freeze({
  Monthly: 1,
  BIMonthly: 2,
  Quarterly: 3,
  Yearly: 12,
});

export const FrequencyDayCountMap = Object.freeze({
  Monthly: 31,
  BIMonthly: 62,
  Quarterly: 92,
  Yearly: 366,
});

export const FrequencyMonthI18nKeyMap = Object.freeze({
  Monthly: _TL_('Monthly'),
  BIMonthly: _TL_('Every 2 months'),
  Quarterly: _TL_('Quarterly'),
  Yearly: _TL_('Yearly'),
});
