/**
 * Mapping to BillingMT proxy InsertionOrderStatus
 */
export const InsertionOrderStatus = Object.freeze({
  PendingSystemReview: 0,
  PendingUserReview: 1,
  Active: 2,
  Declined: 3,
  Expired: 4,
  Canceled: 5,
  Pending: 6,
  Exhausted: 7,
  PendingBillToPartyChange: 8,
  PendingBillToPartyChangeAndUserReview: 9,
  Queued: 11,
});

/**
 * Mapping to BillingMT proxy InsertionOrderLabel
 */
export const InsertionOrderLabel = Object.freeze({
  None: 0,
  Expiring: 1,
  Exhausting: 2,
  Expired: 4,
  NoBudget: 8,
});

/**
 * Max and min value about InsertionOrder fields
 * For io creation, max comment length is 100; While editing, comment length can be up to 500
 */
export const IOMaxValues = Object.freeze({
  budgetValue: 1000000000,
  agencyIdOrPoLength: 50,
  nameLength: 100,
  commentLength: 100,
  editCommentLength: 500,
});

export const IOMinValues = Object.freeze({
  budgetValue: 0.01,
});

export const IOCreationSource = Object.freeze({
  UIInsertionOrder: 1,
  UIBatchInsertionOrder: 2,
  BulkIOImport: 3,
  RecurringInsertionOrder: 4,
  BillingTakeOver: 5,
  AgencyLinking: 6,
  Reparenting: 7,
  PaymentOptionSwitch: 8,
  CCAPIAddInsertionOrder: 9,
  IORecommendation: 10,
});

export const InsertionOrdersGridFieldNames = Object.freeze({
  AccountId: 'AccountId',
  AccountName: 'AccountName',
  AccountNumber: 'AccountNumber',
  Action: 'Action',
  AgencyIdOrPo: 'AgencyIdOrPo',
  BalanceAmount: 'BalanceAmount',
  BillToCustomerName: 'BillToCustomerName',
  BillToCustomerNumber: 'BillToCustomerNumber',
  BudgetPerOrder: 'BudgetPerOrder',
  Comment: 'Comment',
  CompanyName: 'CompanyName',
  CurrencyType: 'CurrencyType',
  DaysToEndDate: 'DaysToEndDate',
  DealPointId: 'DealPointId',
  EndDate: 'EndDate',
  EndDateText: 'EndDateText',
  EndOccurrences: 'EndOccurrences',
  EstDaysToExhaustion: 'EstDaysToExhaustion',
  Frequency: 'Frequency',
  FriendlyName: 'FriendlyName',
  FromDate: 'FromDate',
  InsertionOrderId: 'InsertionOrderId',
  InsertionOrderName: 'InsertionOrderName',
  InsertionOrderLabel: 'InsertionOrderLabel',
  IOCreationSource: 'IOCreationSource',
  IsAgencyIdOrPoEditable: 'IsAgencyIdOrPoEditable',
  IsCommentEditable: 'IsCommentEditable',
  IsDealPointIdEditable: 'IsDealPointIdEditable',
  IsEndDateEditable: 'IsEndDateEditable',
  IsInSeries: 'IsInSeries',
  IsInsertionOrderNameEditable: 'IsInsertionOrderNameEditable',
  IsRecordCenterIdEditable: 'IsRecordCenterIdEditable',
  IsSpendCapAmountEditable: 'IsSpendCapAmountEditable',
  IsStartDateEditable: 'IsStartDateEditable',
  LastModifiedDate: 'LastModifiedDate',
  ModifiedByUser: 'ModifiedByUser',
  Notes: 'Notes',
  OrderName: 'OrderName',
  OrderType: 'OrderType',
  PurchaseOrder: 'PurchaseOrder',
  RecommendationId: 'RecommendationId',
  RecommendationType: 'RecommendationType',
  RecordCenterId: 'RecordCenterId',
  RecurringIOId: 'RecurringIOId',
  RemainingIOBudgetPercent: 'RemainingIOBudgetPercent',
  SeriesEndDate: 'SeriesEndDate',
  SeriesEndDateString: 'SeriesEndDateString',
  SeriesEndOption: 'SeriesEndOption',
  SeriesFrequencyType: 'SeriesFrequencyType',
  SeriesName: 'SeriesName',
  SeriesStartDate: 'SeriesStartDate',
  SeriesStartDateString: 'SeriesStartDateString',
  SpendCapAmount: 'SpendCapAmount',
  SpentIOBudget: 'SpentIOBudget',
  SpentIOBudgetPercent: 'SpentIOBudgetPercent',
  StartDate: 'StartDate',
  StartDateText: 'StartDateText',
  Status: 'Status',
  StatusValue: 'StatusValue',
  ThruDate: 'ThruDate',
});

const MaxLengths = {
  FriendlyName: 100,
  RecordCenterId: 100,
  AgencyIdOrPo: 50,
  Comment: 500,
  Name: 60,
};

const UpperBound = {
  SpendCapAmount: 1000000000,
  DealPointId: 2147483647, // int.Max
  Occurrences: 60,
  ExtendDays: 30,
};

const LowerBound = {
  BackupSpendCapAmount: 0,
  ExtendDays: 0,
};

const SpendCapAmountLowerBound = 0.01;

export const WarningSpendCapAmountIncreaseFactor = 10;

export const UnlimitedBudget = 1000000000000;

export const EndlessDate = new Date(9999, 0, 1);

export const ImmediateDate = new Date(1970, 0, 1);

export const IOFormInputSchema = {
  properties: {
    SpendCapAmount: {
      type: 'number',
      required: true,
      minimum: SpendCapAmountLowerBound,
      maximum: UpperBound.SpendCapAmount,
    },
    ExtendDays: {
      type: 'integer',
      required: true,
      minimum: LowerBound.ExtendDays,
      maximum: UpperBound.ExtendDays,
    },
    BackupSpendCapAmount: {
      type: 'number',
      required: true,
      minimum: LowerBound.BackupSpendCapAmount,
      maximum: UpperBound.SpendCapAmount,
    },
    AgencyIdOrPo: {
      type: 'string',
      nullable: true,
      maxLength: MaxLengths.AgencyIdOrPo,
    },
    FriendlyName: {
      type: 'string',
      nullable: true,
      maxLength: MaxLengths.FriendlyName,
    },
    Comment: {
      type: 'string',
      nullable: true,
      maxLength: MaxLengths.Comment,
    },
    DealPointId: {
      type: 'integer',
      minimum: 0,
      nullable: true,
      maximum: UpperBound.DealPointId,
    },
    RecordCenterId: {
      type: 'string',
      nullable: true,
      maxLength: MaxLengths.RecordCenterId,
    },
    FromDate: {
      type: 'string',
      nullable: true,
      required: true,
    },
    ThruDate: {
      type: 'string',
      nullable: true,
      required: true,
    },
    Occurrences: {
      type: 'integer',
      minimum: 1,
      nullable: true,
      maximum: UpperBound.Occurrences,
      required: true,
    },
    Name: {
      type: 'string',
      nullable: true,
      required: true,
      maxLength: MaxLengths.Name,
    },
  },
};

export const RIOThruDateOptions = {
  EndByDate: 'EndByDate',
  NoEndDate: 'NoEndDate',
  EndByOccurrences: 'EndByOccurrences',
};

export const IOFilter = {
  AccountPaused: JSON.stringify([{ Field: 'Status', Operator: '2', Values: '7,4' }]),
  Expiring: JSON.stringify([{ Field: 'DaysToEndDate', Operator: '9', Values: '7' }]),
  Exhausting: JSON.stringify([{ Field: 'EstDaysToExhaustion', Operator: '9', Values: '7' }]),
};

export const IOEntryPointAlias = {
  Default: 'default',
  RIONotificationQueue: 'rionq',
  UcmHealthCheck: 'ucmhc',
  ConBNotificationQueue: 'conbnq',
  NotificationEmail: 'noemail',
  IONotificationsEnhancement: 'IOARE',
  BudgetCenter: 'bgtct',
};

export const IOEntryPointLogMessage = {
  [IOEntryPointAlias.Default]: 'defaultPage',
  [IOEntryPointAlias.RIONotificationQueue]: 'recurringIONotificationQueue',
  [IOEntryPointAlias.UcmHealthCheck]: 'ucmHealthCheck',
  [IOEntryPointAlias.ConBNotificationQueue]: 'consolidatedBudgetNotificationQueue',
  [IOEntryPointAlias.NotificationEmail]: 'notificationEmail',
  [IOEntryPointAlias.IONotificationsEnhancement]: 'IOARE',
  [IOEntryPointAlias.BudgetCenter]: 'budgetCenter',
};

export const IOInputItemNames = {
  AccountName: 'AccountName',
  AccountId: 'AccountId',
  SpendCapAmount: 'SpendCapAmount',
  FromDate: 'FromDate',
  ThruDate: 'ThruDate',
  FriendlyName: 'FriendlyName',
  AgencyIdOrPo: 'AgencyIdOrPo',
  Comment: 'Comment',
  Currency: 'Currency',
  CurrencyType: 'CurrencyType',
  DealPointId: 'DealPointId',
  RecordCenterId: 'RecordCenterId',
  Name: 'Name',
  FrequencyType: 'FrequencyType',
  SeriesEndOption: 'SeriesEndOption',
  RepeatCount: 'RepeatCount',
  SeriesStartDate: 'SeriesStartDate',
  SeriesEndDate: 'SeriesEndDate',
  BudgetPerOrder: 'BudgetPerOrder',
  BackupIO: 'BackupIO',
  AccountBackupIOConfigModel: 'AccountBackupIOConfigModel',
  BackupSpendCapAmount: 'BackupSpendCapAmount',
  ExtendDays: 'ExtendDays',
  RecommendationId: 'RecommendationId',
  IOInlineSmartnessInfo: 'IOInlineSmartnessInfo',
  StartDateRecommendationItem: 'StartDateRecommendationItem',
  BudgetRecommendationItem: 'BudgetRecommendationItem',
  GeneralRecommendationItem: 'GeneralRecommendationItem',
};

export const IOInlineSmartnessItemNames = {
  // Suggested start date fields
  SuggestedStartDate: 'SuggestedStartDate',
  LastInputStartDate: 'LastInputStartDate',

  // Suggested budget fields
  SuggestedBudget: 'SuggestedBudget',
  LastInputBudget: 'LastInputBudget',
  SuggestedDailySpend: 'SuggestedDailySpend',

  // General recommendation fields
  StartDate: 'StartDate',
  EndDate: 'EndDate',
  SpendCapAmount: 'SpendCapAmount',
  FrequencyType: 'FrequencyType',
  SeriesName: 'SeriesName',

  // Common fields
  IsDisplayed: 'IsDisplayed',
  IsApplyButtonClicked: 'IsApplyButtonClicked',
};

export const IOPageLastVisitDateUserPerfName = 'IOPageLastVisitDate';
