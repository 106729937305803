/* eslint-disable jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, no-script-url */
/* global PopupController */

// NOTE: preferably use HelpButton to avoid accessibility issues
import PropTypes from 'prop-types';
import React from 'react';

/**
 * @param {MouseEvent} event click event
 * @return {void}
 */
function onClick(event) {
  event.preventDefault();
  event.stopPropagation();
  if (!window.PopupController) {
    return;
  }
  PopupController.PopupJsClick(event.target);
}

export function HelpPopup(props) {
  return (
    <a
      aria-label={props['aria-label']}
      aria-labelledby={props['aria-labelledby']}
      className="helpbutton"
      href="#"
      onClick={onClick}
      topic={props.topic}
      type="helppopup"
      role="button"
    />
  );
}

HelpPopup.propTypes = {
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  topic: PropTypes.string.isRequired,
};

HelpPopup.defaultProps = {
  'aria-label': null,
  'aria-labelledby': null,
};
