import _ from 'underscore';

/**
 * Display format for user email and name
 * @param {object} user User object
 * @param {string} user.Email User email
 * @param {object} user.Name User name object
 * @param {string} user.Name.FirstName User firstName
 * @param {string} user.Name.LastName User lastName
 * @returns {string} The display format for given user
 */
export const getUserDisplayByUser = (user = {}) => {
  const names = [_.result(user.Name, 'FirstName'), _.result(user.Name, 'LastName')];
  const nameString = _.compact(names).join(' ');
  const emailString = user.Email || '';
  return _.compact([emailString, nameString]).join(' - ');
};


/**
 * Find user by id and get display format
 * @param {object[]} users List of users
 * @param {string} id User id string
 * @param {string} users[].Email User email
 * @param {object} users[].Name User name object
 * @param {string} users[].Name.FirstName User firstName
 * @param {string} users[].Name.LastName User lastName
 * @returns {array} The display format of user for given id
 */
export const getUserDisplayById = (users, id) => {
  const user = _.findWhere(users, { id });
  return getUserDisplayByUser(user);
};

/**
 * Returns a copy of the given user object with additional utility methods.
 * @param {object} user The user object to be extended with additional utility methods.
 * @returns {object} A copy of the extended user object.
 */
export const extendUser = (user = {}) => _.defaults({}, user, {
  userDisplay: () => getUserDisplayByUser(user),
});

/**
 * Check if user is missing master data in TST scope
 * @param {object} user The user object to be extended with additional utility methods
 * @param {object} user.Name The user Name object containing first and last name
 * @param {object} user.Address The user Address object
 * @param {bool} checkName Flag to check if user name is missing
 * @returns {bool} Value indicating if user is missing master data
 */
export const isMissingMasterData = ({ Name = {}, Address = {} } = {}, checkName = false) =>
  (checkName && (_.isEmpty(Name.FirstName) || _.isEmpty(Name.LastName))) || _.isEmpty(Address.Line1) || _.isEmpty(Address.City) || _.isEmpty(Address.Country);
