export const FCAVerificationType = {
  FS: 1,
  Gov: 2,
  NonFS: 3,
};

export const FCAAccountStatus = {
  Pending: 1,
  Verified: 2,
  VerifiedLimited: 3,
  Failed: 4,
  NotStarted: 5,
  NoNeed: 6,
};

export const FCAAccountDomainStatus = {
  Create: 1,
  NQPPending: 2,
  Verified: 3,
  Failed: 4,
  Deleted: 5,
};

export const FCAVerificationTypeString = {
  [FCAVerificationType.FS]: 'UK FCA authorized financial services advertiser',
  [FCAVerificationType.Gov]: 'Government entity',
  [FCAVerificationType.NonFS]: 'Non-financial services advertiser',
};

export const FCAVerificationTypeLegacyString = {
  [FCAVerificationType.FS]: _TL_('UKFCA_internal_VerificationType_FS'),
  [FCAVerificationType.Gov]: _TL_('UKFCA_internal_VerificationType_GOV'),
  [FCAVerificationType.NonFS]: _TL_('UKFCA_internal_VerificationType_NONFS'),
};

export const FCAVerificationAccountStatusString = {
  [FCAAccountStatus.Pending]: 'Pending',
  [FCAAccountStatus.Verified]: 'Verified',
  [FCAAccountStatus.VerifiedLimited]: 'Partially verified',
  [FCAAccountStatus.Failed]: 'Failed',
  [FCAAccountStatus.NotStarted]: 'Not started',
  [FCAAccountStatus.NoNeed]: 'Not in pilot',
};

export const FCAVerificationAccountStatusLegacyString = {
  [FCAAccountStatus.Pending]: _TL_('UKFCA_internal_AccountStatus_Pending'),
  [FCAAccountStatus.Verified]: _TL_('UKFCA_internal_AccountStatus_Verified'),
  [FCAAccountStatus.VerifiedLimited]: _TL_('UKFCA_internal_AccountStatus_VerifiedLimited'),
  [FCAAccountStatus.Failed]: _TL_('UKFCA_internal_AccountStatus_Failed'),
  [FCAAccountStatus.NotStarted]: _TL_('UKFCA_internal_AccountStatus_NotStarted'),
  [FCAAccountStatus.NoNeed]: _TL_('UKFCA_internal_AccountStatus_NoNeed'),
};

export const CanTriggerFCAScenario = {
  Create: 'CanTriggerFCACreate',
  Update: 'CanTriggerFCAUpdate',
};

export const UnableTriggerFCAReason = {
  UserRoleMismatch: 1,
  NotAccountOwner: 2,
  FCAStatusMismatch: 3,
  Unauthorized: 4,
  NotInPilot: 5,
};
