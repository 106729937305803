import _ from 'underscore';
import { FileUtils } from '@bingads-webui-clientcenter/common-utils';
import Http from './http';

const { buildFormData } = FileUtils;

export const convertToFormData = data => (data instanceof FormData ? data : buildFormData(new FormData(), data));

const finalOptions = (data, options) => {
  // If used by File Upload then do not JSONStringify data nor set the contentType
  const useFormDataFormat = options && options.useFormDataFormat;
  let defaultOptions;

  if (useFormDataFormat) {
    // Refer to https://stackoverflow.com/questions/19617996/file-upload-without-form/26690647#26690647
    defaultOptions = {
      data: convertToFormData(data),
      processData: false,
      contentType: false,
      dataType: 'json',
    };
  } else if (!_.isString(data)) {
    defaultOptions = {
      data: JSON.stringify(data),
      contentType: 'application/json',
      dataType: 'json',
    };
  } else {
    defaultOptions = {
      data,
      dataType: 'json',
    };
  }

  return _.defaults({}, options, defaultOptions);
};

export class RestfulService {
  /**
   * Constructor for restful service
   * @param {Function} cidFetcher - method for getting the cid - this is a method so that it can handle the cid changing
   * @param {Function} uidFetcher - method for getting the uid - this is a method so that it can handle the uid changing
   * @param {Object} ccuiAlertCenter - if in use, the alert center to show alerts in
   * @param {Function} handleAjaxAuthFailure - method for handling AjaxAuthFailures
   * @param {Function} appendErrorSummary - method for adding errors
   * @param {Function} ajaxSuccess - method to use when ajax call was successful
   * @param {Boolean} willSupportCors - when true, it'll add a xhrFields.withCredentials=true and x-requested-with=xhr by default
   */
  constructor({
    cidFetcher,
    uidFetcher,
    ccuiAlertCenter,
    handleAjaxAuthFailure,
    appendErrorSummary,
    ajaxSuccess,
    willSupportCors = false,
  } = {}) {
    this.http = new Http({
      cidFetcher,
      uidFetcher,
      ccuiAlertCenter,
      handleAjaxAuthFailure,
      appendErrorSummary,
      ajaxSuccess,
      willSupportCors,
    });
  }

  get = (uri, options = {}) => {
    const settings = _.defaults({}, options, { dataType: 'json' });

    return this.http.get(uri, settings);
  };

  post = (uri, attrs = {}, options = {}) => {
    const settings = finalOptions(attrs, options);

    return this.http.post(uri, settings);
  };

  put = (uri, attrs = {}, options = {}) => {
    const settings = finalOptions(attrs, options);

    return this.http.put(uri, settings);
  };

  patch = (uri, attrs = {}, options = {}) => {
    const settings = finalOptions(attrs, options);

    return this.http.patch(uri, settings);
  };

  delete = (uri, options = {}) => {
    const settings = _.defaults({}, options, { dataType: 'json' });

    return this.http.delete(uri, settings);
  };
}
