import _ from 'underscore';

export default class {
  constructor({
    ccuiAlertCenter,
    handleAjaxAuthFailure = _.noop,
    appendErrorSummary = _.noop,
    ajaxSuccess = _.noop,
  } = {}) {
    this.ccuiAlertCenter = ccuiAlertCenter;
    this.handleAjaxAuthFailure = handleAjaxAuthFailure;
    this.appendErrorSummary = appendErrorSummary;
    this.ajaxSuccess = ajaxSuccess;
  }

  beforeSend = options => _.wrap(options.beforeSend, (func, xhr) => {
    if (options.useAlertCenter && this.ccuiAlertCenter && options.alertId) {
      this.ccuiAlertCenter.dismiss(options.alertId);
    }

    if (_.isFunction(func)) {
      func(xhr);
    }
  });

  success = options => _.wrap(options.onSuccess, (func, data, textStatus, xhr) => {
    this.handleAjaxAuthFailure(data);
    if (options.useAlertCenter && options.alertId) {
      if (data && this.ccuiAlertCenter) {
        if (!_.isEmpty(data.Errors)) {
          this.ccuiAlertCenter.appendWarning(data.Errors, options.alertId);
        } else if (!_.isEmpty(data.Info)) {
          this.ccuiAlertCenter.appendInfo(data.Info, options.alertId);
        } else if (!_.isEmpty(data.Confirmation)) {
          this.ccuiAlertCenter.appendSuccess(data.Confirmation, options.alertId);
        }
      }
    } else if (data && !_.isEmpty(data.Errors)) {
      if (_.isFunction(options.onJsonError)) {
        options.onJsonError(data, textStatus, xhr);
      } else {
        this.appendErrorSummary(data.Errors);
      }
    } else if (data && !_.isEmpty(data.Alert)) {
      this.ajaxSuccess(data);
    }

    if (_.isFunction(func)) {
      func(data, textStatus, options.data, options.onsuccessEventData, xhr);
    }
  });

  error = options => _.wrap(options.onAjaxError, (func, xhr, textStatus, errorThrown) => {
    if (_.isFunction(func)) {
      func(xhr, textStatus, errorThrown);
    }
  });

  complete = options => _.wrap(options.onAjaxComplete, (func, xhr, textStatus) => {
    if (_.isFunction(func)) {
      func(xhr, textStatus);
    }
  });
}
