import _ from 'underscore';

import { AdjustmentReason } from './constants/adjustment-reason';

/**
 * Sort countries from MT domainData by localized country name
 * @param {object[]} reasons List of countries returned by domain data
 * @param {number} reasons[].id Adjustment reason id
 * @param {object} reasons[].value Adjustment reason value object
 * @param {number} reasons[].value.Id Adjustment reason id
 * @param {string} reasons[].value.Name Adjustment reason name
 * @param {object} i18n i18n
 * @returns {array} The mapped adjustment reason filtered by new adjustment ids
 */
export const mapNewAdjustmentReasons = (reasons, i18n) => {
  const unselectedReason = { id: AdjustmentReason.None, value: i18n.getString('AdjustmentReason_UnselectedItemText') };
  const newAdjustmentReasonsFormatted = _.chain(reasons)
    .filter(reason => _.contains(_.values(AdjustmentReason), reason.id))
    .map(reason => ({
      id: reason.id,
      value: i18n.getString(`AdjustmentReason_${reason.id}`),
    }))
    .value();

  return [unselectedReason, ...newAdjustmentReasonsFormatted];
};
