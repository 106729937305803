/* eslint-disable react/forbid-prop-types */
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';
import { ScenarioContext } from './scenario-context-provider';

// if no activity context is provided, defaultActivity will be used
export const ActivityContext = React.createContext();

export const Activity = (props) => {
  const context = useContext(ScenarioContext);

  const activityContext = useMemo(() => (context && props.name ?
    context.createActivity(props.name) :
    undefined
  ), [context, props.name]);

  return (
    <ActivityContext.Provider value={activityContext}>
      {props.children}
    </ActivityContext.Provider>);
};

Activity.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string,
};

Activity.defaultProps = {
  name: undefined,
};

export const withActivityContext = (WrappedComponent) => {
  const HoC = props => (
    <ActivityContext.Consumer>
      {context => (<WrappedComponent
        {...props}
        activity={context}
      />)}
    </ActivityContext.Consumer>
  );

  HoC.displayName = `withActivityContext(${getDisplayName(WrappedComponent)})`;

  return hoistNonReactStatics(HoC, WrappedComponent);
};
