// NOTE: preferably use HelpButton to avoid accessibility issues

import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { memo, useContext } from 'react';
import classNames from 'classnames';
import { I18nContext } from '@bingads-webui-react/i18n-context';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { HelpPopup } from './help-popup';

export const InlineHelpUnstyled = memo((props) => {
  const {
    classes,
    topic,
    withoutSpace,
    onClick,
    ariaLabel,
  } = props;

  const i18n = useContext(I18nContext);

  return (
    <span
      className={classNames({
        [classes.inlineHelp]: true,
        [classes.withSpace]: !withoutSpace,
      })}
      role="presentation"
      onClick={onClick}
    >
      <HelpPopup
        aria-label={ariaLabel || i18n.getString(_TL_('Learn more'))}
        topic={topic}
      />
    </span>
  );
});

InlineHelpUnstyled.propTypes = {
  topic: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    inlineHelp: PropTypes.string,
  }).isRequired,
  withoutSpace: PropTypes.bool,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};

InlineHelpUnstyled.defaultProps = {
  withoutSpace: false,
  onClick: _.noop,
  ariaLabel: null,
};

export const HelpPopupInline = withDefaultStyles(
  InlineHelpUnstyled,
  (components, { palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
    inlineHelp: {
      display: 'inline-block',
      fontSize: '15px !important',
      fontWeight: 'normal',

      '& .helpbutton': {
        paddingTop: '2px',
        paddingLeft: '2px',

        '&::before': {
          content: '"\\E9CE"',
          fontFamily: iconFontFamily,
          position: 'relative',
          top: 2,
        },

        '&:focus': {
          outline: '1px solid',
          outlineColor: palette.neutralSecondary,
          border: 'none',
          color: palette.black,
          textDecoration: 'none',
        },

        '&:hover': {
          backgroundColor: palette.neutralLighter,
          color: palette.black,
          textDecoration: 'none',
        },

        '&:active': {
          backgroundColor: palette.neutralLight,
          color: palette.black,
          textDecoration: 'none',
        },
      },
    },

    withSpace: {
      marginLeft: 3,
    },
  })
);

