import _ from 'underscore';
import { EntityType } from './constants/common';

/**
 *  Stores the dictionary of UI external role information.
 */
export const UIExternalRoles = {
  ApiUser: {
    RoleId: 27, IsAccountScope: true, IsAbsolute: true, CanBeSwitchedOut: true,
  },
  AdvertiserManager: {
    RoleId: 16, IsAccountScope: true, IsAbsolute: false, CanBeSwitchedOut: false,
  },
  SuperAdmin: {
    RoleId: 41, IsAccountScope: false, IsAbsolute: false, CanBeSwitchedOut: false,
  },
  AdvertiserAdmin: {
    RoleId: 68, IsAccountScope: false, IsAbsolute: true, CanBeSwitchedOut: false,
  },
  ClientAdmin: {
    RoleId: 94, IsAccountScope: true, IsAbsolute: true, CanBeSwitchedOut: true,
  },
  ClientViewer: {
    RoleId: 100, IsAccountScope: true, IsAbsolute: false, CanBeSwitchedOut: true,
  },
  StandardUser: {
    RoleId: 203, IsAccountScope: true, IsAbsolute: false, CanBeSwitchedOut: true,
  },
};

export const hasCustomerLevelRoleEntities = roles => _.any(roles, role => role.EntityType === EntityType.Customer);

export const getAccessibleAccountIds = roles => _.chain(roles)
  .where({ EntityType: EntityType.Account })
  .pluck('EntityId')
  .value();

export const isAccountScopeUserRole = roleId => _.chain(UIExternalRoles)
  .findWhere({ RoleId: Number(roleId) })
  .get('IsAccountScope')
  .value();

export const userRoleCanBeSwitchedOut = roleId => _.findWhere(UIExternalRoles, { RoleId: roleId }).CanBeSwitchedOut;
