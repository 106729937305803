import {
  PrimaryButton as FabricPrimaryButton,
  IconButton as FabricIconButton,
  DefaultButton as FabricDefaultButton,
  ChoiceGroup as FabricChoiceGroup,
  Link as FabricLink,
  SearchBox as FabricSearchBox,
  Dropdown as FabricDropdown,
  TextField as FabricTextField,
  Checkbox as FabricCheckbox,
  Toggle as FabricToggle,
} from '@bingads-webui-react/fabric-control';

import {
  withSignal,
  CommonEventParser,
  FabricDropdownParser,
} from '@bingads-webui-react/hoc-with-signal';

export const PrimaryButton = withSignal(FabricPrimaryButton, {
  onClick: CommonEventParser,
});

export const DefaultButton = withSignal(FabricDefaultButton, {
  onClick: CommonEventParser,
});

export const ChoiceGroup = withSignal(FabricChoiceGroup, {
  onChange: CommonEventParser,
});

export const Link = withSignal(FabricLink, {
  onClick: CommonEventParser,
});

export const SearchBox = withSignal(FabricSearchBox, {
  onChange: CommonEventParser,
});

export const Dropdown = withSignal(FabricDropdown, {
  onChange: FabricDropdownParser,
});

export const TextField = withSignal(FabricTextField, {
  onChange: CommonEventParser,
});

export const Checkbox = withSignal(FabricCheckbox, {
  onChange: CommonEventParser,
});

export const Toggle = withSignal(FabricToggle, {
  onChange: CommonEventParser,
});

export const IconButton = withSignal(FabricIconButton, {
  onClick: CommonEventParser,
});
