export const ERROR_MESSAGES = {
  InvalidValue: _TL_('Invalid value'),
  ad: {
    InvalidValue: _TL_('Invalid ad'),
    url: {
      InvalidValue: _TL_('Invalid ad url'),
    },
    title1: {
      InvalidValue: _TL_('Invalid ad title1'),
    },
  },
  business: {
    InvalidValue: _TL_('Invalid business'),
    url: {
      InvalidValue: _TL_('Invalid business url'),
    },
    name: {
      InvalidValue: _TL_('Invalid business name'),
    },
  },
};
