import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

import { PreferenceContext } from './context';

/**
 * HoC for injecting user preference service into a React component.
 * It assumes that PreferenceContext.Provider has been initialized
 * somewhere up the component hierarchy.
 * @param {React.Component} WrappedComponent - component to wrap
 * @returns {React.Component} an enhanced version of the argument
 */
export const withPreference = (WrappedComponent) => {
  const HoC = props => (
    <PreferenceContext.Consumer>
      {preferenceService => <WrappedComponent {...props} preferenceService={preferenceService} />}
    </PreferenceContext.Consumer>
  );
  HoC.displayName = `withPreference(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(HoC, WrappedComponent);

  return HoC;
};
