import _ from 'underscore';
import { DateUtils } from '@bingads-webui-clientcenter/common-utils';

import {
  InsertionOrderStatus,
  IOCreationSource,
  InsertionOrdersGridFieldNames,
  UnlimitedBudget,
  EndlessDate,
  InsertionOrderLabel,
  ImmediateDate,
} from './constants/insertion-order';

const { isDateWithoutTimeEqual } = DateUtils;

/**
 * Get whether this insertion order is pending under takeover billing linked accounts
 * @param {object} insertionOrder Value object of insertion order
 * @returns {bool} Returns true if this insertion order is pending under takeover billing linked accounts.
 */
export const isPendingTakeoverBillingIO = insertionOrder => _.isObject(insertionOrder) &&
  insertionOrder.IOCreationSource === IOCreationSource.BillingTakeOver &&
  (insertionOrder.Status === InsertionOrderStatus.PendingBillToPartyChange ||
  insertionOrder.Status === InsertionOrderStatus.PendingBillToPartyChangeAndUserReview);

/**
 * Returns a copy of the given in object with additional utility methods.
 * @param {object} insertionOrder The insertion order object to be extended with additional utility methods.
 * @returns {object} A copy of the extended insertion order object.
 */
export const extendInsertionOrder = (insertionOrder = {}) => _.defaults({}, insertionOrder, {
  isPendingTakeoverBillingIO: () => isPendingTakeoverBillingIO(insertionOrder),
});

/**
 * Returns an insertion order which matches singleIOForm' format.
 * @param {object} selectedIO The insertion order from IO grid.
 * @param {object} i18n The i18n object.
 * @returns {object} An insertion order which matches singleIOForm' format.
 */
export const convertGridIOToSingleIOFormIO = (selectedIO, i18n) => {
  if (!_.isObject(selectedIO) || !_.isObject(i18n) || !_.isFunction(i18n.parseDate)) {
    return {};
  }

  return ({
    account: { AccountId: selectedIO.AccountId, DisplayAccountName: `${selectedIO.AccountName}(${selectedIO.AccountNumber})` },
    defaultIO: {
      ...selectedIO,
      FriendlyName: _.isString(selectedIO[InsertionOrdersGridFieldNames.InsertionOrderName]) ? selectedIO[InsertionOrdersGridFieldNames.InsertionOrderName].trim() : null,
      FromDate: selectedIO[InsertionOrdersGridFieldNames.StartDateText] && i18n.parseDate(selectedIO[InsertionOrdersGridFieldNames.StartDateText]),
      ThruDate: selectedIO[InsertionOrdersGridFieldNames.EndDateText] && i18n.parseDate(selectedIO[InsertionOrdersGridFieldNames.EndDateText]),
      Status: selectedIO[InsertionOrdersGridFieldNames.StatusValue],
    },
    mtIOEditableMap: {
      FriendlyName: selectedIO[InsertionOrdersGridFieldNames.IsInsertionOrderNameEditable],
      FromDate: selectedIO[InsertionOrdersGridFieldNames.IsStartDateEditable],
      ThruDate: selectedIO[InsertionOrdersGridFieldNames.IsEndDateEditable],
      SpendCapAmount: selectedIO[InsertionOrdersGridFieldNames.IsSpendCapAmountEditable],
      AgencyIdOrPo: selectedIO[InsertionOrdersGridFieldNames.IsAgencyIdOrPoEditable],
      DealPointId: selectedIO[InsertionOrdersGridFieldNames.IsDealPointIdEditable],
      RecordCenterId: selectedIO[InsertionOrdersGridFieldNames.IsRecordCenterIdEditable],
      Comment: selectedIO[InsertionOrdersGridFieldNames.IsCommentEditable],
    },
  });
};

/**
 * Returns an insertion order whether is valid.
 * @param {object} ioValidationFields The insertion order validation result object from IO grid.
 * @param {object} isIOItemNeedValidation The object of which IO items should be verified.
 * @returns {bool} Whether is a valid IO.
 */
export const isIOValid = ({ ioValidationFields, isIOItemNeedValidation }) =>
// when isIOItemNeedValidation[key] === false, it means we don't need this io item
// and it does not need validation
  _.every(isIOItemNeedValidation, (value, key) => !value || (ioValidationFields && ioValidationFields[key]));


/**
 * Returns insertion orders whether are all valid.
 * @param {object} insertionOrders The insertion orders.
 * @param {object} isIOItemNeedValidation The object of which IO items should be verified.
 * @returns {bool} Whether are valid IOs.
 */
export const isAllIOsValid = ({ insertionOrders, isIOItemNeedValidation }) =>
  _.every(insertionOrders, insertionOrder =>
    isIOValid({ ioValidationFields: insertionOrder.ioValidationFields, isIOItemNeedValidation }));

/**
 * Returns insertion orders budget whether is unlimited.
 * @param {number} spendCapAmountValue The insertion orders budget.
 * @returns {bool} Insertion orders budget whether is unlimited.
 */
export const isUnlimitedIOBudget = spendCapAmountValue => spendCapAmountValue === UnlimitedBudget;

/**
 * Returns insertion orders endDate whether is endless.
 * @param {object} endDateValue The insertion orders endDate.
 * @returns {bool} Whether insertion orders end date  is endless.
 */
export const isEndlessIOEndDate = endDateValue => isDateWithoutTimeEqual(endDateValue, EndlessDate);

/**
 * Returns whether insertion orders startDate is immediate.
 * @param {object} startDateValue The insertion orders startDate.
 * @returns {bool} Whether insertion orders start date is immediate.
 */
export const isImmediateStartDate = startDateValue => isDateWithoutTimeEqual(startDateValue, ImmediateDate);

/**
 * Returns insertion orders budget number.
 * @param {string} spendCapAmountValue The insertion orders budget string or budget number.
 * @param {object} i18n The i18n object.
 * @returns {number} Insertion orders budget number.
 */
export const getSpendCapAmountNumber = ({ spendCapAmountValue, i18n }) => {
  if (_.isString(spendCapAmountValue)) {
    return i18n.parseDecimal(spendCapAmountValue);
  } else if (_.isNumber(spendCapAmountValue)) {
    return spendCapAmountValue;
  }
  return null;
};

/**
 * Returns insertion orders budget string in grid.
 * @param {number} spendCapAmountValue The insertion orders budget.
 * @param {object} i18n The i18n object.
 * @returns {bool} Insertion orders budget string.
 */
export const formatUnlimitedIOBudgetInGrid = ({ spendCapAmountValue, i18n, unlimitedStringKey = 'InsertionOrder_Unlimited' }) => {
  const spendCapAmountNumber = getSpendCapAmountNumber({ spendCapAmountValue, i18n });

  if (isUnlimitedIOBudget(spendCapAmountNumber)) {
    return i18n.getString(unlimitedStringKey);
  }
  return _.isNull(spendCapAmountNumber) ? null : i18n.formatDecimalToFixed(spendCapAmountNumber);
};

/**
 * Returns insertion orders endDate string in grid.
 * @param {object} endDateValue The insertion orders endDate.
 * @param {object} i18n The i18n object.
 * @returns {bool} Insertion orders endDate string.
 */
export const formatEndlessIOEndDateInGrid = ({ endDateValue, i18n, noEndDateStringKey = 'InsertionOrder_NoEndDate' }) => {
  if (isEndlessIOEndDate(endDateValue)) {
    return i18n.getString(noEndDateStringKey);
  }
  return _.isDate(endDateValue) ? i18n.formatDate(endDateValue) : null;
};

/**
 * Returns insertion orders endDate string in grid.
 * @param {object} i18n The i18n  object.
 * @param {object} iolabel The insertion orders alert label.
 * * @param {object} column The insertion orders column name.
 * @returns {object} Insertion orders alert status.
 */
export const getIOAlertInGrid = ({ i18n, column, iolabel }) => {
  const alert = {
    EndDate: {
      [InsertionOrderLabel.Expiring]: {
        alertStatus: i18n.getString('InsertionOrder_Alert_Expiring'),
        alertColor: 'status-warning',
        alertIcon: 'Warning',
      },
      [InsertionOrderLabel.Expired]: {
        alertStatus: i18n.getString('InsertionOrder_Alert_Expired'),
        alertColor: 'status-error',
        alertIcon: 'Blocked2',
      },
    },
    BalanceAmount: {
      [InsertionOrderLabel.Exhausting]: {
        alertStatus: i18n.getString('InsertionOrder_Alert_Exhausting'),
        alertColor: 'status-warning',
        alertIcon: 'Warning',
      },
      [InsertionOrderLabel.NoBudget]: {
        alertStatus: i18n.getString('InsertionOrder_Alert_NoBudget'),
        alertColor: 'status-error',
        alertIcon: 'Blocked2',
      },
    },
  };
  return ((column === InsertionOrdersGridFieldNames.EndDate || column === InsertionOrdersGridFieldNames.BalanceAmount) && iolabel) ? alert[column][iolabel] : undefined;
};

/**
 * parse decimal from number or string or others.
 * @param {object} i18n The i18n  object.
 * @param {object} value The value to parse.
 * @returns {number} a number parsed from value.
 */
export function getDecimal({ i18n, value }) {
  if (_.isNumber(value)) {
    return value;
  }
  if (_.isString(value)) {
    return i18n.parseDecimal(value);
  }
  return NaN;
}
