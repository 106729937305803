import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

import { InstrumentationContext } from './context';

/**
 * HoC for injecting instrumentation into a React component.
 * It assumes that InstrumentationContext.Provider has been initialized
 * somewhere up the component hierarchy.
 * @param {React.Component} WrappedComponent - component to wrap
 * @returns {React.Component} an enhanced version of the argument
 */
export const withInstrumentation = (WrappedComponent) => {
  const HoC = props => (
    <InstrumentationContext.Consumer>
      {instrumentation => <WrappedComponent {...props} instrumentation={instrumentation} />}
    </InstrumentationContext.Consumer>
  );
  HoC.displayName = `Instrumented(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(HoC, WrappedComponent);

  return HoC;
};
