import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withActivityContext } from '@bingads-webui-react/hoc-with-signal';

const RenderLoggerInternal = ({
  title,
  activity,
  children,
}) => {
  useEffect(() => {
    if (activity) {
      activity.signal(`RenderLogger - ${title} has been rendered.`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <> { children }</>;
};
RenderLoggerInternal.propTypes = {
  title: PropTypes.string.isRequired,
  activity: PropTypes.shape({
    signal: PropTypes.func,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

RenderLoggerInternal.defaultProps = {
  activity: null,
};

export const RenderLogger = withActivityContext(RenderLoggerInternal);
