export const CbtReportTimePeriod = Object.freeze({
  Today: 0,
  Yesterday: 1,
});

export const CbtReportTimePeriodTLString = Object.freeze({
  [CbtReportTimePeriod.Today]: _TL_('Today'),
  [CbtReportTimePeriod.Yesterday]: _TL_('Yesterday'),
});

export const BudgetRecommendationItemType = Object.freeze({
  Suggested: 0,
  MaxValue: 1,
  Current: 2,
  Other: 3,
});

export const BudgetEventType = Object.freeze({
  // campaign level
  CampaignOutOfBudget: 50,
  CampaignLimitedByBudget: 1001,
  // prepay account level
  PrepayAccountBalanceLow: 69,
  PrepayAccountBalanceZero: 70,
  // monthly invoice account level
  InsertionOrderBalanceDepleted: 119,
  InsertionOrderExpired: 125,
  InsertionOrderExhaustingUrgent: 354,
  InsertionOrderExpiringUrgent: 355,
  // threshold account level
  AccountHold: 7,
});

export const BudgetChangeType = Object.freeze({
  PrepayBalanceRecharge: 1,
  ThresholdPaymentChange: 2,
  InsertionOrderChange: 3,
  CampaignDailyBudgetChange: 4,
});
