export const CountryCode = Object.freeze({
  US: 'US',
  UA: 'UA',
  AM: 'AM',
  AN: 'AN',
  AZ: 'AZ',
  BR: 'BR',
  CN: 'CN',
  IN: 'IN',
  IQ: 'IQ',
  KZ: 'KZ',
  KG: 'KG',
  MM: 'MM',
  MV: 'MV',
  FR: 'FR',
  HK: 'HK',
  TW: 'TW',
  HU: 'HU',
  PT: 'PT',
  PL: 'PL',
  CO: 'CO',
  CL: 'CL',
  AE: 'AE',
  IL: 'IL',
  VN: 'VN',
  VE: 'VE',
  NO: 'NO',
  TR: 'TR',
  FK: 'FK',
  VC: 'VC',
  GE: 'GE',
  TH: 'TH',
  TJ: 'TJ',
  BD: 'BD',
  BT: 'BT',
  ID: 'ID',
  BY: 'BY',
  NG: 'NG',
  IS: 'IS',
  SS: 'SS',
  MD: 'MD',
  ZW: 'ZW',
  ZA: 'ZA',
  BH: 'BH',
  KH: 'KH',
  KE: 'KE',
  SA: 'SA',
  OM: 'OM',
  GH: 'GH',
  CM: 'CM',
  UZ: 'UZ',
  FJ: 'FJ',
  GT: 'GT',
  BS: 'BS',
  PH: 'PH',
  PK: 'PK',
  RU: 'RU',
  LK: 'LK',
  MX: 'MX',
  NP: 'NP',
  LA: 'LA',
  BB: 'BB',
  NZ: 'NZ',
  RS: 'RS',
  SG: 'SG',
  KR: 'KR',
  AR: 'AR',
  BJ: 'BJ',
  CI: 'CI',
  JP: 'JP',
  MY: 'MY',
  SR: 'SR',
  ZM: 'ZM',
  EG: 'EG',
  PE: 'PE',
  UY: 'UY',
  PA: 'PA',
});

export const CallingCode = Object.freeze({
  US: '1',
});

export const CallingCodeBeforePhoneNumberRegExp = '^([0-9]+)-(.*)$';

// This should be in sync with excluded countries in AdsAppUI/private/UI/Common/Microsoft.ClientCenter.Repository/Dictionaries/CountryMapping.cs GetVatApplicableCountriesMapping
export const NonEMEAVatCountries = [CountryCode.NZ, CountryCode.RS, CountryCode.SG];

export const YahooMarkets = [CountryCode.HK, CountryCode.TW];

export const CustomerUnsupportedBusinessLocations = [CountryCode.AN, CountryCode.FK, CountryCode.VC];

export const UnsupportedBusinessLocations = [CountryCode.RU, CountryCode.BY];
