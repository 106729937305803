export const DocumentType = Object.freeze({
  CurrentActivity: 'CurrentActivity',
  PrePaidInvoices: 'PrePaidInvoices',
  PrePaidStatement: 'PrePaidStatement',
  SelfServeInvoice: 'SelfServeInvoice',
  Invoice: 'Invoice',
  XandrInvoice: 'XandrInvoice',
  Pending: 'pending', // small case from MT, but i18n string still camelCase
  Invoices: 'Invoices',
  CreditMemoInvoice: 'CreditMemoInvoice',
  PostPaidStatement: 'PostPaidStatement',
  PrepaidCreditMemoInvoice: 'PrepaidCreditMemoInvoice',
  PrepaidChargebackInvoice: 'PrepaidChargebackInvoice',
  PrepaidRefundReversalInvoice: 'PrepaidRefundReversalInvoice',
  WriteOffCreditMemo: 'WriteOffCreditMemo',
  MonthlyStatement: 'MonthlyStatement',
});

export const CreditMemoDocumentType = [
  DocumentType.CreditMemoInvoice,
  DocumentType.PrepaidCreditMemoInvoice,
  DocumentType.PrepaidChargebackInvoice,
  DocumentType.WriteOffCreditMemo,
];

export const BillingDocumentRenderingMethod = Object.freeze({
  Account: 0,
  Billing: 1,
  Adjustment: 2,
});

export const IndiaEInvoicingStatus = Object.freeze({
  NotStarted: 0,
  Available: 1,
  Failed: 2,
  PendingSupport: 3,
  PendingIRNGeneration: 4,
  PendingCustomerAction: 5,
  PendingCreditAndRebill: 7,
});

export const ApContactType = Object.freeze({
  SAPLevel: 0,
  AccountLevel: 1,
});

export const APContactStatus = Object.freeze({
  PendingUserValidation: 0,
  Active: 1,
  Deleted: 2,
  Inactive: 3,
  PendingSystemValidation: 4,
  SystemValidationFailure: 5,
  Unknown: 6,
});

export const APContactMode = Object.freeze({
  Static: 'static',
  Edit: 'edit',
  Create: 'create',
});

export const IndiaDualInvoiceType = Object.freeze({
  Default: 0,
  RecipientCopy: 1,
  SupplierCopy: 2,
});

export const PaymodErrorCodes = Object.freeze({
  AuthorizationExpired: 'authorization_expired',
  ProcessorDeclined: 'processor_declined',
  ExpiredPaymentInstrument: 'expired_payment_instrument',
  ProcessorRiskcheckDeclined: 'processor_riskcheck_declined',
  AmountLimitExceeded: 'amount_limit_exceeded',
  InvalidPaymentInstrument: 'invalid_payment_instrument',
  InsufficientFund: 'insufficient_fund',
  MissingFundingSource: 'missing_funding_source',
  IncorrectPinOrPasscode: 'incorrect_pin_or_passcode',
  TransactionNotAllowed: 'transaction_not_allowed',
  MissingSessionData: 'missing_session_data',
  InvalidTransactionData: 'invalid_transaction_data',
  InvalidTransactionDataPrepay: 'invalid_transaction_data_12',
  InvalidTransactionDataThreshold: 'invalid_transaction_data_1',
  RiskReject: 'risk_evaluation_declined',
  AuthRequired: 'authentication_required',
  CVVValueMismatch: 'cvv_value_mismatch',
});

export const DefaultMinBillingThreshold = 50;

export const AccountBillMeNowStatus = Object.freeze({
  None: 0,
  ForceBill: 1,
  Refund: 2,
});

export const IndiaEMandateOfficialUrl = 'https://www.rbi.org.in/Scripts/BS_PressReleaseDisplay.aspx?prid=51353';

/**
 * Available Action ids for adjustments
 */
export const AdjustmentsAction = Object.freeze({
  None: 0,
  Debit: 1,
  Credit: 2,
  SpotDebit: 3,
  SpotCredit: 4,
  CurrentBalanceDebit: 53,
  CurrentBalanceCredit: 54,
});

/**
 * Billing Document action
 */
export const BillingDocumentAction = Object.freeze({
  Credit: 0,
  Debit: 1,
  Rebill: 2,
});
