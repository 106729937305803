import { Glyphicon } from '@bingads-webui-react/primitive';
import React from 'react';
import classNames from 'classnames';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { submitEvent } from '@bingads-webui-universal/accessibility-helper';

import { INotification, NotificationLevel } from '../types';
import { styles } from './styles';

interface IFloatingBannerWrapperProps {
  notification: INotification,
  messageClassName: string,
}

// We have this Wrapper because currently in CMUI we might shown fallback callout for some link
// Once there's no need to do that, we could move this part into UnstyledFloatingBanner
class DefaultWrapper extends React.PureComponent<IFloatingBannerWrapperProps> {
  render() {
    return (
      React.isValidElement(this.props.notification.message)
        ? (
          <span
            className={this.props.messageClassName}
            role="alert"
          >
            {this.props.notification.message}
          </span>
        )
        : (
          <span
            className={this.props.messageClassName}
            dangerouslySetInnerHTML={{ __html: this.props.notification.message }}
            role="alert"
          />
        )
    );
  }
}

interface IFloatingBannerProps {
  notification: INotification;
  i18n: any,
  dismiss: () => void;
  classes: any;
  Wrapper?: React.ComponentClass<any>;
}

class UnstyledFloatingBannerBar extends React.PureComponent<IFloatingBannerProps> {
  static defaultProps = {
    Wrapper: DefaultWrapper,
  };

  render() {
    const {
      classes, notification, i18n, dismiss, Wrapper,
    } = this.props;
    const wrappedMsg = <Wrapper notification={notification} messageClassName={classes.text} i18n={i18n} />;

    const ExitIcon = (
      <Glyphicon
        className={classNames('exitIcon', classes.exitIcon)}
        glyph="ba-Cancel"
        onClick={() => dismiss()}
        role="button"
        tabIndex={0}
        aria-label={i18n.getString(_TL_('Dismiss the message'))}
        onKeyDown={submitEvent(dismiss)}
      />
    );

    if (notification.severity === NotificationLevel.Info) {
      return (
        <div className={classNames('floating-banner', 'infoFloatingBanner', classes.infoFloatingBanner)}>
          <Glyphicon className={classes.infoIcon} glyph="ba-Completed" />
          {wrappedMsg}
          {ExitIcon}
        </div>
      );
    } if (notification.severity === NotificationLevel.Warning) {
      return (
        <div className={classNames('floating-banner', 'warnFloatingBanner', classes.warnFloatingBanner)}>
          <Glyphicon className={classes.warnIcon} glyph="ba-Warning" />
          {wrappedMsg}
          {ExitIcon}
        </div>
      );
    }
    return null;
  }
}

export const FloatingBannerBar = withDefaultStyles(UnstyledFloatingBannerBar, styles);
