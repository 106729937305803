import React from 'react';
import PropTypes from 'prop-types';

/**
 * React error boundary enclosing the entire application.
 * Smaller components in the app should have their own error boundaries as appropriate.
 * Any uncaught rendering/React lifecycle errors will be handled here.
 * https://reactjs.org/docs/error-boundaries.html
 * @extends React.Component
 */
export class GlobalErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    if (this.props.logError) {
      this.props.logError({ error, info });
    }
    this.setState({ hasError: true, error, info });
  }

  render() {
    // Set renderErrorView to null to swallow the error instead of crashing the page
    if (this.state.hasError && this.props.renderErrorView) {
      return this.props.renderErrorView({
        error: this.state.error,
        info: this.state.info,
        errorViewProps: this.props.errorViewProps,
      });
    }
    return (
      <React.Fragment>
        {this.props.children}
        <div className="global-error-boundary" style={{ display: 'none' }} />
      </React.Fragment>);
  }
}

GlobalErrorBoundary.propTypes = {
  /**
   * The enclosed application.
   */
  children: PropTypes.node.isRequired,
  /**
   * Function for logging the error that occurred.
   * @param {Object} obj - Object based on the arguments provided to React componentDidCatch
   * @param {Object} obj.error - Error object from React, which includes an error stack trace.
   * @param {Object} obj.info - Info object from React, which includes a componentStack trace.
   */
  logError: PropTypes.func,
  /**
   * Function for generating an error page view.
   * @param {Object} obj - Object based on the arguments provided to React componentDidCatch
   * @param {Object} obj.error - Error object from React, which includes an error stack trace.
   * @param {Object} obj.info - Info object from React, which includes a componentStack trace.
   * @param {Object} obj.errorViewProps - The props used by the error view to be rendered.
   * @returns - React node for the error view.
   */
  renderErrorView: PropTypes.func,

  /**
   * Objects of the props will be passed into the renderErrorView.
   */
  errorViewProps: PropTypes.instanceOf(Object),
};

GlobalErrorBoundary.defaultProps = {
  logError: null,
  renderErrorView: null,
  errorViewProps: null,
};
