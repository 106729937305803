import _ from 'underscore';

export const errorPropertyReg = /((.+)\[(\d+)\]){0,1}(\.)?(.+)?/;

export const getParsedIndexAndProperty = (errorProperty) => {
  if (!errorProperty) {
    return {
      index: null,
      property: null,
    };
  }
  const res = errorPropertyReg.exec(errorProperty);
  const property = !res[5] && res[2] ? res[2] : res[5];
  return {
    index: res[3],
    property,
  };
};

export const odataErrorHandler = (
  rawError, entity, field,
  getErrorMessage, useServerErrorMessage
) => {
  let errors = [];
  if (_.isArray(rawError)) {
    errors = rawError;
  } else if (_.isObject(rawError)) {
    if (rawError['@odata.context'] && _.isArray(rawError.value)) {
      errors = rawError.value;
    } else if (_.isArray(rawError.responseJSON)) {
      errors = rawError.responseJSON;
    } else if (_.isObject(rawError.responseJSON) && _.isArray(rawError.responseJSON.Errors)) {
      errors = rawError.responseJSON.Errors;
    }
  }
  const entityErrors = {};
  errors.forEach((e) => {
    const { property: errProperty } = getParsedIndexAndProperty(e.Property);
    const errField = field || errProperty || ''; // entity level errors are stored on param ''

    const error = {
      errorCode: e.Code,
      message: getErrorMessage({
        errorCode: e.Code,
        entity,
        field: field || errProperty,
        serverErrorMessage: useServerErrorMessage && e.Message,
        errMsgObj: e.ErrMsgObj,
      }),
      clientId: e.ClientId,
    };
    if (entityErrors[errField]) {
      entityErrors[errField].push(error);
    } else {
      entityErrors[errField] = [error];
    }
  });
  return entityErrors;
};

export const ccErrorHandler = (rawError, entity, field, getErrorMessage) => {
  const defaultErrorCode = `ResultType${rawError.ResultType}`;
  if (_.isEmpty(rawError.Errors)) {
    return {
      [field || '']: [{
        errorCode: defaultErrorCode,
        message: getErrorMessage({
          errorCode: defaultErrorCode,
          entity,
          field,
        }),
        clientId: rawError.ClientId,
      }],
    };
  }

  const entityErrors = {};
  rawError.Errors.forEach((e) => {
    const errorCode = e.Code || defaultErrorCode;
    const errField = field || e.FieldId || '';
    const error = {
      errorCode,
      message: getErrorMessage({ errorCode, entity, field: field || e.FieldId }),
      clientId: e.ClientId,
    };
    if (entityErrors[errField]) {
      entityErrors[errField].push(error);
    } else {
      entityErrors[errField] = [error];
    }
  });

  return entityErrors;
};
