import { AttestationValue, GroupingCriteria } from './constants';

export const normalizeIncludeCampaignDetailsValue = (value) => {
  switch (value) {
    case true:
      return AttestationValue.True;
    case false:
      return AttestationValue.False;
    default:
      return AttestationValue.NotSet;
  }
};

export const denormalizeIncludeCampaignDetailsValue = (value) => {
  switch (value) {
    case AttestationValue.True:
      return true;
    case AttestationValue.False:
      return false;
    default:
      return false;
  }
};

export const MTToUIBillingGroups = billingGroups => billingGroups.map(b => ({
  billingGroupId: b.id,
  billtoCustomerId: b.BilltoCustomerId,
  name: b.Name,
  description: b.Description,
  sapId: b.SapId,
  address: b.Address,
  taxInformation: b.TaxInformation,
  currencyCode: b.CurrencyCode,
  languageId: b.LanguageId,
  companyCode: b.CompanyCode,
  includeCampaignDetails: normalizeIncludeCampaignDetailsValue(b.IncludeCampaignDetails),
  accounts: b.Accounts || [],
}));

export const MTToUIUngroupedAccounts = ungroupedAccounts => ungroupedAccounts.map(a => ({
  accountName: a.Name,
  accountId: a.id,
  accountNumber: a.Number,
  potentialBillingGroups: a.PotentialBillingGroups ? a.PotentialBillingGroups.map(g => ({ id: g.Key, name: g.Value })) : [],
}));

export const UIToMTBillingGroup = billingGroupInfo => ({
  id: billingGroupInfo.billingGroupId,
  BilltoCustomerId: billingGroupInfo.billtoCustomerId,
  Name: billingGroupInfo.name,
  Description: billingGroupInfo.description,
  SapId: billingGroupInfo.sapId,
  Address: billingGroupInfo.address,
  TaxInformation: billingGroupInfo.taxInformation,
  CurrencyCode: billingGroupInfo.currencyCode,
  LanguageId: billingGroupInfo.languageId,
  CompanyCode: billingGroupInfo.companyCode,
  IncludeCampaignDetails: denormalizeIncludeCampaignDetailsValue(billingGroupInfo.includeCampaignDetails),
  Accounts: billingGroupInfo.accounts,
});

export const getGroupingCriteria = ({
  billtoSapId, currencyType, billingGroupId, companyCode, businessAddress, taxInformation,
}, isInWhiteList) => {
  const sharedGroupingCriteria = {
    [GroupingCriteria.BilltoSAPId]: billtoSapId,
    [GroupingCriteria.CurrencyType]: currencyType,
    [GroupingCriteria.BillingGroupId]: billingGroupId,
  };
  const whiteListGroupingCriteria = {
    [GroupingCriteria.CompanyCode]: companyCode,
  };
  const nonWhiteListGroupingCriteria = {
    [GroupingCriteria.BusinessAddress]: businessAddress,
    [GroupingCriteria.TaxInformation]: taxInformation,
  };
  return {
    ...sharedGroupingCriteria,
    ...(isInWhiteList ? whiteListGroupingCriteria : nonWhiteListGroupingCriteria),
  };
};
