import { Validation } from '@bingads-webui/validation';
import { BaseFactory } from './base-factory';

/**
 * Factory for enum validation rules
 * @param {Object} options - input for schema property bag and i18n
 */
export class EnumFactory extends BaseFactory {
  /**
   * Create enum rules set from schema property
   * @returns {Object} - enum validation rules set
   */
  create() {
    return {
      enum: Validation.enum(this.prop.enum, this.i18n),
    };
  }
}
