/**
 * Base factory for validation rules
 * @param {Object} options - input for schema property bag and i18n
 */
export class BaseFactory {
  constructor(options) {
    this.prop = options.prop;
    this.i18n = options.i18n;
  }

  /**
   * Base method to create rules set
   * @returns {Object} - empty rules set
   */
  create() {
    return {};
  }
}
