import _ from 'underscore';
import URI from 'urijs';

export function IsFraudAppealPage({ urls }) {
  if (_.isFunction(urls && urls.current) && _.isFunction(urls && urls.fraudAppeal)) {
    const currentUrl = URI(urls.current());
    const fraudAppealUrl = URI(urls.fraudAppeal());
    return currentUrl.path() === fraudAppealUrl.path();
  }
  return false;
}

export function IsFraudAppealAIVPage({ urls }) {
  if (_.isFunction(urls && urls.current) && _.isFunction(urls && urls.fraudAppealAIV)) {
    const currentUrl = URI(urls.current());
    const fraudAppealAIVUrl = URI(urls.fraudAppealAIV());
    return currentUrl.path() === fraudAppealAIVUrl.path();
  }
  return false;
}
