export const CouponType = Object.freeze({
  Unspecified: 0,
  Acquisition: 1,
  Retention: 2,
  Performance: 3,
});

/**
 * Coupon Status
 * AssociatedCoupon - For UI usage only, this performance coupon is associated to the account, but not redeemed
 * PendingUpfrontSpending - For UI usage only, this performance coupon is in upfront spending status, and currently there is an active coupon, so this pbc should be in "Pending UpfrontSpending"
 * UpfrontSpending - For performance coupon only, this coupon is in upfront spending status, will be active afer meet upfront spending threshold
 * ActiveCoupon - This coupon is active, and for each account there should be only 1 active coupon at a time.
 * Completed - This coupon is completed
 * Expired - This coupon is expired
 * Pending - This coupon is in pending (paused) status. Pending can be switched to Active and vice versa
 */
export const CouponStatus = Object.freeze({
  AssociatedCoupon: 247,
  PendingUpfrontSpending: 248,
  UpfrontSpending: 249,
  ActiveCoupon: 250,
  Completed: 251,
  Expired: 252,
  Pending: 253,
});

/**
 * Feature Adoption Coupon Status
 * NotInProgess - Adoption of this feature has not started, but the account is qualified
 * Active - Adoption of this feature has started, but has not been completed
 * Adopted - Adoption has been completed from UI, but the coupon hasn't been redeemed yet
 * PickedUp - Intermediary step of the redemption process. Coupon should be redeemed momentarily
 * Redeemed - The coupon has been redeemed
 * DismissedBeforeStart - The user dismissed the coupon before they started the adoption process
 * DismissedAfterStart - The user dismissed the coupon after they started the adoption process
 * AdoptedAfterExpiration - The adoption has been completed from UI, but expiration date expired before it was done
 * FailedToRedeem - This status will be set if there are issues during the redemption flow on the CCMT side
 * Depleted - There are no longer enough coupons to give out
 * FailedAdoption - Feature Crew has deemed the adoption invalid
 */
export const RecommendationAdoptionStatus = Object.freeze({
  NotInProgess: 0,
  Active: 1,
  Adopted: 2,
  PickedUp: 3,
  Redeemed: 4,
  DismissedBeforeStart: 5,
  DismissedAfterStart: 6,
  AdoptedAfterExpiration: 7,
  FailedToRedeem: 8,
  Depleted: 9,
  FailedAdoption: 10,
});

/**
 * Feature Adoption Coupon Feature Id
 * Google Import - 206
 * FacebookImport - 207
 * ConversionTracking - 208
 * CalloutCard - 209
 */
export const FeatureAdoptionCouponFeatureId = Object.freeze({
  GoogleImport: 206,
  FacebookImport: 207,
  ConversionTracking: 208,
  CalloutCard: 209,
  AutoBidMaxClicks: 212,
  AutoBidMaxConversions: 213,
  ShoppingCampaign: 214,
  PerformanceMax: 215,
});

export const CouponPurposeId = Object.freeze({
  Signup: 0,
  Ongoing: 1, // Deprecated
  Multipurpose: 2,
  FeatureAdoption: 3,
  BingOnlyCampaign: 4,
  SyndicationFiltering: 5,
  MultiChannels: 6,
  MSANSignUp: 7,
  StoreAds: 8,
  RetentionStoreAds: 9,
});
