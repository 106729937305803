(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("underscore"), require("@bingads-webui/validation"));
	else if(typeof define === 'function' && define.amd)
		define(["underscore", "@bingads-webui/validation"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("underscore"), require("@bingads-webui/validation")) : factory(root["underscore"], root["@bingads-webui/validation"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__32__) {
return 