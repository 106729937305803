import _ from 'underscore';

export const LinkType = Object.freeze({
  AdAccount: 1,
  ManagerAccount: 2,
  Reparenting: 3,
});

export const LinkPermissionType = Object.freeze({
  Administrative: 1,
  Standard: 2,
});

export const LinkRequestType = Object.freeze({
  Link: 1,
  Unlink: 2,
  BillingTakeover: 3,
  AccountTransfer: 4,
});


export const HierarchyAccountTypeValue = Object.freeze({
  Unknown: 1,
  AdAccount: 2,
  ManagerAccount: 3,
});

export const HierarchyAccountType = Object.freeze(_.invert(HierarchyAccountTypeValue));
