import _ from 'underscore';

/**
 * Get language name property
 * @param {object} languageValue Value object of language
 * @param {number} languageValue.Code Code of language
 * @param {string} languageValue.Name Name of language
 * @returns {object} language name, and empty string if name is not found
 */
export const getLanguageName = languageValue => _.result(languageValue, 'Name', '');

/**
 * Get extendLanguage object
 * @param {object} languageValue Value object of language
 * @param {number} languageValue.Code Code of language
 * @param {string} languageValue.Name Name of language
 * @returns {object} extendLanguage object
 */
export const extendLanguage = (languageValue = {}) => _.defaults({}, languageValue, {
  name: getLanguageName(languageValue),
});

/**
 * Get language value object by code
 * @param {number} code Code of language
 * @param {bool} getExtendedObject It indicates if this function will return extended language value object
 * @param {object[]} languages List of languages
 * @param {number} languages[].code Code of language
 * @param {object} languages[].value Value object of language
 * @param {number} languages[].value.Code Code of language
 * @param {string} languages[].value.Name Name of language
 * @returns {object} Language value object, and null or default extendLanguage object if language code is not found
 */
export const findLanguage = ({ languages, code, getExtendedObject = false }) => {
  const language = _.findWhere(languages, { code });
  const languageValue = _.result(language, 'value', null);
  return getExtendedObject ? extendLanguage(languageValue) : languageValue;
};
