import _ from 'underscore';
import { BaseFactory } from './convertor-factory/base-factory';
import { NumberFactory } from './convertor-factory/number-factory';

/**
 * Find conversion factory by field type
 * @param {Object} prop - Schema field properties
 * @param {I18n} i18n - i18n for localization
 * @returns {Object} - returns factory for conversion object
 */
function getConvertorFactoryByType(prop, i18n) {
  switch (prop.type) {
    case 'integer':
      return new NumberFactory({ prop: _.defaults({ decimalLength: 0 }, prop), i18n });
    case 'number':
      return new NumberFactory({ prop: _.defaults({ decimalLength: 2 }, prop), i18n });
    default:
      return new BaseFactory({ prop, i18n });
  }
}

/**
 * Create conversion object by field type
 * @param {Object} prop - Schema field properties
 * @param {I18n} i18n - i18n for localization
 * @returns {Object} - returns conversion object
 */
export function createConversion(prop, i18n) {
  const factory = getConvertorFactoryByType(prop, i18n);

  return factory.create();
}
