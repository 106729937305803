import _ from 'underscore';
import { NumberUtils } from '@bingads-webui-clientcenter/common-utils';

import { CustomerProfilePropertyType, AppScope, ManagedByType, CustomerServiceLevel } from './constants/customer';
import { HierarchyAccountTypeValue } from './constants/linking';
import { TradeScreeningStatus } from './constants/trade-screening-transformation';
import { isStringNullOrWhiteSpace } from './common';

const { hasFlag } = NumberUtils;

/**
 * @typedef {Object} Customer
 * @property {string|number} [ServiceLevel]
 */

/**
 * Returns the display string of an customer in the format CustomerName (CustomerNumber)
 * @param {object} customer the customer object
 * @param {*} i18n the i18n object
 * @returns {string} the display string of customer
 */
export const getDisplayName = (customer, i18n) => {
  if (!_.isObject(customer)) {
    return '';
  }
  const name = customer.name || '';
  return customer.number ? i18n.getString('Customer_DisplayName', { name, number: customer.number }) : name;
};

export const isAggregatee = customer => !!customer.AggregatorName;

/**
 * Returns the hierarchy format of an customer
 * @param {object} customer the customer object
 * @returns {string} the hierarchy format of an customer
 */
export const getHierarchyCustomer = (customer = {}) => ({
  id: customer.id,
  name: customer.Name,
  number: customer.Number,
  managers: _.map(customer.DirectManagers, (manager = {}) => ({
    name: manager.RelatedToCustomerName,
    number: manager.RelatedToCustomerNumber,
    id: manager.RelatedToCustomerId,
  })),
  type: HierarchyAccountTypeValue.ManagerAccount,
});

/**
 * CustomerProfileModel holds a list profile properties for a customer.
 * @typedef {object} CustomerProfileModel
 * @property {number} [numberOfBillToInvoiceAccounts]
 * @property {number} [numberOfBillToPrepayAccounts]
 * @property {number} [numberOfBillToThresholdAccounts]
 * @property {bool} [isOptedInConsolidatedBilling]
 * @property {bool} [isOptedInCampaignDetails]
 * @property {number} [totalNumberOfBillToAccounts]
 */

const getCustomerProfileValue = (customerProfile, customerProfilePropertyType) => {
  const profile = _.findWhere(customerProfile.CustomerProfilePropertyList, { CustomerProfilePropertyType: customerProfilePropertyType });
  const value = profile && profile.Value;
  return parseInt(value, 10) || 0;
};

/**
 * Parse a customerProfile's CustomerProfilePropertyList array into a readable CustomerProfileModel's properties
 * @param {object} customerProfile the customerProfile object
 * @returns {CustomerProfileModel} the model of a customer Profile
 */
export const parseCustomerProfile = (customerProfile) => {
  if (!_.isObject(customerProfile) || !_.isArray(customerProfile.CustomerProfilePropertyList)) {
    return null;
  }

  const numberOfBillToInvoiceAccounts = getCustomerProfileValue(customerProfile, CustomerProfilePropertyType.NumberOfBillToInvoiceAccountsUnderCustomer);
  const numberOfBillToPrepayAccounts = getCustomerProfileValue(customerProfile, CustomerProfilePropertyType.NumberOfBillToPrepayAccountsUnderCustomer);
  const numberOfBillToThresholdAccounts = getCustomerProfileValue(customerProfile, CustomerProfilePropertyType.NumberOfBillToThresholdAccountsUnderCustomer);
  const isOptedInConsolidatedBilling = getCustomerProfileValue(customerProfile, CustomerProfilePropertyType.IsOptedInForConsolidatedBilling) === 1;
  const isOptedInCampaignDetails = getCustomerProfileValue(customerProfile, CustomerProfilePropertyType.IsOptedInForCampaignDetailsInInvoices) === 1;

  return {
    numberOfBillToInvoiceAccounts,
    numberOfBillToPrepayAccounts,
    numberOfBillToThresholdAccounts,
    isOptedInConsolidatedBilling,
    isOptedInCampaignDetails,
    totalNumberOfBillToAccounts: numberOfBillToInvoiceAccounts + numberOfBillToPrepayAccounts + numberOfBillToThresholdAccounts,
  };
};

/**
 * Parse a customerProfile's CustomerProfilePropertyList array and return the specified property value
 * @param {object} customerProfile the customerProfile object
 * @param {number} propertyType the customerProfilePropertyType enum
 * @returns {number} the customerProfileProperty value
 */
export const parseCustomerProfileSingle = (customerProfile, propertyType) => {
  if (!_.isObject(customerProfile) || !_.isArray(customerProfile.CustomerProfilePropertyList) || _.isEmpty(customerProfile.CustomerProfilePropertyList)) {
    return null;
  }

  return getCustomerProfileValue(customerProfile, propertyType);
};

/**
 * Returns true if the customer in param belongs to the appScope given in params
 * @param {object} customer the customer object
 * @param {*} appScope the Applications the Customer is associated with
 * @returns {boolean} true if the customer in param belongs to the appScope given in params
 */
export const isInAppScope = (customer, appScope) => _.contains(_.values(AppScope), appScope) && hasFlag(customer.AppScope, appScope);

/**
 * Returns true if the customer is managed by Yahoo
 * @function
 * @param {object} customer the customer object
 * @returns {bool} true if the customer is managed by Yahoo
 */
export const isManagedByYahoo = customer => _.result(customer, 'ManagedBy') === ManagedByType.Yahoo;

/**
 * Check if customer is in TST data issue missing name status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST data issue missing name status
 */
export const isTSTDataIssueName = customer =>
  _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueName ||
  _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueOrg;

/**
 * Check if customer is in TST data issue missing address status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST data issue missing address status
 */
export const isTSTDataIssueAddress = customer => _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueAddress;

/**
 * Check if customer is in TST data issue missing legal identifier status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST data issue missing legal identifier status
 */
export const isTSTDataIssueLegalId = customer => _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.DataIssueLegalId;

/**
 * Check if customer is in TST hit-in-review status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST hit-in-review status
 */
export const isTSTInReview = customer => _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.HitInReview;

/**
 * Check if customer is in TST LicR status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST LicR status
 */
export const isTSTLicR = customer => _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.LicR;

/**
 * Check if customer is in TST true-match status
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is in TST true-match status
 */
export const isTSTTrueMatch = customer => _.get(customer, 'TradeScreeningStatus') === TradeScreeningStatus.TrueMatch;

/**
 * Check if customer is missing master data
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is missing data
 */
export const isTSTMissingMasterData = customer => (
  isStringNullOrWhiteSpace(_.get(customer, ['Address', 'Country']))
  || isStringNullOrWhiteSpace(_.get(customer, ['Address', 'City']))
  || isStringNullOrWhiteSpace(_.get(customer, ['Address', 'Line1']))
  || isStringNullOrWhiteSpace(_.get(customer, ['Name'])));

/**
 * Check if customer is unmanaged customer
 * @param {Customer} customer Duck customer type to be extended with additional utility methods.
 * @returns {boolean} Result of whether customer is unmanaged customer
 */
export const isUnmanagedCustomer = ({ ServiceLevel }) => ServiceLevel === CustomerServiceLevel.SelfServe
  || CustomerServiceLevel[ServiceLevel] === CustomerServiceLevel.SelfServe
  || ServiceLevel === CustomerServiceLevel.SelfServeTrusted
  || CustomerServiceLevel[ServiceLevel] === CustomerServiceLevel.SelfServeTrusted;

/**
 * Returns a copy of the given customer object with additional utility methods.
 * @param {object} customer The customer object to be extended with additional utility methods.
 * @param {*} i18n the i18n object
 * @returns {object} A copy of the extended customer object.
 */
export const extendCustomer = (customer = {}, i18n) => _.defaults({}, customer, {
  isAggregatee: () => isAggregatee(customer),
  displayName: () => getDisplayName(customer, i18n),
  hierarchyCustomer: () => getHierarchyCustomer(customer),
  isInAppScope: appScope => isInAppScope(customer, appScope),
  isManagedByYahoo: () => isManagedByYahoo(customer),
  isTSTDataIssueName: () => isTSTDataIssueName(customer),
  isTSTDataIssueAddress: () => isTSTDataIssueAddress(customer),
  isTSTDataIssueLegalId: () => isTSTDataIssueLegalId(customer),
  isTSTInReview: () => isTSTInReview(customer),
  isTSTLicR: () => isTSTLicR(customer),
  isTSTTrueMatch: () => isTSTTrueMatch(customer),
  isTSTMissingMasterData: () => isTSTMissingMasterData(customer),
  isUnmanagedCustomer: () => isUnmanagedCustomer(customer),
});
