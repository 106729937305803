import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEmpty, isString } from 'underscore';
import { withI18nConsumer } from '@bingads-webui-react/i18n-context';
import { withStateStore } from '@bingads-webui/with-state-store';
import { notificationStore } from '../notification-store';
import { INotification } from '../types';
import { FloatingBannerBar } from './floating-banner-bar';

interface IFloatingBannerProps {
  // eslint-disable-next-line react/require-default-props
  floatingBannerNotification?: INotification;
  i18n: any;
  clearNotification: () => void;
  Wrapper: React.ComponentClass<any>,
}

export class FloatingBanner extends React.PureComponent<IFloatingBannerProps> {
  private timeoutID: number = -1;

  private isSessionModeDismissEnabled: boolean = false; // if using session mode dismissal

  private isFloatingBannerAlreadyDismissedBySession: boolean = false; // if current floating banner is already dismissed by session

  componentDidMount() {
    // if there is any floating banner notification and it is not dismissed by session
    if (this.props.floatingBannerNotification && !this.isFloatingBannerAlreadyDismissedBySession) {
      this.startDismiss();
    }
  }

  componentDidUpdate() {
    // if there is any floating banner notification and it is not dismissed by session
    if (this.props.floatingBannerNotification && !this.isFloatingBannerAlreadyDismissedBySession) {
      this.startDismiss();
    }
  }

  // manually dismiss by clicking exit icon
  dismiss = () => {
    if (this.timeoutID >= 0) {
      window.clearTimeout(this.timeoutID);
    }
    this.props.floatingBannerNotification.extraOptions?.onDismiss?.('manual', notificationStore);

    // reset initial status
    this.resetInitialStatus();
  };

  // auto dismiss if any
  startDismiss() {
    if (this.timeoutID >= 0) {
      window.clearTimeout(this.timeoutID);
    }

    const autoDismissTime = this.props.floatingBannerNotification?.extraOptions?.autoDismissTime;
    if (autoDismissTime && autoDismissTime > 0) {
      this.timeoutID = window.setTimeout(() => {
        // reset initial status
        this.props.floatingBannerNotification.extraOptions?.onDismiss?.('auto', notificationStore);
        this.resetInitialStatus();
      }, autoDismissTime);
    }
  }

  // reset initial status
  resetInitialStatus() {
    // dismiss by session mode if session dismiss is enabled
    if (this.isSessionModeDismissEnabled) {
      this.dismissBySessionMode();
    }

    // reset initial values
    this.timeoutID = -1;
    this.isSessionModeDismissEnabled = false;
    this.isFloatingBannerAlreadyDismissedBySession = false;

    // clear notification
    this.props.clearNotification();
  }

  // should show -> true if no notification id in session storage
  // should not show -> false if has notification id in session storage
  shouldShowNotificationUnderSessionModeDismiss() {
    const notificationId = this.props.floatingBannerNotification.extraOptions?.sessionModeDismissForFloatingBannerProps?.uniqueFloatingBannerId;
    return window.sessionStorage.getItem(notificationId) === null;
  }

  // set unique floating banner id to session storage for dismissal
  dismissBySessionMode() {
    const notificationId = this.props.floatingBannerNotification?.extraOptions?.sessionModeDismissForFloatingBannerProps?.uniqueFloatingBannerId;
    window.sessionStorage.setItem(notificationId, 'true');
  }

  render() {
    // return if no notification
    if (!this.props.floatingBannerNotification) {
      return null;
    }

    // if has props for session mode dismissal
    if (!isEmpty(this.props.floatingBannerNotification?.extraOptions?.sessionModeDismissForFloatingBannerProps)) {
      // session mode is enabled if has correct values in props
      this.isSessionModeDismissEnabled = isString(this.props.floatingBannerNotification?.extraOptions?.sessionModeDismissForFloatingBannerProps?.uniqueFloatingBannerId)
        && this.props.floatingBannerNotification?.extraOptions?.sessionModeDismissForFloatingBannerProps?.uniqueFloatingBannerId.length !== 0;

      // if current floating banner is already dismissed
      this.isFloatingBannerAlreadyDismissedBySession = this.isSessionModeDismissEnabled && !this.shouldShowNotificationUnderSessionModeDismiss();

      // clear notification and return if floating banner is already dismissed by session
      if (this.isFloatingBannerAlreadyDismissedBySession) {
        this.props.clearNotification();
        return null;
      }
    }

    return (
      <FloatingBannerBar notification={this.props.floatingBannerNotification} i18n={this.props.i18n} Wrapper={this.props.Wrapper} dismiss={this.dismiss} />
    );
  }
}

// floating banner is always action-triggered, so we use the store in shared repo to avoid creating two containers in app
export const getWrappedFloatingBannerContainer = (Wrapper?: React.ComponentClass<any>) => withStateStore(withI18nConsumer(FloatingBanner), notificationStore, (store: any) => ({
  floatingBannerNotification: store.state.floatingBannerNotification,
  Wrapper,
  clearNotification: () => {
    store.setState({
      floatingBannerNotification: null,
    });
  },
}));

export const WrappedFloatingBannerContainer = getWrappedFloatingBannerContainer();
