import { NotificationLevel, ExtraOptions, INotification } from '../types';
import { notificationStore } from '../notification-store';
import { INotificationApi } from './api.types';

export class NotificationApi implements INotificationApi {
  dismiss(id: string) {
    notificationStore.dismiss(id);
  }

  showWarning(message: string, extraOptions?: ExtraOptions) {
    return notificationStore.setFloatingBannerNotification(message, NotificationLevel.Warning, extraOptions);
  }

  showInfo(message: string, extraOptions?: ExtraOptions) {
    return notificationStore.setFloatingBannerNotification(message, NotificationLevel.Info, extraOptions);
  }

  add(notification: INotification) {
    return notificationStore.add(notification);
  }

  removeAllTopBanners() {
    return notificationStore.removeAllTopBanners();
  }
}

export const notificationApi = new NotificationApi();

// Put it here only for debugging purpose
(window as any).notificationApi = notificationApi;
