import _ from 'underscore';
import { CountryCode, CallingCodeBeforePhoneNumberRegExp, NonEMEAVatCountries, YahooMarkets } from './constants/country';
import { getControlledCurrency } from './currency-utils';

export const isAddressRequired = (country = {}) => country.IsAddressRequired !== false;

export const isVatApplicable = (country = {}) => !!country.IsVatApplicable && !_.contains(NonEMEAVatCountries, country.Code.toUpperCase());

export const isTaxableCountry = (country = {}) => !!country.IsTaxableCountry;

export const isPaperlessBillingAllowed = (country = {}) => !!country.IsPaperlessBillingAllowed;

export const isPaperlessBillingEnforcement = (country = {}) => !!country.PaperlessBillingEnforced;

// TODO: add region property for Country from Domain Data MT Response
export const getRegion = country => (isVatApplicable(country) ? 'EMEA' : '');

/**
 * Display format for country and calling code
 * @param {object} country Country object
 * @param {string} Name Country localized name
 * @param {string} CountryCallingCode Country calling code
 * @returns {string} The display format for given country and calling code
 */
export const getCallingCodeDisplayByCountry = (country = {}) => {
  const callingCodeString = _.isEmpty(country.CountryCallingCode) ? '' : ` (+${country.CountryCallingCode})`;
  return `${country.Name || ''}${callingCodeString}`;
};

/**
 * Returns a copy of the given country object with additional utility methods.
 * @param {object} country The country object to be extended with additional utility methods.
 * @returns {object} A copy of the extended country object.
 */
export const extendCountry = (country = {}) => _.defaults({}, country, {
  isAddressRequired: () => isAddressRequired(country),
  isVatApplicable: () => isVatApplicable(country),
  isPaperlessBillingAllowed: () => isPaperlessBillingAllowed(country),
  isPaperlessBillingEnforcement: () => isPaperlessBillingEnforcement(country),
  getRegion: () => getRegion(country),
  callingCodeDisplay: () => getCallingCodeDisplayByCountry(country),
});

/**
 * Looks through the list 'countries' and returns the first country found by code, with or without extension
 * if no match is found, undefined will be returned.
 * @param {object[]} countries the list of countries
 * @param {string} countryCode the country code
 * @param {bool} [withExtensions] Specifiy if we should add extensions to the country found
 * @returns {object} the first country found from the list 'countries', or undefined otherwise
 */
export const findCountry = ({ countries, countryCode, withExtensions = false } = {}) => {
  const country = _.findWhere(countries, { Code: countryCode });
  return country && withExtensions ? extendCountry(country) : country;
};

/**
 * Sort countries from MT domainData by localized country name
 * @param {object[]} countries List of countries returned by domain data
 * @param {string} countries[].Name Country localized name
 * @param {string} countries[].Code Country code
 * @param {string} countries[].CountryCallingCode Country calling code
 * @param {string} countries[].Currency Country currency
 * @param {number} countries[].Id Country id
 * @param {boolean} countries[].IsAddressRequired It indicates if country's address is required
 * @param {boolean} countries[].IsVatApplicable It indicates if country's VAT is applicable
 * @param {boolean} countries[].IsPaperlessBillingAllowed It indicates if country allow paperless billing
 * @returns {array} The sorted countries
 */
export const sortCountries = (countries) => {
  if (!_.isArray(countries)) {
    return [];
  }
  return countries.sort((item1, item2) => item1.Name.localeCompare(item2.Name, 'co', { sensitivity: 'base' }));
};

/**
 * Group countries by calling code because some countries share same calling code,
 * we should show one line in dropdown list. Also calling code for AN is not correct.
 * @param {object[]} countries List of countries returned by domain data
 * @param {string} countries[].Name Country localized name
 * @param {string} countries[].Code Country code
 * @param {string} countries[].CountryCallingCode Country calling code
 * @param {string} countries[].Currency Country currency
 * @param {number} countries[].Id Country id
 * @param {boolean} countries[].IsAddressRequired It indicates if country's address is required
 * @param {boolean} countries[].IsVatApplicable It indicates if country's VAT is applicable
 * @param {boolean} countries[].IsPaperlessBillingAllowed It indicates if country allow paperless billing
 * @returns {array} The countries grouped by calling code
 */

export const groupCountriesByCallingCode = (countries) => {
  const CountryNameSeperator = ', ';
  const moveUsInFront = (countryList) => {
    const indexOfUS = _.findIndex(countryList, { Code: CountryCode.US });
    if (indexOfUS > 0) {
      const arrayWithCountryUS = countryList.splice(indexOfUS, 1);
      return arrayWithCountryUS.concat(countryList);
    }
    return countryList;
  };

  return _.chain(countries)
    .omit(country => country.Code === CountryCode.AN) // omit calling code 599 since it's not accurate
    .groupBy('CountryCallingCode')
    .map((_countryList, callingCode) => {
      const countryList = moveUsInFront(_countryList);
      return {
        CountryCallingCode: callingCode,
        Name: _.pluck(countryList, 'Name').join(CountryNameSeperator),
      };
    })
    .value();
};


/**
 * Find country by calling code and get display format
 * @param {object[]} countries List of countries returned by domain data
 * @param {string} callingCode Calling code string
 * @param {string} countries[].Name Country localized name
 * @param {string} countries[].CountryCallingCode Country calling code
 * @returns {array} The display format of country and calling code for given calling code
 */
export const getCallingCodeDisplayByCallingCode = (countries, callingCode) => {
  const country = _.findWhere(countries, { CountryCallingCode: callingCode });
  return getCallingCodeDisplayByCountry(country);
};

/**
 * Get calling code and phone number from a phone string
 * @param {string} phone Phone string with calling code and phone number
 * @returns {object} Calling code and phone number
 */
export const getPhoneNumberMatchResult = (phone) => {
  const phoneNumberWithCallingCodeMatchResult = new RegExp(CallingCodeBeforePhoneNumberRegExp).exec(phone);
  return {
    phoneNumber: _.result(phoneNumberWithCallingCodeMatchResult, 2, phone),
    callingCode: _.result(phoneNumberWithCallingCodeMatchResult, 1),
  };
};

/**
 * Combine calling code and phone number to a phone string, refer to `CallingCodeBeforePhoneNumberRegExp`
 * @param {string} callingCode calling code
 * @param {string} phoneNumber phone number
 * @returns {string} phone string
 */
export const combineCallingCodeAndPhoneNumber = (callingCode, phoneNumber) => {
  const callingCodeString = _.isEmpty(callingCode) ? '' : `${callingCode}-`;
  return `${callingCodeString}${phoneNumber}`;
};

/**
 * Get country code of the indicated account
 * @param {object} account account to get country code
 * @returns {string} country code of the indicated account
 */
export const getAccountCountryCode = account => _.chain(account).result('BusinessAddress').result('Country').value();

/**
 * Remove Yahoo markets from country list
 * @function
 * @param {object[]} countries List of countries
 * @param {string} countries[].Code Country code
 * @param {array} options.countryCodeKeepList countryCodeKeepList special country codes that we want to keep, e.g. HK, only remove TW
 * @returns {array} The country list excluding Yahoo markets
 */
export const removeYahooMarkets = (countries, { countryCodeKeepList } = {}) => _.reject(countries, ({ Code }) => (!_.contains(countryCodeKeepList, Code) && YahooMarkets.includes(Code)));

/**
 * Get available countries by controlled currency
 * @param {object[]} countries List of countries
 * @param {object[]} currencies List of currencies
 * @param {string} currencyType - The currency type
 * @returns {Array} country of the controlled currency, return null if not a controlled currency
 */
export const getAvailableCountriesByControlledCurrency = ({ countries, currencies, currencyType }) => {
  const controlledCurrency = getControlledCurrency({ currencies, currencyType });
  return controlledCurrency ? countries.filter(country => country.Id === controlledCurrency.ControlledCurrencyCountryId) : null;
};

/**
 * Get available countries by currency countries mapping
 * @param {object[]} countries List of countries
 * @param {object[]} currencyCountriesMapping List of currency country mapping
 * @param {string} currencyType - The currency type
 * @returns {Array} country of the controlled currency, return null if not a controlled currency
 */
export const getAvailableCountriesByCurrencyCountriesMapping = ({ countries, currencyCountriesMapping, currencyType }) => {
  const availableCountryListByCurrency = _.get(_.find(currencyCountriesMapping, record => record.currency === currencyType), 'countries', []);
  return currencyType ? countries.filter(country => _.contains(availableCountryListByCurrency, country.Code)) : countries;
};

/**
 * get available countries from currencyCountriesMapping
 * @param {object[]} countries List of countries
 * @param {object[]} currencyCountriesMapping - The currency countries mapping, each currency is mapping to a list of country code, optional
 * @param {object[]} currencies List of currencies, optional
 * @param {string} currencyType - The currency type, optional
 * @param {string} countryCode -  Country code, optional
 * @returns {Array.<string>} The available country code list filtered by currencyType, if no currencyType, return all countries
 */
export const getAvailableCountriesByCurrency = ({
  countries, currencyCountriesMapping = [], currencies = [], currencyType = '', countryCode = '',
}) => {
  const controlledCurrencyCountry = getAvailableCountriesByControlledCurrency({ countries, currencies, currencyType });
  if (controlledCurrencyCountry) {
    return controlledCurrencyCountry;
  }

  const filteredCountries = getAvailableCountriesByCurrencyCountriesMapping({ countries, currencyCountriesMapping, currencyType });
  // we want to keep yahoo markets for existing accounts
  return removeYahooMarkets(filteredCountries, { countryCodeKeepList: [countryCode] });
};

/**
 * Get available countries for the account mode
 * @param {object[]} countries List of countries
 * @param {int} accountMode - The accountMode of the account
 * @param {object} allowedCountriesByAccountModeMapping - the allowed countries by accountMode mapping
 * @param {string} [countryCodePropertyKey] - The key to access the country code of the country object. Defaults to 'Code'
 * @returns {Array.<string>} The available country code list filtered by accountMode, if no accountMode, or no restrictions, return all countries
 */
export const getAvailableCountriesByAccountMode = ({
  countries, accountMode, allowedCountriesByAccountModeMapping, countryCodePropertyKey = 'Code',
}) => {
  const allowedcountryList = accountMode && _.isObject(allowedCountriesByAccountModeMapping) && allowedCountriesByAccountModeMapping[accountMode];
  if (allowedcountryList) {
    return countries.filter(country => _.contains(allowedcountryList, country[countryCodePropertyKey]));
  }

  return countries;
};
