import Promise from 'bluebird';
import _ from 'underscore';

/**
 * Use FileReader to read file as byte array. For FileReader, @see {@link https://developer.mozilla.org/en-US/docs/Web/API/FileReader}
 * Browser compatibility: IE 10/Chrome 7/Firefox 3.6/Edge 12
 * @function
 * @param {object} obj Parameter object
 * @property {File} obj.file The file needs to be converted
 * @returns {Promise<Array<Number>>} Promise, will resolve a byte array
 */
export const readFileAsByteAray = ({ file }) => new Promise((resolve) => {
  const reader = new FileReader();

  reader.onload = ({ target: { result } }) => {
    resolve(_.toArray(new Uint8Array(result)));
  };

  reader.readAsArrayBuffer(file);
});

/**
 * Append extra data to FormData object before sent to server, modified the solution https://stackoverflow.com/questions/22783108/convert-js-object-to-form-data/42483509#42483509,
 * and adapted it to ASP.NET MVC: Object property will be chained by '.', Array index will be wrapped with '[]'.
 * This function is mainly created for sending data and files together.
 * Example:
 *  Passed-in arguments
 *    formData: { FormKey1: '1', FormKey2: 2 }
 *    data: {
 *            JsonKey1: {
 *              JsonKey2: 3
 *            },
 *            JsonKey3: [
 *              { JsonKey4: '4' }
 *            ],
 *            JsonKey5: fileObj,
 *            JsonKey6: null,
 *            JsonKey7: () => {}
 *          }
 *    parentKey: 'ParentKey'
 *  Returned value:
 *    formData: {
 *                 FormKey1: '1',
 *                 FormKey2: '2',
 *                 ParentKey.JsonKey1.JsonKey2: '3',
 *                 ParentKey.JsonKey3[0].JsonKey4: '4',
 *                 ParentKey.JsonKey5: fileObj,
 *                 ParentKey.JsonKey6: ''
 *              }
 * @param {FormData} formData - the original FormData object reference, it can be empty FormData or FormData with some values
 * @param {*} data - the data to append, can be any type
 * @param {string} parentKey - the parentKey to data, will be the prefix of data's property names
 * @returns {FormData} the appended FormData object
 */
export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof Blob)) {
    const subKeyFormatter = Array.isArray(data) ? key => `[${key}]` : key => `.${key}`;

    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}${subKeyFormatter(key)}` : key);
    });
  } else if (parentKey && typeof data !== 'function') {
    const value = data === null || data === undefined || Number.isNaN(data) ? '' : data;

    formData.append(parentKey, value);
  }

  return formData;
};

/**
 * Recursively detect if the data contains file.
 * Return true if the data is an instance of Blob, or the data is an object containing Blob,
 * otherwise return false.
 * @param {*} data - the data to check, can be any type
 * @returns {bool} the bool result
 */
export const containsFile = (data) => {
  if (data instanceof Blob) {
    return true;
  }

  if (data && typeof data === 'object') {
    return Object.values(data).some(containsFile);
  }

  return false;
};
