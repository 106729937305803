import _ from 'underscore';
import { Metadata, AddressFields, RuleName } from './address-metadata';
import { BusinessNameMaxLength } from './constants/abl';

const AddressTwoLinesSeparator = ', ';
const AddressBlockLinesSeparator = ' ';
const AddressInlineSeparator = ' ';
const AddressProperties = [..._.toArray(AddressFields), 'Id', 'Country', 'StateOrProvince'];
const {
  maxLength,
  minLength,
  required,
  match,
} = RuleName;

const getMetadata = countryCode => Metadata[countryCode] || Metadata.default;
const inlineFormatOfProperties = (address, properties, _separator) => _.reduce(properties, (memo, field) => {
  const addressField = _.isString(address[field]) ? address[field].trim() : address[field];
  const separator = _.isEmpty(memo) ? '' : _separator;
  return _.isEmpty(addressField) ? memo : `${memo}${separator}${addressField}`;
}, '');

/**
 * Find the latest(max TimeStamp) validated address(if there is) from a given nonempty address list, the logic is to keep the same result with backend(improved with O(n) solution):
 * https://msasg.visualstudio.com/Bing_Ads/_git/AnB?path=%2Fprivate%2FClientCenter%2FMT%2FSource%2FClientCenter%2FEO%2FAddress%2FCustomerAddressLoadEO.cs&version=GBmaster&line=152&lineEnd=154&lineStartColumn=38&lineEndColumn=47&lineStyle=plain&_a=contents
 * The TimeStamp got from backend is byte[8], it doesn't represent any real date, instead it means the upsert order of each record: https://docs.microsoft.com/en-us/sql/t-sql/data-types/rowversion-transact-sql?redirectedfrom=MSDN&view=sql-server-ver15
 * The algorithm used here is to construct the HEX string version of TimeStamp leading with the validated identifier(validated: 1, not: 0). So that the max string is the candidate.
 * @param {Array.<address>} addressList The given nonempty address list
 * @returns {address} Retruns the found address
 */
export const findLatestValidatedAddress = addressList => _.max(
  addressList,
  ({ IsValid, ValidationVersionId, TimeStamp }) =>
    (_.isBoolean(IsValid) || _.isNumber(ValidationVersionId) ? '1' : '0')
    + (_.isArray(TimeStamp) ? TimeStamp.map(byte => byte.toString(16)).join('') : '0000000000000000') // safe guard for no-TimeStamp address
);

/**
 * @param {object} address The address object.
 * @returns {boolean} Returns if given address object is not empty and it's country property is not empty.
 */
export const exists = address => _.isObject(address) && !_.isEmpty(address.Country);

/**
 * Whether the given address object is considered as not empty
 * The original code is from: https://msasg.visualstudio.com/DefaultCollection/Bing_Ads/_git/AdsAppUI?path=%2Fprivate%2FUI%2FCommon%2FMicrosoft.Advertising.Web.Mvc%2FControllers%2FBusinessAddressController.cs&version=GBmaster&line=130&lineEnd=142&lineStartColumn=9&lineEndColumn=10&lineStyle=plain&_a=contents
 * @param {object} address - The address object
 * @returns {boolean} - Returns true is the given address exists and it contains either StateOrProvince, PostalCode or City is not empty
 */
export const isNotEmpty = address => exists(address) && _.any(['StateOrProvince', 'PostalCode', 'City'], property => !_.isEmpty(address[property]));

/**
 * @param {object} address The address object.
 * @returns {array} Returns array of string to display given address object with metadata display rules.
 */
// TODO: will refactor with React after
export const blockLinesFormat = (address) => {
  if (!exists(address)) {
    return [];
  }
  const metadata = getMetadata(address.Country);
  const mapLine = line => _.reduce(line, (memo, field) => {
    if (_.contains(AddressFields, field)) {
      const addressField = _.isString(address[field]) ? address[field].trim() : address[field];
      const separator = _.isEmpty(memo) ? '' : AddressBlockLinesSeparator;
      return _.isEmpty(addressField) ? memo : `${memo}${separator}${addressField}`;
    }
    return `${memo}${_.isEmpty(memo) ? '' : field}`;
  }, '');

  return _.compact(_.map(metadata.BlockDisplayFormat, mapLine));
};

/**
 * @param {object} address The address object.
 * @returns {string} Returns display string of given address object with metadata properties.
 */
export const inlineFormat = (address) => {
  if (!exists(address)) {
    return '';
  }

  return blockLinesFormat(address).join(AddressInlineSeparator);
};

/** A method to get the address as a string, in a standard address format.
 * @param {object} address The address object.
 * @returns {string} Returns string of given address object, formatted like a typical address in a form. (e.g. 'line1 line2 line3 line4 city, state postalcode country')
 */
export const inlineFormatWithBusinessName = (address) => {
  if (!exists(address)) {
    return '';
  }

  return `${address.BusinessName ? address.BusinessName + AddressTwoLinesSeparator : ''}${inlineFormat(address)}`;
};

/**
 * Keep exporting this dropdownFormat to avoid breaking account-creation customer-address-selector
 * TODO: remove this util after replacing dropdownFormat with inlineFormatWithBusinessName
 */
export const dropdownFormat = inlineFormatWithBusinessName;

/**
 * @param {object} address The address object.
 * @returns {array} Returns array of string to display given address object in two lines.
 */
export const twoLinesFormat = (address) => {
  if (!exists(address)) {
    return [];
  }
  const line1 = inlineFormatOfProperties(address, [AddressFields.Line1, AddressFields.Line2, AddressFields.Line3, AddressFields.Line4], AddressTwoLinesSeparator);
  const line2 = inlineFormatOfProperties(address, [AddressFields.City, AddressFields.StateOrProvinceName, AddressFields.PostalCode, AddressFields.CountryName], AddressTwoLinesSeparator);

  return _.compact([line1, line2]);
};

/**
 * Returns error object for given address
 * @param {object} address The address object to be validate.
 * @param {object} options The options.
 * @param {boolean} options.isOptional Whether address fields are required.
 * @returns {object} An object {[field]: array} that shows all errors for invalid fields, array is with object {ruleName, ruleValue}.
 */
export const validateAndReturnError = (address, { isOptional = false } = {}) => {
  const error = {};

  if (!exists(address)) {
    if (isOptional) {
      return {};
    }
    return { Country: [{ ruleName: required }] };
  }

  const metadata = getMetadata(address.Country);

  _.each(metadata, (field, addressProperty) => {
    const ruleValueRequired = isOptional ? false : field[required];
    const ruleValueMaxlength = field[maxLength];
    const ruleValueMinLength = field[minLength];
    const ruleValueMatch = field[match];
    const errorList = [];
    const value = (_.isString(address[addressProperty]) && address[addressProperty].trim()) || null;

    if (ruleValueRequired && _.isEmpty(value)) {
      errorList.push({ ruleName: required });
    }
    if (ruleValueMaxlength && !_.isEmpty(value) && value.length > ruleValueMaxlength) {
      errorList.push({ ruleName: maxLength, ruleValue: ruleValueMaxlength });
    }
    if (ruleValueMinLength && !_.isEmpty(value) && value.length < ruleValueMinLength) {
      errorList.push({ ruleName: minLength, ruleValue: ruleValueMinLength });
    }
    if (ruleValueMatch && !_.isEmpty(value) && _.isEmpty(value.match(ruleValueMatch))) {
      errorList.push({ ruleName: match, ruleValue: ruleValueMatch });
    }

    if (!_.isEmpty(errorList)) {
      error[addressProperty] = errorList;
    }
  });

  return error;
};

/**
 * Returns error object for given address that only validate MT contraint
 * For now we only validate maxlength for each property of billing address
 * @param {object} address The address object to be validate.
 * @returns {object} An object {[field]: array} that shows all errors for invalid fields, array is with object {ruleName, ruleValue}.
 */
export const validateMTConstraintOnly = address => _.chain(getMetadata(address && address.Country))
  .mapObject((field, addressProperty) => {
    const ruleValueMaxlength = field[maxLength];
    const value = _.isString(address[addressProperty]) && address[addressProperty].trim();

    if (ruleValueMaxlength && !_.isEmpty(value) && value.length > ruleValueMaxlength) {
      return [{ ruleName: maxLength, ruleValue: ruleValueMaxlength }];
    }
    return undefined;
  })
  .pick(_.identity)
  .value();

/**
 * @param {object} address The address object.
 * @param {object} options The options.
 * @param {boolean} options.isOptional Whether address fields are required.
 * @returns {boolean} Returns if every property of given address object is valid according to metadata rules.
 */
export const isValid = (address, options) => _.isEmpty(validateAndReturnError(address, options));

/**
 * @param {string} businessName The name of the business to check for validity.
 * @param {object} options The options.
 * @param {boolean} options.isOptional Whether business name is required.
 * @returns {boolean} Returns whether the business name is valid
 */
export const businessNameIsValid = (businessName, { isOptional = false } = {}) =>
  (!_.isEmpty(businessName) ? businessName.length < BusinessNameMaxLength : isOptional);

/**
 * @param {object} accountBusinessAddress The account business address object.
 * @param {object} options The options.
 * @param {boolean} options.isOptional Whether business name is required.
 * @returns {boolean} Returns true if given account business name object is valid.
 */
export const isValidBusinessName = (accountBusinessAddress, { isOptional = false } = {}) =>
  (_.isObject(accountBusinessAddress) ? businessNameIsValid(accountBusinessAddress.BusinessName, { isOptional }) : isOptional);

/**
 * Returns an address object with normal properties and string values trimmed
 * @param {object} address The address object to be normalized.
 * @returns {object} An address object with normal properties.
 */
export const normalizeAddress = address => _.chain(address)
  .pick(AddressProperties)
  .mapObject((field) => {
    const fieldValue = _.has(field, 'value') ? field.value : field;
    return _.isString(fieldValue) ? fieldValue.trim() : fieldValue;
  })
  .value();

/**
 * Returns true if the 2 addresses exist and have their field matching exactly.
 * Only the fields of the normalizedAddress are compared if forceNormalized is set to true, and falsy values (undefined, null, '') are all treated like a not existing field.
 * @param {object} address1 The first address object.
 * @param {object} address2 The second address object.
 * @param {bool} options.forceNormalized true to force addresses in param to get normalized, false otherwise.
 * @param {Array.<string>} options.propertiesToCompare properties to compare.
 * @returns {boolean} true if all field matches, false otherwise.
 */
export const areEqual = (address1, address2, { forceNormalized = false, propertiesToCompare = AddressProperties } = {}) => {
  if (!exists(address1) || !exists(address2)) {
    return false;
  }
  const addressToCompare1 = forceNormalized ? normalizeAddress(address1) : address1;
  const addressToCompare2 = forceNormalized ? normalizeAddress(address2) : address2;

  const fieldsMatch = (field1, field2) => field1 === field2 || (Boolean(field1) === false && Boolean(field2) === false);
  return _.all(propertiesToCompare, field => fieldsMatch(addressToCompare1[field], addressToCompare2[field]));
};

/**
 * Address type definition
 * @typedef {object} address
 * @property {string} Id The id of address object.
 * @property {string} Country The country of address object.
 * @property {string} Line1 The line1 of address object.
 * @property {string} Line2 The line2 of address object.
 * @property {string} Line3 The line3 of address object.
 * @property {string} Line4 The line4 of address object.
 * @property {string} City The city of address object.
 * @property {string} StateOrProvince The state of address object.
 * @property {string} PostalCode The postal code of address object.
 * @property {string} CountryName The localiazed country name of address object.
 * @property {string} StateOrProvinceName The localiazed state name of address object.
 */

/**
 * Returns a copy of the given address object with additional utility methods.
 * @param {object} _address The address object to be extended with additional utility methods.
 * @returns {object} A copy of the extended address object.
 */
export const extendAddress = (_address) => {
  const address = normalizeAddress(_address);

  return _.defaults(address, {
    inlineFormat: () => inlineFormat(address),
    twoLinesFormat: () => twoLinesFormat(address),
    blockLinesFormat: () => blockLinesFormat(address),
    exists: () => exists(address),
    isValid: () => isValid(address),
    isValidBusinessName: () => isValidBusinessName(address),
  });
};

/**
 * Remove duplicate addresses based on addressFormatter from the given addressList
 * @param {Array.<address>} addressList - the given addressList
 * @param {object} options - extra options
 * @param {Array.<number>} options.includedAddressIds - the address Ids to be included in the result list
 * @param {function} options.addressFormatter - the address formatter function
 * @param {function} options.addressValidator - the address validator function that filters expected addresses
 * @returns {Array.<address>} - the deduplicate address list, with an extra 'formatted' property in each address
 */
export const removeDuplicateAddresses = (addressList, {
  includedAddressIds = [],
  addressFormatter = inlineFormatWithBusinessName,
  addressValidator = isNotEmpty,
} = {}) => {
  const validAddressList = _.isFunction(addressValidator) ? _.filter(addressList, addressValidator) : addressList;
  const includedAddresses = _.filter(validAddressList, address => includedAddressIds.includes(address.Id));
  const formattedIncludedAddresses = includedAddresses.map(address => addressFormatter(address));

  return _.chain(validAddressList)
    .map(address => ({ ...address, formatted: addressFormatter(address) }))
    .reject(({ Id, formatted }) => formattedIncludedAddresses.includes(formatted) && !includedAddressIds.includes(Id))
    .groupBy(address => address.formatted)
    .map(findLatestValidatedAddress)
    .value();
};

/**
 * Finds duplicate addresses in the given address list.
 *
 * @param {Array} addressList - The list of addresses to search for duplicates.
 * @param {Object} options - The options object.
 * @param {string} options.targetAddressId - The ID of the address to exclude from the search.
 * @param {Function} [options.addressFormatter=inlineFormatWithBusinessName] - The function to format the addresses for comparison.
 * @param {Function} [options.addressValidator=isNotEmpty] - The function to validate the addresses before searching for duplicates.
 * @returns {Array} - The list of duplicate addresses.
 */
export const findDuplicateAddresses = (addressList, {
  targetAddressId,
  addressFormatter = inlineFormatWithBusinessName,
  addressValidator = isNotEmpty,
} = {}) => {
  const validAddressList = _.isFunction(addressValidator) ? _.filter(addressList, addressValidator) : addressList;
  const targetAddress = _.findWhere(validAddressList, { Id: targetAddressId });
  const formattedTargetAddress = addressFormatter(targetAddress);

  return _.chain(validAddressList)
    .reject(({ Id }) => Id === targetAddressId)
    .filter(address => addressFormatter(address) === formattedTargetAddress)
    .value();
};

/**
 * @typedef {object} ValidatedField
 * @property {string} value The field value
 * @property {bool} isValid The validation result of the field value
 *
 * @typedef {object} ValidatedAddress
 * @property {ValidatedField} Country
 * @property {ValidatedField} Line1
 * @property {ValidatedField} Line2
 * @property {ValidatedField} Line3
 * @property {ValidatedField} Line4
 * @property {ValidatedField} City
 * @property {ValidatedField} StateOrProvince
 * @property {ValidatedField} PostalCode
 */

/**
 * TODO: Let AddressForm callback this AddressObject
 * Returns an plain address object from AddressForm callbacked validated address
 * @param {ValidatedAddress} validatedAddress - The validated address callbacked from AddressForm
 * @returns {address} - An plain address object.
 */
export const getAddressObject = validatedAddress =>
  _.chain(validatedAddress)
    .omit('isValid')
    .mapObject(({ value }) => value)
    .value();

/**
 * Returns the city, state, country string from the given address object
 * Used only in CBV2 project
 * @param {address} address - The address object
 * @param {object} i18n - The i18n object
 * @returns {string} - The city, state, country string
 */
export const getCityStateCountryString = (address = {}, i18n) => {
  const { City, Country, CountryName } = address;
  const StateOrProvinceName = !_.isEmpty(address.StateOrProvinceName) ? address.StateOrProvinceName : i18n.getString(_TL_('No state/province'));
  if (Country === 'US' && City && CountryName) {
    return `${City}, ${StateOrProvinceName}, ${CountryName}`;
  }
  return CountryName ? `${StateOrProvinceName}, ${CountryName}` : '';
};

/**
 * Returns true if the two addresses have the same city, state, country
 * @param {address} address1 address object 1
 * @param {address} address2 address object 2
 * @param {object} i18n - The i18n object
 * @returns {boolean} true if city, state, country match, false otherwise
 */
export const isCityStateCountryMatch = (address1, address2, i18n) => {
  const cityStateCountry1 = getCityStateCountryString(address1, i18n);
  const cityStateCountry2 = getCityStateCountryString(address2, i18n);
  return cityStateCountry1 === cityStateCountry2;
};
