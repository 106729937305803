import { Validation } from '@bingads-webui/validation';
import { BaseFactory } from './base-factory';

/**
 * Factory for array validation rules
 * @param {Object} options - input for schema property bag and i18n
 */
export class ArrayFactory extends BaseFactory {
  /**
   * Create array rules set from schema property
   * @returns {Object} - array validation rules set
   */
  create() {
    const rules = {};

    if (this.prop.minItems || this.prop.maxItems) {
      rules.size = Validation.array.size(this.prop.minItems, this.prop.maxItems, this.i18n);
    }

    if (this.prop.uniqueItems) {
      rules.unique = Validation.array.unique(this.i18n);
    }

    return rules;
  }
}
