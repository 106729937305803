import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';

import { PermissionsContext } from './context';

export const withPermissions = (WrappedComponent) => {
  const HoC = props => (
    <PermissionsContext.Consumer>
      {permissions => <WrappedComponent {...props} permissions={permissions} />}
    </PermissionsContext.Consumer>
  );
  HoC.displayName = `WithPermission(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(HoC, WrappedComponent);

  return HoC;
};
