/* eslint no-param-reassign: 0 */
import _ from 'underscore';
import Promise from 'bluebird';
import URI from 'urijs';

import { http } from '@bingads-webui/http-util';

import AjaxHelper from './ajax-helper';

const defaultSetting = {
  __adcenterAJAX: true,
  data: {},
  contentType: 'application/json',
  dataType: null,
  onsuccessEventData: {},
  traditional: false,
  silent: false,
};

const antiForgeryTokenKey = '__RequestVerificationToken';

function addAntiForgeryToken(options) {
  const antiForgeryTokenInput = document.querySelector(`input[name="${antiForgeryTokenKey}"]`);
  const antiForgeryToken = antiForgeryTokenInput && antiForgeryTokenInput.value;
  const method = _.result(options.method, 'toUpperCase');

  if (antiForgeryToken) {
    switch (options.contentType) {
      case 'application/json':
        options.headers = _.extend({}, options.headers, { [antiForgeryTokenKey]: antiForgeryToken });
        break;
      case 'application/x-www-form-urlencoded':
        if (method && method !== 'GET' && method !== 'DELETE') {
          options.data = _.extend({}, options.data, { [antiForgeryTokenKey]: antiForgeryToken });
        }
        break;
      case false:
        if (options.useFormDataFormat && options.data instanceof FormData && !options.data.get(antiForgeryTokenKey)) {
          options.data.append(antiForgeryTokenKey, antiForgeryToken);
        }
        break;
      default:
        break;
    }
  }
}

function appendQuery(uri, options, defaultValue) {
  let param = _.isObject(options.params) ? options.params : {};

  param = _.defaults(param, defaultValue);
  return new URI(uri).addQuery(param).toString();
}

export default class {
  constructor({
    cidFetcher,
    uidFetcher,
    ccuiAlertCenter,
    handleAjaxAuthFailure,
    appendErrorSummary,
    ajaxSuccess,
    willSupportCors = false,
  }) {
    this.cidFetcher = cidFetcher;
    this.uidFetcher = uidFetcher;
    this.willSupportCors = willSupportCors;

    this.ajaxHelper = new AjaxHelper({
      ccuiAlertCenter,
      handleAjaxAuthFailure,
      appendErrorSummary,
      ajaxSuccess,
    });
  }

  ajax = (uri, options, cb) => {
    const extendedOptions = _.defaults(
      {},
      options,
      defaultSetting
    );

    if (this.willSupportCors === true) {
      const defaultCorsHeaders = { 'X-Requested-With': 'XMLHttpRequest' };
      extendedOptions.headers = _.extend({}, defaultCorsHeaders, extendedOptions.headers);

      const defaultCorsXhrFields = { withCredentials: true };
      extendedOptions.xhrFields = _.extend({}, defaultCorsXhrFields, extendedOptions.xhrFields);
    }

    addAntiForgeryToken(extendedOptions);

    extendedOptions.beforeSend = this.ajaxHelper.beforeSend(extendedOptions);
    extendedOptions.success = this.ajaxHelper.success(extendedOptions);
    extendedOptions.error = this.ajaxHelper.error(extendedOptions);
    extendedOptions.complete = this.ajaxHelper.complete(extendedOptions);

    const cidUidQueryParam = {};
    if (_.isFunction(this.cidFetcher)) {
      const cid = this.cidFetcher();
      _.extend(cidUidQueryParam, { cid });
    }
    if (_.isFunction(this.uidFetcher)) {
      const uid = this.uidFetcher();
      _.extend(cidUidQueryParam, { uid });
    }
    // append cid and uid to the url
    const integrateUri = appendQuery(uri, extendedOptions, cidUidQueryParam);

    return http.ajax(integrateUri, extendedOptions, cb)
      .then(res => (res.Errors ? Promise.reject(res) : Promise.resolve(res)));
  };

  defaults = options => (...params) => {
    const [url, opt, callback] = http.signature(params);
    const op = _.defaults({}, opt, options);

    return this.ajax(url, op, callback);
  };

  get = this.defaults({ method: 'get' });
  put = this.defaults({ method: 'put' });
  post = this.defaults({ method: 'post' });
  patch = this.defaults({ method: 'patch' });
  delete = this.defaults({ method: 'delete' });
}
