import _ from 'underscore';

const defaultValues = {
  integer: 0,
  number: undefined,
  string: '',
  boolean: false,
  object: {},
  array: [],
  datetime: {},
};

/**
 * get default value for property
 * @param {Object} prop - property define, it's from schema
 * @returns {any} - return default value for different type
 */
export function getDefaultValue(prop) {
  if (_.has(prop, 'default')) {
    return prop.default;
  }

  if (!_.isEmpty(prop.enum)) {
    return prop.nullable ? null : _.first(prop.enum);
  }

  return _.clone(defaultValues[prop.type]);
}
