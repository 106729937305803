import _ from 'underscore';

/**
 * Get permission map from domaindata
 * @param {object[]} permissions List of permissions
 * @param {string} permissions[].id Id of permission
 * @param {string} permissions[].action Action name of permission
 * @param {boolean} permissions[].hasPermission Value that indicates if it has permission
 * @returns {object[]} An object with { action : hasPermission }
 */
export const getPermissionMap = (permissions) => {
  const permissionMap = {};
  _.each(permissions, permission => _.extend(permissionMap, { [permission.action]: permission.hasPermission }));
  return permissionMap;
};
