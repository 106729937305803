/* global PopupController */
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { I18nContext } from '@bingads-webui-react/i18n-context';
import classNames from 'classnames';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';

function onClick(event) {
  event.preventDefault();
  PopupController.PopupJsClick(event.target);
}

export function HelpButtonUnstyled(props) {
  const i18n = useContext(I18nContext);
  return (
    <button
      aria-label={props['aria-label'] || i18n.getString(_TL_('Learn more'))}
      aria-labelledby={props['aria-labelledby']}
      className={classNames('helpbutton', props.classes.helpButton)}
      onClick={onClick}
      topic={props.topic}
      type="helppopup"
    />
  );
}

HelpButtonUnstyled.propTypes = {
  'aria-label': PropTypes.string,
  'aria-labelledby': PropTypes.string,
  topic: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    helpButton: PropTypes.string,
  }).isRequired,
};

HelpButtonUnstyled.defaultProps = {
  'aria-label': null,
  'aria-labelledby': null,
};

export const HelpButton = withDefaultStyles(
  HelpButtonUnstyled,
  (components, { palette = {}, iconFontFamily = 'MsAds MDL2 Assets' }) => ({
    helpButton: {
      border: '0px',
      fontSize: '15px',
      backgroundColor: 'inherit !important',
      textDecoration: 'none !important',
      cursor: 'pointer',
      '&:focus': {
        border: `1px solid ${palette.neutralSecondary}`,
      },
      color: palette.themePrimary,
      fontWeight: 'normal',
      padding: '0px',
      display: 'block',
      '&:before': {
        top: '2px',
        content: '"\\E9CE"',
        position: 'relative',
        fontFamily: iconFontFamily,
      },
    },
  })
);
