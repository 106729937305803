import _ from 'underscore';
import { BaseFactory } from './base-factory';

/**
 * Factory for number conversion object
 * @param {Object} options - input for schema property bag and i18n
 */
export class NumberFactory extends BaseFactory {
  numberToString(num, numberOfDigits, i18n) {
    if (!_.isNumber(num)) {
      return num;
    }

    return i18n.formatDecimal(num, {
      minimumFractionDigits: numberOfDigits,
      maximumFractionDigits: numberOfDigits,
    });
  }

  stringToNumber(localizedString, i18n) {
    if (_.isEmpty(localizedString)) {
      // Convert to null if string is null or empty. Leave it to required validator to raise error.
      return null;
    }
    if (!i18n.isValidDecimal(localizedString)) {
      // Do not convert if it is not decimal. Leave it to validator raise type error.
      return localizedString;
    }
    return i18n.parseDecimal(localizedString);
  }

  /**
   * Create number conversion object
   * @returns {Object} - conversion object that convers number to and from string
   */
  create() {
    return {
      fromModel: value => this.numberToString(value, this.prop.decimalLength, this.i18n),
      toModel: value => this.stringToNumber(value, this.i18n),
    };
  }
}
