/* eslint-disable no-useless-escape */
export const RuleName = {
  maxLength: 'maxLength',
  minLength: 'minLength',
  required: 'required',
  match: 'match',
};
export const AddressFields = {
  Line1: 'Line1',
  Line2: 'Line2',
  Line3: 'Line3',
  Line4: 'Line4',
  City: 'City',
  StateOrProvinceName: 'StateOrProvinceName',
  PostalCode: 'PostalCode',
  CountryName: 'CountryName',
};

const defaultLine1 = {
  [RuleName.maxLength]: 512,
  [RuleName.required]: true,
  label: 'AddressControl_AddressLine1',
  visible: true,
  order: 0,
};
const defaultLine2 = {
  [RuleName.maxLength]: 512,
  label: 'AddressControl_AddressLine2',
  visible: true,
  order: 1,
};
const defaultLine3 = {
  [RuleName.maxLength]: 512,
  label: 'AddressControl_AddressLine3',
  visible: true,
  order: 2,
};
const defaultLine4 = {
  [RuleName.maxLength]: 512,
  label: 'AddressControl_AddressLine4',
  visible: true,
  order: 3,
};
const defaultCity = {
  [RuleName.maxLength]: 35,
  [RuleName.required]: true,
  label: 'AddressControl_City',
  visible: true,
  order: 4,
};
const defaultStateOrProvince = {
  [RuleName.maxLength]: 50,
  label: 'AddressControl_StateOrProvince',
  visible: true,
  order: 5,
};
const defaultBlockDisplayFormat = [
  [AddressFields.Line1],
  [AddressFields.Line2],
  [AddressFields.Line3],
  [AddressFields.Line4],
  [AddressFields.City],
  [AddressFields.StateOrProvinceName],
  [AddressFields.PostalCode],
  [AddressFields.CountryName],
];

const optionalStateOrProvinceCountries = ['AG', 'AI', 'AS', 'AW', 'CC', 'CD', 'CK', 'CX', 'BB', 'FK', 'GF', 'GI', 'GL', 'GP', 'GU', 'KN', 'LC', 'MO', 'MP', 'MS', 'MT', 'NC', 'NF', 'NR', 'NU', 'PF', 'PM', 'PN', 'PS', 'PW', 'RE', 'RS', 'SM', 'TK', 'TL', 'TO', 'VA', 'VG', 'VI', 'WF'];
const defaultAddressForOptionalStateOrProvinceCountries = {
  Line1: defaultLine1,
  Line2: defaultLine2,
  Line3: defaultLine3,
  Line4: defaultLine4,
  City: defaultCity,
  PostalCode: {
    [RuleName.maxLength]: 10,
    [RuleName.required]: true,
    label: 'AddressControl_PostalCode',
    visible: true,
    order: 5,
  },
  StateOrProvince: {
    [RuleName.maxLength]: 50,
    label: 'AddressControl_Province',
    visible: true,
    order: 6,
  },
  BlockDisplayFormat: [
    [AddressFields.Line1],
    [AddressFields.Line2],
    [AddressFields.Line3],
    [AddressFields.Line4],
    [AddressFields.City, AddressFields.PostalCode],
    [AddressFields.CountryName],
  ],
};

const optionalProvinceAndPostalCodeCountries = ['AN'];
const defaultAddressForOptionalProvinceAndPostalCodeCountries = {
  Line1: defaultLine1,
  Line2: defaultLine2,
  Line3: defaultLine3,
  Line4: defaultLine4,
  City: defaultCity,
  PostalCode: {
    [RuleName.maxLength]: 10,
    label: 'AddressControl_PostalCode',
    visible: true,
    order: 5,
  },
  StateOrProvince: {
    [RuleName.maxLength]: 50,
    label: 'AddressControl_Province',
    visible: true,
    order: 6,
  },
  BlockDisplayFormat: [
    [AddressFields.Line1],
    [AddressFields.Line2],
    [AddressFields.Line3],
    [AddressFields.Line4],
    [AddressFields.City, AddressFields.PostalCode],
    [AddressFields.CountryName],
  ],
};

const optionalStateOrProvinceCountriesMetadata = optionalStateOrProvinceCountries.reduce((prev, curr) => ({ ...prev, [curr]: defaultAddressForOptionalStateOrProvinceCountries }), {});
const optionalProvinceAndPostalCodeCountriesMetadata = optionalProvinceAndPostalCodeCountries.reduce((prev, curr) => ({ ...prev, [curr]: defaultAddressForOptionalProvinceAndPostalCodeCountries }), {});

export const Metadata = {
  US: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_State',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5}(\-[0-9]{4})?$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: [
      [AddressFields.Line1],
      [AddressFields.Line2],
      [AddressFields.Line3],
      [AddressFields.Line4],
      [AddressFields.City, ',', AddressFields.StateOrProvinceName, AddressFields.PostalCode],
      [AddressFields.CountryName],
    ],
  },
  CA: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_Province',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[a-zA-Z][0-9][a-zA-Z][ ]?[0-9][a-zA-Z][0-9]$',
      label: 'AddressControl_PostalCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: [
      [AddressFields.Line1],
      [AddressFields.Line2],
      [AddressFields.Line3],
      [AddressFields.Line4],
      [AddressFields.City, AddressFields.StateOrProvinceName, AddressFields.PostalCode],
      [AddressFields.CountryName],
    ],
  },
  IT: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_ProvinceOrRegion',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  ES: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  BR: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}[-][0-9]{3,3}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  IN: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{6,6}$',
      label: 'AddressControl_PostalCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  AU: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{4,4}$',
      label: 'AddressControl_ZipOrPostalCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  CN: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{6,6}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  GB: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^([gG][iI][rR][ ]?0[aA]{2,2}|[a-zA-Z]([0-9][0-9a-zA-Z]?|[a-zA-Z][0-9][0-9a-zA-Z]?)[ ]?[0-9][a-zA-Z]{2,2})$',
      label: 'AddressControl_PostalCode',
      visible: true,
      order: 5,
    },
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      label: 'AddressControl_Province',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  SG: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{6,6}$',
      label: 'AddressControl_PostalCode',
      visible: true,
      order: 5,
    },
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      label: 'AddressControl_Province',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: [
      [AddressFields.Line1],
      [AddressFields.Line2],
      [AddressFields.Line3],
      [AddressFields.Line4],
      [AddressFields.City, AddressFields.PostalCode],
      [AddressFields.CountryName],
    ],
  },
  FR: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_PostalCode',
      visible: true,
      order: 4,
    },
    City: {
      [RuleName.maxLength]: 35,
      [RuleName.required]: true,
      label: 'AddressControl_City',
      visible: true,
      order: 5,
    },
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      label: 'AddressControl_Province',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: [
      [AddressFields.Line1],
      [AddressFields.Line2],
      [AddressFields.Line3],
      [AddressFields.Line4],
      [AddressFields.PostalCode, AddressFields.City],
      [AddressFields.CountryName],
    ],
  },
  SA: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  DK: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{4,4}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  DE: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  AT: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[1-9][0-9]{3,3}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  CH: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[1-9][0-9]{3,3}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  FI: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  IL: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^([0-9]{5,5}|[0-9]{7,7})$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  KR: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{3,3}[-]?([0-9]{3,3}|[0-9]{2,2})$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  NL: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[1-9][0-9]{3,3}[ ]?[a-zA-Z]{2,2}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  NO: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{4,4}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  RU: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{6,6}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  SE: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^(3[013-9]|4[0-7]|9[0-8]|[125-8][0-9])[0-9][ ]{1,1}[0-9]{2,2}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  TW: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: defaultStateOrProvince,
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      [RuleName.match]: '^$|^[0-9]{3,3}|[0-9]{5,5}$',
      label: 'AddressControl_ZipCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
  ...optionalStateOrProvinceCountriesMetadata,
  ...optionalProvinceAndPostalCodeCountriesMetadata,
  default: {
    Line1: defaultLine1,
    Line2: defaultLine2,
    Line3: defaultLine3,
    Line4: defaultLine4,
    City: defaultCity,
    StateOrProvince: {
      [RuleName.maxLength]: 50,
      [RuleName.required]: true,
      label: 'AddressControl_StateOrProvince',
      visible: true,
      order: 5,
    },
    PostalCode: {
      [RuleName.maxLength]: 10,
      [RuleName.required]: true,
      label: 'AddressControl_ZipOrPostalCode',
      visible: true,
      order: 6,
    },
    BlockDisplayFormat: defaultBlockDisplayFormat,
  },
};
