export const CustomerProfilePropertyType = Object.freeze({
  LastLoginTime: 1,
  IpAddress: 2,
  AuthType: 3,
  ImpersonationEnabled: 4,
  IsOptedInForConsolidatedBilling: 5,
  IsOptedInForCampaignDetailsInInvoices: 6,
  IsCustomerOptedInForPostalMail: 7,
  IsCustomerOptedInPaperlessBilling: 8,
  IsEligibleForConsolidatedBilling: 9,
  NumberOfBillToInvoiceAccountsUnderCustomer: 10,
  NumberOfBillToPrepayAccountsUnderCustomer: 11,
  NumberOfBillToThresholdAccountsUnderCustomer: 12,
});

export const IsOptedIn = Object.freeze({
  on: '1',
  off: '0',
});

export const AppScope = Object.freeze({
  None: 0,
  Adv: 1,
  Pub: 2,
  Aca: 4,
  MultiChannelAds: 8,
  All: 7,
});

export const ManagedByType = Object.freeze({
  Microsoft: 0,
  Yahoo: 1,
  ChinaJV: 2,
});

export const CustomerFinancialStatus = Object.freeze({
  ProposalsOnly: 14,
  PendingCreditCheck: 15,
  NoHold: 16,
  SoldToOnly: 17,
  CreditHold: 18,
  CreditWarning: 19,
});

export const CustomerServiceLevel = Object.freeze({
  NA: 0,
  SelfServe: 1,
  SelfServeTrusted: 2,
  Premium: 3,
  Internal: 4,
  Select: 5,
});
