/* eslint-disable no-param-reassign */
import { StateStore } from '@bingads-webui/state-store';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'underscore';

import {
  INotification, INotificationDataSource, NotificationLevel, TargetComponent, DismissMode, ExtraOptions,
} from '../types';
import { DefaultFloatingBannerId } from '../constants';

interface INotificationStateStore {
  bannerNotificationList: INotification[];
  nQNotificationList: INotification[];
  floatingBannerNotification?: INotification | null;
}

export class NotificationStore extends StateStore<INotificationStateStore> {
  floatingBannerId: string = DefaultFloatingBannerId;

  notificationDataSource: INotificationDataSource;

  constructor() {
    super({
      bannerNotificationList: [],
      nQNotificationList: [],
      floatingBannerNotification: null,
    });
    // Set default notification data source
    this.notificationDataSource = {
      getNotifications: () => Promise.resolve([]),
      shouldClearOldNotification: () => false,
      shouldShowNewNotification: () => true,
    };
  }

  public setDataSource(dataSource: INotificationDataSource) {
    this.notificationDataSource = dataSource;
  }

  public async refresh() {
    let notifications = await this.notificationDataSource.getNotifications();

    notifications = notifications.filter((notification) => notification.condition === true && this.notificationDataSource.shouldShowNewNotification(notification));

    const { bannerNotificationList, nQNotificationList, floatingBannerNotification } = this.groupNotifications(
      notifications,
    );

    // dispatch notifications here
    this.setState({
      bannerNotificationList: this.mergeToLeft(this.state.bannerNotificationList, bannerNotificationList),
      nQNotificationList: this.mergeToLeft(this.state.nQNotificationList, nQNotificationList),
      floatingBannerNotification: floatingBannerNotification || this.state.floatingBannerNotification,
    });
  }

  // TODO: Need to add unit test
  mergeToLeft(leftList: INotification[], rightList: INotification[]) {
    const rightMap: any = {};
    let resultList = [];
    rightList.forEach((item) => {
      rightMap[item.id] = item;
    });

    // clear notification that scopeLevel is lower than current change scope
    resultList = leftList.filter((notification) => {
      if (this.notificationDataSource.shouldClearOldNotification(notification)) {
        return false;
      }

      return true;
    });

    // Replace old one to new one if one notification exists in both new UI and old UI
    resultList = resultList.map((item) => {
      if (rightMap[item.id]) {
        const newNotification = rightMap[item.id];
        rightMap[item.id] = undefined;
        return newNotification;
      }
      return item;
    });

    // Add new notification in rightList to result list
    Object.keys(rightMap).forEach((key) => {
      if (rightMap[key]) {
        resultList.push(rightMap[key]);
      }
    });

    return _.assign([], _.compact(resultList));
  }

  groupNotifications(notificationList: INotification[]) {
    const bannerNotificationList: INotification[] = [];
    const nQNotificationList: INotification[] = [];
    let floatingBannerNotification;

    notificationList.forEach((notification) => {
      if (notification.target === TargetComponent.TopBanner) {
        bannerNotificationList.push(notification);
      } else if (notification.target === TargetComponent.UnifiedQueue) {
        nQNotificationList.push(notification);
      } else if (notification.target === TargetComponent.FloatingBanner) {
        floatingBannerNotification = notification;
      }
    });

    return { bannerNotificationList, nQNotificationList, floatingBannerNotification };
  }

  /**
   * Will be called by Unified Notification Queue
   */
  dismissNQAll() {
    this.state.nQNotificationList.forEach((notification) => {
      if (notification.dismissAction) {
        notification.dismissAction(notification);
      }
    });
    this.setState({
      nQNotificationList: [],
    });
  }

  buildDimissAction(notification: INotification) {
    if (DismissMode.never) {
      const originDismissAction = notification.dismissAction;
      notification.dismissAction = () => {
        this.deleteNotificationFromList(notification.id);
        if (originDismissAction) {
          originDismissAction(notification);
        }
      };
    }
    switch (notification.dismissMode) {
      case DismissMode.session:
      case DismissMode.custom:
      case DismissMode.once:
      case DismissMode.permanent: {
        const originDismissAction = notification.dismissAction;
        notification.dismissAction = () => {
          this.deleteNotificationFromList(notification.id);
          if (originDismissAction) {
            originDismissAction(notification);
          }
        };
        break;
      }
      case DismissMode.never:
      default:
        break;
    }
  }

  deleteNotificationFromList(id: string) {
    const bannerNotificationList = this.state.bannerNotificationList.filter((n) => n.id !== id);

    const nQNotificationList = this.state.nQNotificationList.filter((n) => n.id !== id);

    this.setState({
      bannerNotificationList,
      nQNotificationList,
    });
  }

  // used by notification api to remove all from external
  removeAllTopBanners() {
    this.setState({
      bannerNotificationList: [],
    });
  }

  // used by notification api to add notification
  add(notification: INotification) {
    if (notification.target === TargetComponent.TopBanner) {
      const bannerNotificationList = this.state.bannerNotificationList.filter((n) => n.id !== notification.id);

      bannerNotificationList.push(notification);
      this.setState({ bannerNotificationList });
    } else if (notification.target === TargetComponent.UnifiedQueue) {
      const nQNotificationList = this.state.nQNotificationList.filter((n) => n.id !== notification.id);

      nQNotificationList.unshift(notification); // insert at the start of array
      this.setState({ nQNotificationList });
    } else if (notification.target === TargetComponent.FloatingBanner) {
      if (notification.severity === NotificationLevel.Info || notification.severity === NotificationLevel.Confirmation) {
        this.setFloatingBannerNotification(notification.message, NotificationLevel.Info);
      } else {
        this.setFloatingBannerNotification(notification.message, NotificationLevel.Warning);
      }

      return this.floatingBannerId;
    }

    return notification.id;
  }

  // used by notification api
  setFloatingBannerNotification(message: string, severity: NotificationLevel.Warning | NotificationLevel.Info, extraOptions?: ExtraOptions) {
    const notification: INotification = {
      message,
      title: '',
      severity,
      id: this.floatingBannerId,
      target: TargetComponent.FloatingBanner,
      condition: true,
      extraOptions,
    };
    this.setState({
      floatingBannerNotification: notification,
    });

    return this.floatingBannerId;
  }

  // used by notification api to dismiss notification
  dismiss(id: string) {
    if (id === this.floatingBannerId) {
      this.state.floatingBannerNotification?.extraOptions?.onDismiss?.('code', this);
      this.setState({
        floatingBannerNotification: undefined,
      });
    } else {
      this.deleteNotificationFromList(id);
    }
  }
}

export const notificationStore = new NotificationStore();

// Add to window for easier debugging
(window as any).notificationStore = notificationStore;
