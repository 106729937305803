/* istanbul ignore file */
import React from 'react';
import { withStateStore } from '@bingads-webui/with-state-store';
import { TopBanner } from './top-banner';
import { notificationStore } from '../notification-store';
import { INotification, TopBannerNotification } from '../types';

interface ITopBannerLayoutProps {
  changeLayout: Function;
  mode: any;
  bannerNotificationList: INotification[];
  Wrapper: React.ComponentClass<any>;
  afterChangeLayout?: Function;
}

class TopBannerLayoutInternal extends React.PureComponent<ITopBannerLayoutProps> {
  componentDidMount() {
    this.decideShowBanner();
  }

  componentDidUpdate() {
    this.decideShowBanner();
  }

  // eslint-disable-next-line react/sort-comp
  render() {
    const notifications: TopBannerNotification[] = this.props.bannerNotificationList.map((notification) => ({
      notificationId: notification.id,
      message: notification.message,
      actionList: notification.actionList,
    }));
    return <TopBanner notifications={notifications} Wrapper={this.props.Wrapper} />;
  }

  showTopBanner() {
    this.props.changeLayout({ topBanner: { mode: 'expanded' } }, false, false, this.props.afterChangeLayout);
  }

  hideTopBanner() {
    this.props.changeLayout({ topBanner: { mode: 'collapsed' } });
  }

  decideShowBanner() {
    // If top banner is in hidden mode, we will never show it.
    const isHiddenMode = this.props.mode === 'hidden';
    if (isHiddenMode) {
      return;
    }

    if (this.props.bannerNotificationList.length !== 0) {
      this.showTopBanner();
    } else {
      this.hideTopBanner();
    }
  }
}

export const getTopBannerLayoutWithStore = (customizedStore: any, Wrapper?: React.ComponentClass<any>) => withStateStore(TopBannerLayoutInternal, customizedStore, (store: any) => ({
  bannerNotificationList: store.state.bannerNotificationList,
  Wrapper,
}));

export const TopBannerLayout = getTopBannerLayoutWithStore(notificationStore);

export const TopBannerLayoutBase = TopBannerLayoutInternal;
