import _ from 'underscore';

export const AccountFinancialStatus = Object.freeze({
  Active: 31,
  Inactive: 32,
  Proposed: 34,
  PendingCreditCheck: 35,
  NoHold: 36,
  SoldToOnly: 37,
  CreditWarning: 38,
  Hold: 39,
  WriteOff: 223,
  TaxOnHold: 253,
  UserHold: 254,
});

export const AccountAssociationType = Object.freeze({
  Primary: 1,
  Backup: 2,
  None: 3,
});

export const AccountFinancialStatusBit = Object.freeze({
  CreditHold: 1,
  CLEHold: 2,
  PIHold: 4,
  ManualHold: 8,
});

export const AccountLifecycleStatus = Object.freeze({
  Blocked: 28,
  Pending: 29,
  Draft: 30,
  Active: 31,
  Inactive: 32,
  Deleted: 33, // deprecated
  Pause: 42,
  Suspended: 43,
});

export const AccountInactiveReasonCode = Object.freeze({
  NotSet: 0,
  PausedDueToSystemIssue: 1,
  PausedZeroBalance: 2,
  PausedNoValidIO: 3,
  Processing: 4,
  PendingPaymentMethod: 5,
  PendingNewCustomer: 6,
  Inactive: 7,
  Hold: 8,
  Deleted: 9,
});

export const AccountInactiveReasonValue = _.mapObject(AccountInactiveReasonCode, (value, key) => key);

export const AccountInactiveCauseCode = Object.freeze({
  NotSet: 0,
  PausedDueToSystemIssue: 1,
  PausedZeroBalance: 2,
  PausedNoValidIO: 3,
  Processing: 4,
  PendingPaymentMethod: 5,
  PendingNewCustomer: 6,
  Inactive: 7,
  Hold: 8,
  AccountLifecycleStatusSuspended: 9,
  AccountLifecycleStatusDraft: 10,
  AccountLifecycleStatusDeleted: 11,
  AccountLifecycleStatusUserPause: 12,
  AccountFinancialStatusProposed: 13,
  AccountFinancialStatusWriteOff: 14,
  AccountFinancialStatusTaxOnHold: 15,
  AccountFinancialStatusUserHold: 16,
  AccountFinancialStatusPendingCreditCheck: 17,
  AccountFinancialStatusSoldToOnly: 18,
  AdvertiserCustomerLifecycleStatusDraft: 19,
  AdvertiserCustomerLifecycleStatusInactive: 20,
  AdvertiserCustomerLifecycleStatusDeleted: 21,
  AdvertiserCustomerFraudStatusFraud: 22,
  BillToCustomerLifecycleStatusDraft: 23,
  BillToCustomerLifecycleStatusInactive: 24,
  BillToCustomerLifecycleStatusDeleted: 25,
  BillToCustomerFinancialStatusProposalsOnly: 26,
  BillToCustomerFinancialStatusSoldToOnly: 27,
  BillToCustomerFraudStatusFraud: 28,
  AccountLifecycleStatusNotSet: 29,
  AccountFinancialStatusNotSet: 30,
  AdvertiserCustomerLifecycleStatusNotSet: 31,
  AdvertiserCustomerFraudStatusNotSet: 32,
  BillToCustomerLifecycleStatusNotSet: 33,
  BillToCustomerFinancialStatusNotSet: 34,
  BillToCustomerFraudStatusNotSet: 35,
  UserDeleted: 36,
  SystemNoSpendDeactivated: 37,
  AccountVerificationHold: 39,
});

export const AccountInactiveCauseValue = _.mapObject(AccountInactiveCauseCode, (value, key) => key);

export const AccountActivityStatus = Object.freeze({
  Enabled: 'Enabled',
  Deleted: 'Deleted',
  Errors: 'Errors',
});

export const AccountMode = Object.freeze({
  Legacy: null,
  Expert: 0,
  Smart: 1,
  UnifiedSmart: 2,
  UnifiedExpert: 3,
});

export const AccountEditMode = Object.freeze({
  FixIndiaTax: 1,
});

export const AccountCustomerRelationLifecycleStatus = Object.freeze({
  Pending: 160,
  Active: 168,
  Deleted: 169,
});

export const AccountAutoTaggingType = Object.freeze({
  Off: 'Off',
  CustomerOverride: 'CustomerOverride',
  BingAdsOverride: 'BingAdsOverride',
});

export const AccountAutoTaggingTypeId = Object.freeze({
  Off: 0,
  CustomerOverride: 1,
  BingAdsOverride: 2,
});

export const AccountFeaturePilots = Object.freeze({
  SingleAccountIdentityVerification: 888,
});

export const AccountCommentsMaxLength = 1000;
