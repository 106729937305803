import React from 'react';
import classNames from 'classnames';
import { withDefaultStyles } from '@bingads-webui-react/with-default-styles';
import { buttonStyle } from './styles';

interface IButtonWrapperProps {
  message: string,
  classes: any,
}

// This is to split server-side returned <a> element in message and convert to button style
class UnstyledButtonWrapper extends React.PureComponent<IButtonWrapperProps> {
  render() {
    const { classes } = this.props;
    const doc = (new DOMParser()).parseFromString(this.props.message, 'text/html');
    const aElements = doc.body.getElementsByTagName('a');
    if (aElements.length <= 0) {
      return (<span className={classNames('notification-center-top-banner-message', classes.content)} title={doc.body.innerText} dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />);
    }

    const aTags = Array.prototype.slice.call(aElements).map((el) => {
      const aTag = doc.body.removeChild(el);
      aTag.classList.add(classes.button);
      if (!aTag.style.color) {
        aTag.style.color = 'black';
      }
      return aTag.outerHTML;
    }).join('');
    return (
      <>
        <span className={classNames('notification-center-top-banner-message', classes.content)}>{doc.body.innerHTML}</span>
        <span className={classNames('notification-center-top-banner-action', classes.calloutTargetContainer)} dangerouslySetInnerHTML={{ __html: aTags }} />
      </>
    );
  }
}

export const ButtonWrapper = withDefaultStyles(UnstyledButtonWrapper, buttonStyle);
