import $ from 'jquery';
import _ from 'underscore';

/**
 * This function is the same of the one in number-utils
 * We also export it in string-util because it can be applied with both string or number passed in parameter
 */
export { isValidWholeNumber } from './number-utils';

/**
 * Return true if the string in parameter is a string not empty after trim
 * @param {string} str The string to validate.
 * @returns {boolean} True if the string in parameter is a string not empty after trim
 */
export const isStringNotBlank = str => _.isString(str) && !_.isEmpty(str.trim());

/**
 * Replaces Values of the form {#} with the corresponding argument passed in after format.
 * If # arguments weren't passed in, then {#} is left in the formatted string
 * @param {string} str The string to format.
 * @returns {string} Formats the string with the given arguments
 */
export const format = (str, ...args) => str.replace(/{(\d+)}/g, (match, number) => (typeof args[number] === 'undefined' ? match : args[number]));

/**
 * Encodes an text into an HTML string
 * @param {string} str The string to encode.
 * @returns {string} An HTML-encoded version of str
 */
export const encode = str => $('<p>').text(str).html();

/**
 * Decodes an HTML string into text
 * @param {string} str The string to decode.
 * @returns {string} A plaintext version of the HTML-encoded str
 */
export const decode = str => $('<p>').html(str).text();

/**
 * Parse a string to bool
 * @param {string|bool} str The string to parse.
 * @returns {bool} The bool value of string
 */
export const parseStringToBool = (str) => {
  if (_.isString(str)) {
    return str.toLowerCase() === 'true';
  }
  if (_.isBoolean(str)) {
    return str;
  }
  return false;
};

/**
 * Parse a base64 array to string
 * @param {array} arr The array of base64 bytes.
 * @returns {string} The string translate from base64
 */
export const parseBase64ToString = (arr) => {
  if (arr && arr.length) {
    const bytes = new Uint8Array(arr);
    return btoa(_.map(bytes, byte => String.fromCharCode(byte)).join(''));
  }

  return null;
};

/**
 * Test a string using a given regexString
 * @param {string} regexStr The given regexString
 * @param {string} str The string to test
 * @returns {bool} Indicate whether the string fulfils the regexString
 */
export const regexTest = (regexStr, str) => {
  const reg = new RegExp(regexStr);
  return reg.test(str);
};
