export const TokenVerificationScenario = Object.freeze({
  AIV: 1,
  FCA: 2,
  CN: 3,
});

// China qualification type
export const CNQualificationType = Object.freeze({
  Industry: 0,
  Supplementary: 1,
});

// China qualification verification status
export const CNQualificationStatus = Object.freeze({
  Failed: 71,
  ActionRequired: 72,
  NotVerified: 73,
  Pending: 74,
  Verified: 75,
  LicenseExpired: 76,
});

export const CNQualificationUnableCreateReason = Object.freeze({
  InternalUser: 1,
  UserRoleMismatch: 3,
  InitializeInProgress: 5,
});

export const OrderedCNQualificationUnableCreateReason = [
  CNQualificationUnableCreateReason.InitializeInProgress,
  CNQualificationUnableCreateReason.InternalUser,
  CNQualificationUnableCreateReason.UserRoleMismatch,
];
