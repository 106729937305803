
import _ from 'underscore';
import { getMessageForErrorCodeFn } from './get-message-for-code';
import { odataErrorHandler, ccErrorHandler } from './error-handlers';
import { ERROR_MESSAGES } from './error-messages';

// server error code is not unique for now, so we temporarily depend on server error messages
// when MT fixed this error code issue, we should not use server error message any more
export const getErrorFromRaw = (
  i18n, instrumentation, errorMap,
  rawError, entity, field, useServerErrorMessage = false
) => {
  const errorMapper = getMessageForErrorCodeFn(i18n, instrumentation, errorMap);
  if (_.isObject(rawError)) {
    if (_.isNumber(rawError.ResultType)) {
      return ccErrorHandler(rawError, entity, field, errorMapper);
    }
  }
  return odataErrorHandler(rawError, entity, field, errorMapper, useServerErrorMessage);
};

export const getErrorFromRawFn = (i18n, instrumentation, errorMap = ERROR_MESSAGES) => (
  (...args) => getErrorFromRaw(i18n, instrumentation, errorMap, ...args));
