import _ from 'underscore';
import Promise from 'bluebird';

const preloadJsDataCache = ({ jsData, cid, aid }) => {
  const preloadList = [
    ...(cid ? [jsData.Customer.find(cid)] : []),
    ...(aid ? [jsData.Account.find(aid)] : []),
  ];

  return Promise.all(preloadList);
};

/**
 * Description of the function
 * @name PreloadCallback
 * @function
 * @param {object} options - options
 * @param {object} options.jsData - jsData object
 * @param {number|string} [options.cid] - customer id
 * @param {number|string} [options.aid] - account id
*/

/**
 * Clear the specified entities' jsData cache, then immediately preload some entities.
 * This function is designed for clearing dirty cache after entity update.
 * @function
 * @param {object} options - options
 * @param {object} options.jsData - jsData object
 * @param {number|string} [options.cid] - customer id to preload
 * @param {number|string} [options.aid] - account id to preload
 * @param {string[]} [options.entitiesToClear=[]] - the specified entities to clear, for example ['Account', 'Customer']. empty means clear all entities.
 * @param {PreloadCallback} [options.preloadCallback=null] - the custom preload callback. If not specified, Account and Customer will be preloaded.
 * @returns {Promise} - The preload Promise, it will resolve { ready: true }
 */
export const refreshJsDataCache = ({
  jsData,
  cid,
  aid,
  entitiesToClear = [],
  preloadCallback = null,
}) => {
  if (_.isEmpty(entitiesToClear)) {
    // Clear the whole JsData store cache
    _.each(jsData, entityObj => entityObj && entityObj.ejectAll());
  } else {
    // Clear the specified entities' cache as required
    _.each(entitiesToClear, entity => jsData[entity] && jsData[entity].ejectAll());
  }

  // by default preload customer data and account data
  const preloadCallbackFunc = preloadCallback || preloadJsDataCache;

  return preloadCallbackFunc({ jsData, cid, aid }).then(() => ({ ready: true }));
};
