import { useContext } from 'react';
import { PreferenceService } from '@bingads-webui/preference-service';
import { PermissionsContext } from '@bingads-webui-react/hoc-with-permissions';
import { SWITCH_BACK_NAV_REFRESH_KEY } from './constants';

export const isNavigationRefreshOn = (permissions) => {
  const preferenceService = PreferenceService;
  const switchBackNavRefreshPreference = preferenceService.findByNameAtUserLevel(SWITCH_BACK_NAV_REFRESH_KEY);

  const switchedBack = switchBackNavRefreshPreference && switchBackNavRefreshPreference.Data;
  // Switch back cutoff date is August 1, 2024
  const isPastSwitchBackDate = new Date() > new Date(2024, 7, 1);

  return permissions.dynamic && permissions.dynamic.NavigationRefresh && (!switchedBack || isPastSwitchBackDate);
};

export const useNavigationRefreshPreference = () => {
  const permissions = useContext(PermissionsContext);

  return {
    isNavigationRefreshOn: isNavigationRefreshOn(permissions),
  };
};
