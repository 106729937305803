import { Validation } from '@bingads-webui/validation';
import _ from 'underscore';
import { BaseFactory } from './rules-factory/base-factory';
import { EnumFactory } from './rules-factory/enum-factory';
import { NumberFactory } from './rules-factory/number-factory';
import { StringFactory } from './rules-factory/string-factory';
import { ArrayFactory } from './rules-factory/array-factory';

/**
 * Find validation rules factory by field type
 * @param {Object} prop - Schema field properties
 * @param {I18n} i18n - i18n for localization
 * @returns {Object} - returns factory for validation rules
 */
function getRuleFactoryByType(prop, i18n) {
  switch (prop.type) {
    case 'integer':
      return new NumberFactory({ prop: _.defaults({ decimalLength: 0 }, prop), i18n });
    case 'number':
      return new NumberFactory({ prop: _.defaults({ decimalLength: 2 }, prop), i18n });
    case 'boolean':
      return new EnumFactory({ prop: { enum: [true, false] }, i18n });
    case 'string':
      return prop.enum ? new EnumFactory({ prop, i18n }) : new StringFactory({ prop, i18n });
    case 'array':
      return new ArrayFactory({ prop, i18n });
    default:
      return new BaseFactory({ prop, i18n });
  }
}

/**
 * Create validation rules by field type
 * @param {Object} prop - Schema field properties
 * @param {I18n} i18n - i18n for localization
 * @returns {Object} - returns validation rules
 */
export function createValidation(prop, i18n) {
  const factory = getRuleFactoryByType(prop, i18n);

  const rules = factory.create();

  if (prop.required) {
    // allowspace means whether value with pure spaces is treated as valid
    const allowSpace = _.result(prop, 'allowSpace', false);

    rules.required = Validation.required(allowSpace, i18n);
  }

  return rules;
}
