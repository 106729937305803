/* eslint-disable react/forbid-prop-types */
import React, { useMemo, useContext } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import PropTypes from 'prop-types';
import { getDisplayName } from '@bingads-webui-react/hoc-utils';
import { InstrumentationContext } from '@bingads-webui-react/hoc-with-instrumentation';

export const ScenarioContext = React.createContext();

export const Scenario = (props) => {
  const instrumentation = useContext(InstrumentationContext);
  const context = useContext(ScenarioContext);

  const scenarioInstance = useMemo(() => {
    if (props.name) {
      if (context && context.value === props.name) {
        // if parent scenario and current one share the same name
        // reuse parent scenario instance
        return context;
      } else if (instrumentation && instrumentation.createScenario) {
        // otherwise create a new scenario instance and pass in parent scenario instance
        // which could be undefined or null
        return instrumentation.createScenario({
          scenarioName: props.name,
          parentScenario: context,
        });
      }
    }
    return undefined;
  }, [context, instrumentation, props.name]);

  return (
    <ScenarioContext.Provider value={scenarioInstance}>
      {props.children}
    </ScenarioContext.Provider>
  );
};

Scenario.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  name: PropTypes.string.isRequired,
};

export const withScenarioContext = (WrappedComponent) => {
  const HoC = (props) => {
    const context = useContext(ScenarioContext);

    return (<WrappedComponent
      {...props}
      scenario={context}
    />);
  };

  HoC.displayName = `withScenarioContext(${getDisplayName(WrappedComponent)})`;

  return hoistNonReactStatics(HoC, WrappedComponent);
};
