import $ from 'jquery';
import _ from 'underscore';

const defaultOptions = {
  precision: 2,
  needPercentageSymbol: true,
  needSpace: false,
};

/**
 * Return true if the string in parameter is a valid integer greater or equal to Zero
 * @param {string|Number} numberValue The number value to test, can be a string or a number type.
 * @returns {boolean} True if the string in parameter is a string greater or equal to 0
 */
export const isValidWholeNumber = (numberValue) => {
  const number = Math.floor(Number(numberValue));

  return String(number) === String(numberValue) && number >= 0;
};

/**
 * Return input source in percentage format
 * @param {number} source The input source. Need to be number, otherwise will return undefined.
 * @param {object} [options] Options.
 * @param {int} [options.precision] How many digits of decimals want to keep. Default is 2.
 * @param {bool} [options.needPercentageSymbol] Whether need a percentage symbol '%'. Default is true.
 * @param {bool} [options.needSpace] Wehther need a spance between the value and percentage symbol. Defualt is false.
 * @returns {string} Result.
 */
export const getPercentage = (source, options) => {
  const { precision, needPercentageSymbol, needSpace } = _.extend({}, defaultOptions, options);

  if (!$.isNumeric(source)) {
    return null;
  }

  return `${Number(source * 100).toFixed(precision)}${needSpace ? ' ' : ''}${needPercentageSymbol ? '%' : ''}`;
};

/**
 * Return the devided result by handling the divisor or dividend is 0 case.
 * @param {number} divisor The divisor.
 * @param {number} dividend The dividend.
 * @returns {number} Divided result.
 */
export const dividePossibleZero = (divisor, dividend) => (dividend === 0 ? 0 : divisor / dividend);

/**
 * Determines whether one or more bit fields are set in the current instance.
 * Need to disable eslint for bitwise operation, that is considered as too rarely used to be expected in js code: https://eslint.org/docs/rules/no-bitwise
 * @param {number} number The current instance value number. Need to be a numeric value instance of number or string
 * @param {number} flag A value representing the bit field that should be present in the number
 * @returns {bool} true if the bit field or bit fields that are set in flag are also set in the current instance; otherwise, false
 */
export const hasFlag = (number, flag) => isValidWholeNumber(number) && (Number(number) & flag) === flag; // eslint-disable-line no-bitwise

/**
 * Returns a new number with one bit fields added.
 * @param {number} number The current instance value number. Need to be a numeric value instance of number or string
 * @param {number} flag A value representing the bit field that should be added from the number
 * @returns {number} a new number with one bit fields added.
 */
export const addFlag = (number, flag) => {
  const num = isValidWholeNumber(number) ? Number(number) : 0;
  return (num | flag); // eslint-disable-line no-bitwise
};

/**
 * Returns a new number with one bit fields removed if exists, or the same number if flag is not present. Returns null if last flag is removed.
 * @param {number} number The current instance value number. Need to be a numeric value instance of number or string
 * @param {number} flag A value representing the bit field that should be removed from the number
 * @returns {number} a new number with one bit fields removed if exists, or the same number if flag is not present. Returns null if last flag is removed.
 */
export const removeFlag = (number, flag) => {
  // If the number doesn't have the flag we should not try to XOR otherwise result will be 0 instead of the value without flag
  if (hasFlag(number, flag)) {
    return Number(number) ^ flag || null; // eslint-disable-line no-bitwise
  }
  return number;
};
