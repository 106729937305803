export const EnforcementActionType = Object.freeze({
  Default: 0,
  SuspendAccountCreation: 1,
  ReadOnly: 2,
});

export const CustomerFraudStatus = Object.freeze({
  NonFraud: 6,
  FraudPending: 7,
  Fraud: 8,
});
