/* istanbul ignore file */
import _ from 'underscore';
import 'jquery';
import IWindow from '@bingads-webui-clientcenter/window-interface';

import { getTimestampMillis } from './date-utils';

export const ScenarioNames = {
  agencyRequestsPage: 'AgencyRequestsPage',
  settingsPage: {
    content: 'SettingsContent',
    userInfo: 'SettingsUserInfoContent',
    emailNotification: 'SettingsEmailNotificationContent',
    billing: 'SettingsBillingContent',
    emailNotificationCategory: 'SettingsEmailNotificationCategoryContent',
    emailNotificationCategorySave: 'SettingsEmailNotificationCategorySave',
    emailNotificationCategoryAccountFetch: 'emailNotificationCategoryAccountFetch',
    emailNotificationCategoryAccountSelectorRender: 'emailNotificationCategoryAccountSelectorRender',
    emailNotificationCategoryAccountSelectAll: 'emailNotificationCategoryAccountSelectAll',
    emailNotificationCategoryAccountFilter: 'emailNotificationCategoryAccountFilter',
    emailNotificationUserSelectorRender: 'SettingsEmailNotificationUserSelectorRender',
  },
};

/**
 * Mark the page load time when page is loaded.
 * Log the page duration when page is navigated
 * @function
 * @param {logging} logging to log
 * @param {pageReadyMark} pageReadyMark which can be used for performance mark
  * @returns {void} Nothing
 */
export const logPageLoadAndNavigationDuration = ({ logging, pageReadyMark }) => {
  if (IWindow.pageLoadDurationRecorded && IWindow.pageNavigationStartTime && IWindow.pageNavigationRecorded && logging && logging.pageRoutePageLoadDuration) {
    logging.pageRoutePageLoadDuration(IWindow.location.pathname, getTimestampMillis() - IWindow.pageNavigationStartTime, IWindow.pageNavigationRecorded[pageReadyMark]);
    IWindow.pageNavigationRecorded[pageReadyMark] = true;
  }

  if (!IWindow.pageLoadDurationRecorded && IWindow.location.pathname === IWindow.pageLandingPath) {
    IWindow.performance.mark(`${pageReadyMark}LandingPageReady`);
    IWindow.pageLoadDurationRecorded = true;
    IWindow.pageNavigationRecorded = _.extend(IWindow.pageNavigationRecorded, { [pageReadyMark]: true });
  }
};
