import {
  Input as InputPrimitive,
  Checkbox as CheckboxPrimitive,
  Select as SelectPrimitive,
  RadioGroup as RadioGroupPrimitive,
  CheckboxGroup as CheckboxGroupPrimitive,
  TextArea as TextAreaPrimitive,
} from '@bingads-webui-react/primitive';

import {
  withValidation,
  ValidationGroup,
  withError,
  CheckedAdapter,
  CheckedItemAdapter,
} from '@bingads-webui-react/validation';

export const InputLite = withValidation(InputPrimitive);
export const Input = withError(InputLite);

export const TextAreaLite = withValidation(TextAreaPrimitive);
export const TextArea = withError(TextAreaLite);

export const CheckboxLite = withValidation(CheckboxPrimitive, CheckedItemAdapter);
export const Checkbox = withError(CheckboxLite);

export const SelectLite = withValidation(SelectPrimitive);
export const Select = withError(SelectLite);

export const RadioGroupLite = withValidation(RadioGroupPrimitive, CheckedAdapter);
export const RadioGroup = withError(RadioGroupLite);

export const CheckboxGroupLite = withValidation(CheckboxGroupPrimitive, CheckedAdapter);
export const CheckboxGroup = withError(CheckboxGroupLite);

export { ValidationGroup };
export const ValidationGroupWithErrors = withError(ValidationGroup);
