import { Validation } from '@bingads-webui/validation';
import _ from 'underscore';
import { BaseFactory } from './base-factory';

/**
 * Factory for number validation rules
 * @param {Object} options - input for schema property bag and i18n
 */
export class NumberFactory extends BaseFactory {
  /**
   * Create number rules set from schema property
   * @returns {Object} - number validation rules set
   */
  create() {
    const rules = {
      type: Validation.number.type(this.i18n).set({ nullable: this.prop.nullable }),
      decimalLength: Validation.number.size(undefined, this.prop.decimalLength, this.i18n)
        .set({ nullable: this.prop.nullable }),
    };

    if (_.isFinite(this.prop.maximum) || _.isFinite(this.prop.minimum)
      || this.prop.isPercentValue) {
      const minimum = _.isFinite(this.prop.minimum) ?
        this.prop.minimum : (this.prop.isPercentValue && 0);
      const maximum = _.isFinite(this.prop.maximum) ?
        this.prop.maximum : (this.prop.isPercentValue && 100);

      rules.range = Validation.number.range(minimum, maximum, this.i18n)
        .set({ nullable: this.prop.nullable });
    }

    return rules;
  }
}
