import _ from 'underscore';
import 'jquery';
import { parseCivilDate, formatCivilDate } from '@bingads-webui-universal/temporal-utitlities';
import { withoutTime } from '@bingads-webui-universal/primitive-utilities';
import IWindow from '@bingads-webui-clientcenter/window-interface';

import { MillisecondsPerDay, StandardDateFormat, FrequencyMonthCountMap } from './constants/date';

/**
 * ATTENTION: You can directly using the i18n.getToday({ timeZone: 'America/Los_Angeles' }) method.
 * This is just a reminder in case you don't know or forget that i18n.getToday.
 * Returns the current date (without time) now of PST timezone.
 * @function
 * @returns {date} the current PST date now. Failed will return null.
 */
export const getPSTDateNow = ({ i18n }) => {
  if (_.isFunction(i18n.getToday)) {
    const todayObj = i18n.getToday({ timeZone: 'America/Los_Angeles' });
    return new Date(todayObj.year, todayObj.month - 1, todayObj.day);
  }
  /* istanbul ignore next */
  return null;
};

/**
 * Returns the numbers of different days from the fromDate minus the endDate
 * Result possitive means fromDate is later then toDate, negative means fromDate is eariler than toDate
 * @function
 * @param {date} fromDate the date of from
 * @param {date} toDate the date of to
 * @returns {number} number of days, if input is invalid will return null.
 */
export const getDateDiffInDays = ({ fromDate, toDate }) =>
  (_.isDate(fromDate) && _.isDate(toDate) ? Math.ceil((fromDate - toDate) / MillisecondsPerDay) : null);

/**
 * Returns the date after a certain days from provided fromDate
 * @function
 * @param {date} fromDate the date of from
 * @param {number} offset the offset days, if offsetis larger than 0, it means getting date after, vice versa
 * @returns {date} the result date after setting offset, if input is invalid will return null.
 */
export const addDays = ({ fromDate, offset }) =>
  (_.isDate(fromDate) && _.isNumber(offset) ? new Date(fromDate.getTime() + (offset * MillisecondsPerDay)) : /* istanbul ignore next */ null);

/**
 * @typedef {object} NormalizedDateRange - Normalized date range
 * @property {string} start - start date string
 * @property {string} end - end date string
 * @property {number} rangeId - date range Id
 */

/**
 * @typedef {object} ParsedDateRange - Parsed date range
 * @property {object} range - date range
 * @property {CivilDate} range.start - start dat
 * @property {CivilDate} range.end - end date
 * @property {number} rangeId - date range Id
 */

/**
 * Normalize date range
 * @function
 * @param {object} dateRangeObject - parsed date range object with i18n and date format pattern
 * @param {object} dateRangeObject.i18n - i18n model
 * @param {object} dateRangeObject.range - date range object
 * @param {CivilDate} dateRangeObject.range.start - start date
 * @param {CivilDate} dateRangeObject.range.end - end date
 * @param {number} dateRangeObject.rangeId - range Id
 * @returns {NormalizedDateRange} - normalized date range
 */
export const normalizeDateRange = ({
  i18n,
  range: {
    start, end,
  },
  rangeId,
  dateFormat = StandardDateFormat,
}) => ({
  start: formatCivilDate({ i18n, date: start, dateFormat }),
  end: formatCivilDate({ i18n, date: end, dateFormat }),
  rangeId,
});

/**
 * Parse normalized date range.
 * @function
 * @param {object} dateRangeObject - date range object with i18n and date format pattern
 * @param {object} dateRangeObject.i18n - i18n model
 * @param {string} dateRangeObject.start - start date
 * @param {string} dateRangeObject.end - end date
 * @param {number} dateRangeObject.rangeId - range Id
 * @param {string} dateRangeObject.dateFormat - date format pattern
 * @returns {ParsedDateRange} - parsed date range object
 */
export const parseNormalizedDateRange = ({
  i18n,
  start,
  end,
  rangeId,
  dateFormat = StandardDateFormat,
}) => {
  const startCivilDate = parseCivilDate({ i18n, dateString: start, dateFormat });
  const endCivilDate = parseCivilDate({ i18n, dateString: end, dateFormat });

  return (startCivilDate && endCivilDate ? ({
    range: {
      start: startCivilDate,
      end: endCivilDate,
    },
    rangeId,
  }) : null);
};

/**
 * Get end date by start date & frequency & occurences.
 * @function
 * @param {date} startDate - start date
 * @param {string} frequency - frequency
 * @param {number} occurrences - occurrences
 * @returns {date} - end date
 */
export const getNFrequencyEndDate = (
  startDate,
  frequency,
  occurrences
) => {
  if (!_.isDate(startDate) || !FrequencyMonthCountMap[frequency] || !_.isNumber(occurrences)) return null;

  const calculateOccurrences = parseInt(occurrences, 10) > 0 ? parseInt(occurrences, 10) : 1;

  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const startDay = startDate.getDate();

  const endRawMonth = (calculateOccurrences * FrequencyMonthCountMap[frequency]) + startMonth;

  const endYear = startYear + (endRawMonth / 12);
  const endMonth = endRawMonth % 12;
  const monthDayCount = new Date(endYear, endMonth + 1, 0/* the 0th day means last day in last month */).getDate();

  // The logic in MT
  // https://msasg.visualstudio.com/DefaultCollection/Bing_Ads/_git/AnB?path=/private/ClientCenter/MT/Source/ClientCenter/EO/BillingMT/InsertionOrder/RecurringInsertionOrderAddEO.cs&version=GBmaster&line=82&lineEnd=93&lineStartColumn=17&lineEndColumn=43&lineStyle=plain&_a=contents
  // There are some by design but strange logic:
  // Start Date: 08/30/2022 -> End Date: 09/29/2022
  // Start Date: 08/31/2022 -> End Date: 09/29/2022
  // Start Date: 01/31/2022 -> End Date: 02/27/2022
  // Start Date: 01/31/2020 -> End Date: 02/28/2020
  // So the `monthDayCount` should minus 1
  const endDay = Math.min(startDay - 1, monthDayCount - 1);

  const endDate = new Date(endYear, endMonth, endDay);

  return _.isNaN(Date.parse(endDate)) ? null : endDate;
};

/**
 * Performs an optimized deep comparison between the two dates, to determine if they should be considered equal.
 * @function
 * @param {date} date1 - date
 * @param {date} date2 - date
 * @returns {bool} - is equal or not
 */
export const isDateWithoutTimeEqual = (date1, date2) => _.isDate(date1) && _.isDate(date2) &&
  (_.isEqual(withoutTime(date1), withoutTime(date2)) || (_.isNaN(date1.getTime()) && _.isNaN(date2.getTime())));

/**
 * Get current timestamp.
 * @function
 * @returns {number} - timestamp
 */
export const getTimestampMillis = () => {
  /* istanbul ignore next */
  if (typeof IWindow.performance !== 'undefined' && IWindow.performance.now) {
    return IWindow.performance.now();
  }
  /* istanbul ignore next */
  return Date.now();
};
