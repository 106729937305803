import _ from 'underscore';
import { AddressUtils } from '@bingads-webui-clientcenter/address';

const { isValid: isValidAddress, isValidBusinessName } = AddressUtils;

/**
 * @param {object} accountBusinessAddress The account business address object.
 * @returns {boolean} Returns true if given account business address object is valid.
 */
export const isValidABL = accountBusinessAddress => isValidBusinessName(accountBusinessAddress) && isValidAddress(accountBusinessAddress);

/**
 * Verify if the business name passed in matches with the individual information of the user.
 * A match is considered valid independently of the order of firstname and lastname passed in, spaces and upper/lower case entered
 * @param {object} businessNameData The UI version of the businessName data, containing the business name (property starting with lowerCase)
 * @param {object} individualInfoData An object representing information of an individual user like firstName and lastName
 * @returns {boolean} Returns true if the businessName matches the fullName.
 */
export const isBusinessNameMatchFullName = (businessNameData, individualInfoData) => {
  const { businessName } = businessNameData || {};
  const { firstName, lastName } = individualInfoData || {};

  const trimValue = (value) => {
    if (!_.isString(value) || _.isEmpty(value)) {
      return null;
    }
    return value.replace(/\s/g, '').toLowerCase();
  };

  const businessNameTrimmed = trimValue(businessName);
  const firstNameTrimmed = trimValue(firstName);
  const lastNameTrimmed = trimValue(lastName);

  return _.isEqual(businessNameTrimmed, `${firstNameTrimmed}${lastNameTrimmed}`) || _.isEqual(businessNameTrimmed, `${lastNameTrimmed}${firstNameTrimmed}`);
};

/**
 * Returns a copy of the given account business address object with additional utility methods.
 * @param {object} accountBusinessAddress The account business address object to be extended with additional utility methods.
 * @returns {object} Return a copy of the extended account business address object.
 */
export const extendABL = (accountBusinessAddress = {}) => _.defaults({}, accountBusinessAddress, {
  isValidABL: () => isValidABL(accountBusinessAddress),
  isValidBusinessName: () => isValidBusinessName(accountBusinessAddress),
});

/**
 * Returns a normalized copy of the given account business address object.
 * @param {object} address The account business address object.
 * @returns {object} Return a copy of the extended account business address object.
 */
export const normalizeAddress = (address) => {
  const normalizedKeys = ['BusinessName', 'City', 'Country', 'Line1', 'Line2', 'Line3', 'Line4', 'PostalCode', 'StateOrProvince'];
  const normalizedAddress = _.pick(address, normalizedKeys);
  _.each(normalizedKeys, (key) => {
    const value = normalizedAddress[key];
    if (_.isEmpty(value)) {
      normalizedAddress[key] = null;
    } else {
      normalizedAddress[key] = value.trim().toLowerCase();
    }
  });
  return normalizedAddress;
};

/**
 * Return if the two addresses are the same. The compare is case insensitive and ignores spaces.
 * Logic should align with MT logic. https://msasg.visualstudio.com/Bing_Ads/_git/AnB?path=/private/ClientCenter/MT/Source/ClientCenter/DataContract/Entity/Address.cs
 * @param {object} accountBusinessAddress1 The flattened account business address object.
 * @param {object} accountBusinessAddress2 The flattened account business address object.
 * @returns {boolean} Returns true if the two addresses are the same.
 */
export const isABLMatch = (accountBusinessAddress1, accountBusinessAddress2) => _.isEqual(normalizeAddress(accountBusinessAddress1), normalizeAddress(accountBusinessAddress2));
