export const styles = (_theme:any) => {
  const sharedFloatingBannerStyles = {
    position: 'fixed',
    bottom: '15%',
    left: '50%',
    right: '50%',
    boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.1)',
    width: 'min(528px, 90vw)',
    transform: 'translateX(-50%)',
    display: 'flex',
    zIndex: 1100000,
  };

  return ({
    infoFloatingBanner: {
      ...sharedFloatingBannerStyles,
      background: '#DFF6DD',
    },
    infoIcon: {
      color: '#107C10',
      fontSize: '16px !important',
      padding: '16px 8px 16px 8px',
      float: 'left',
    },
    exitIcon: {
      fontSize: '16px  !important',
      color: '#605E5C',
      padding: '16px 14px 16px 14px',
      float: 'right',
      cursor: 'pointer',
    },
    warnFloatingBanner: {
      ...sharedFloatingBannerStyles,
      background: '#FFF4CE',
    },
    warnIcon: {
      color: '#D83B01',
      fontSize: '16px !important',
      padding: '16px 8px 16px 8px',
      float: 'left',
    },
    text: {
      wordWrap: 'break-word',
      width: '456px',
      flex: 1,
      fontSize: 12,
      lineHeight: '16px',
      padding: '16px 0px 16px 0px',
    },
  });
};
